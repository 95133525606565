import { FC, useState } from 'react';
import { Dialog, DialogContent, Box, IconButton } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { colorThemes } from '../theme';
import { LiteracyTraining } from '../__generated__/gql/graphql';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import NavigationButton from '../components/NavigationButton';
import { getCurrentStep, getStepLabels } from '../utils/navigationHelpers';
import WelcomeScreen from './training_modal_screens/WelcomeScreen';
import PdfViewerScreen from './training_modal_screens/PdfViewScreen';
import CompletionScreen from './training_modal_screens/CompletionScreen';
import CloseIcon from '@mui/icons-material/Close';

const GET_FILE_CONTENT = gql`
  query GetFileContent($versionedFileId: String!) {
    versionedFile(versionedFileId: $versionedFileId) {
      id
      contentBase64
    }
  }
`;

const COMPLETE_TRAINING = gql`
  mutation CompleteTraining($trainingId: String!) {
    completeTraining(trainingId: $trainingId) {
      trainingCompletion {
        id
        completionDate
        user {
          id
          name
        }
        training {
          id
          title
        }
      }
    }
  }
`;

// Types
interface TrainingModalProps {
  open: boolean;
  onClose: () => void;
  training: LiteracyTraining | null;
}

enum TrainingStage {
  Welcome = 'WELCOME',
  PdfViewer = 'PDF_VIEWER',
  Completion = 'COMPLETION',
}

const TrainingModal: FC<TrainingModalProps> = ({ open, onClose, training }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [showCompletion, setShowCompletion] = useState(false);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  const { loading: loadingPdf, data } = useQuery(GET_FILE_CONTENT, {
    variables: {
      versionedFileId: training?.trainingMaterial?.id,
    },
    skip: !training?.trainingMaterial?.id || activeStep === 0,
    onCompleted: () => setIsLoading(false),
    onError: error => {
      toast.error('Error loading training material');
      console.error('Error loading PDF:', error);
      setIsLoading(false);
    },
  });

  const [completeTraining] = useMutation(COMPLETE_TRAINING, {
    onCompleted: () => {
      toast.success('Training completed successfully');
      onClose();
    },
    onError: error => {
      toast.error(`Failed to complete training: ${error.message}`);
    },
    refetchQueries: ['GetLiteracyTrainingsforUser'],
  });

  const handlePdfLoad = (numPages: number) => {
    setTotalPages(numPages);
    setPdfLoaded(true);
  };

  const handleNavigation = (direction: 'next' | 'back') => {
    if (showCompletion) {
      if (direction === 'back') setShowCompletion(false);
      return;
    }

    if (activeStep === 0) {
      if (direction === 'next') setActiveStep(1);
      return;
    }

    if (direction === 'next') {
      if (currentPage >= totalPages) {
        setShowCompletion(true);
      } else {
        setCurrentPage(prev => prev + 1);
      }
    } else {
      if (currentPage > 1) {
        setCurrentPage(prev => prev - 1);
      } else {
        setActiveStep(0);
      }
    }
  };
  const resetStates = () => {
    setActiveStep(0);
    setCurrentPage(1);
    setIsLoading(true);
    setTotalPages(1);
    setShowCompletion(false);
    setPdfLoaded(false);
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const handleComplete = async () => {
    if (training?.id) {
      await completeTraining({
        variables: { trainingId: training.id },
      });
    }
    resetStates();
  };

  const getCurrentStage = (): TrainingStage => {
    if (activeStep === 0) return TrainingStage.Welcome;
    if (showCompletion) return TrainingStage.Completion;
    return TrainingStage.PdfViewer;
  };

  const stages: Record<TrainingStage, JSX.Element> = {
    [TrainingStage.Welcome]: (
      <WelcomeScreen
        training={training}
        onStart={() => handleNavigation('next')}
      />
    ),
    [TrainingStage.PdfViewer]: (
      <PdfViewerScreen
        isLoading={isLoading}
        loadingPdf={loadingPdf}
        pdfData={data?.versionedFile?.contentBase64}
        currentPage={currentPage}
        onPageLoad={handlePdfLoad}
      />
    ),
    [TrainingStage.Completion]: (
      <CompletionScreen training={training} onComplete={handleComplete} />
    ),
  };

  const renderContent = () => {
    const currentStage = getCurrentStage();
    return stages[currentStage];
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: '16px',
          height: '90%',
        },
      }}
    >
      <DialogContent sx={{ padding: 0, height: '100%' }}>
        <Box
          sx={{
            width: '85%',
            height: '100%',
            backgroundColor: colorThemes.DARK_BLUE_600,
            borderRadius: '2rem',
            padding: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            margin: 'auto',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              color: 'white',
              zIndex: 1,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            width="40px"
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
          >
            <NavigationButton
              direction="back"
              onClick={() => handleNavigation('back')}
              show={activeStep > 0 || currentPage > 1 || showCompletion}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            gap="40px"
            maxWidth="80%"
            height="90%"
            sx={{
              overflow: 'hidden',
              '& > *:first-of-type': {
                flexShrink: 0,
                marginBottom: '8px',
              },
              '& > *:last-child': {
                flexGrow: 1,
                width: '100%',
                overflow: 'auto',
              },
            }}
          >
            {(pdfLoaded || showCompletion) && (
              <Box
                sx={{
                  width: '100%',
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    height: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255, 255, 255, 0.3)',
                    borderRadius: '4px',
                  },
                }}
              >
                <HorizontalLinearStepper
                  step={getCurrentStep(
                    showCompletion,
                    activeStep,
                    currentPage,
                    totalPages
                  )}
                  stepLabels={getStepLabels(activeStep, pdfLoaded, totalPages)}
                  stepperProps={{
                    sx: {
                      minWidth: 'max-content',
                      padding: '16px',
                      '& .MuiStepLabel-label': {
                        color: 'white',
                        fontSize: '0.875rem',
                      },
                      '& .MuiStepIcon-root': {
                        fontSize: '1.25rem',
                      },
                      '& .MuiStepConnector-line': {
                        minWidth: '32px',
                      },
                    },
                  }}
                />
              </Box>
            )}
            {renderContent()}
          </Box>
          <Box
            width="40px"
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
          >
            <NavigationButton
              direction="next"
              onClick={() => handleNavigation('next')}
              show={!showCompletion && !(activeStep === 0)}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TrainingModal;
