import { Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditableTypography from '../../project_view/EditableTypography';
import MenuButton from '../../components/MenuButton';

interface TrainingDrawerHeaderProps {
  title: string;
  isEditable: boolean;
  onTitleChange: (title: string) => void;
  onEdit: () => void;
  onSave: () => void;
  onDelete: () => void;
}

export function TrainingDrawerHeader({
  title,
  isEditable,
  onTitleChange,
  onEdit,
  onSave,
  onDelete,
}: TrainingDrawerHeaderProps) {
  return (
    <Grid
      sx={{
        width: '100%',
        py: '30px',
        display: 'flex',
        justifyContent: 'start',
      }}
    >
      <Box sx={{ px: '30px', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'space-between',
            pb: '10px',
          }}
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, mr: 2 }}>
            <EditableTypography
              variant="h4"
              multiline={false}
              isEditable={isEditable}
              onChange={e => onTitleChange(e as string)}
            >
              {title}
            </EditableTypography>
          </Box>
          <Box display="flex" marginLeft="auto" gap={2}>
            <MenuButton
              variant="contained"
              startIcon={<EditIcon sx={{ color: 'black' }} />}
              label={isEditable ? 'Cancel' : 'Edit'}
              onClick={onEdit}
              items={[]}
            />
            {isEditable && (
              <>
                <MenuButton
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: 'black' }} />}
                  label="Save"
                  onClick={onSave}
                  items={[]}
                />
                <MenuButton
                  variant="contained"
                  startIcon={<DeleteIcon sx={{ color: 'black' }} />}
                  label="Delete"
                  onClick={onDelete}
                  items={[]}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
