import { DocumentComponentTypeEnum } from '../../../../__generated__/gql/graphql';

export const DocumentComponentNameMap = {
  [DocumentComponentTypeEnum.Abstract]: 'Abstract',
  [DocumentComponentTypeEnum.Architecture]: 'Architecture',
  [DocumentComponentTypeEnum.ArchitectureDiagram]: 'Architecture Diagram',
  [DocumentComponentTypeEnum.CustomText]: 'Custom Text',
  [DocumentComponentTypeEnum.BiasMitigationStrategy]:
    'Bias Mitigation Strategy',
  [DocumentComponentTypeEnum.ClassificationMetrics]: 'Classification Metrics',
  [DocumentComponentTypeEnum.CrossValidation]: 'Cross Validation',
  [DocumentComponentTypeEnum.CustomArtifact]: 'Custom Artifact',
  [DocumentComponentTypeEnum.DataIntegrity]: 'Data Integrity',
  [DocumentComponentTypeEnum.DataProcessing]: 'Data Processing',
  [DocumentComponentTypeEnum.DataSource]: 'Data Sources',
  [DocumentComponentTypeEnum.DataSummary]: 'Data Summary',
  [DocumentComponentTypeEnum.EasyAlgorithmExplanation]:
    'Easy Algorithm Explanation',
  [DocumentComponentTypeEnum.HoldoutTestset]: 'Holdout Testset',
  [DocumentComponentTypeEnum.InputDataType]: 'Input Data Type',
  [DocumentComponentTypeEnum.IntendedUseCase]: 'Intended Use Case',
  [DocumentComponentTypeEnum.LegalDisclaimer]: 'Legal Disclaimer',
  [DocumentComponentTypeEnum.MlFramework]: 'ML Framework',
  [DocumentComponentTypeEnum.ModelParameters]: 'Model Parameters',
  [DocumentComponentTypeEnum.OutputDataType]: 'Output Data Type',
  [DocumentComponentTypeEnum.PerformanceSummary]: 'Performance Summary',
  [DocumentComponentTypeEnum.PurposeAndGoal]: 'Purpose and Goal of AI Policy',
  [DocumentComponentTypeEnum.QualitativePerformanceMetrics]:
    'Qualitative Performance Metrics',
  [DocumentComponentTypeEnum.QuantitativePerformanceMetrics]:
    'Quantitative Performance Metrics',
  [DocumentComponentTypeEnum.ReferencePapers]: 'Reference Papers',
  [DocumentComponentTypeEnum.RepresentativeData]: 'Representative Data',
  [DocumentComponentTypeEnum.RolesResponsibilities]:
    'Roles and Responsibilities',
  [DocumentComponentTypeEnum.ScopeOfPolicy]: 'Scope of Policy',
  [DocumentComponentTypeEnum.StratifiedSampling]: 'Stratified Sampling',
  [DocumentComponentTypeEnum.TaskType]: 'Task Type',
  [DocumentComponentTypeEnum.TrainTestSplit]: 'Train Test Split',
  [DocumentComponentTypeEnum.UnintendedUseCases]: 'Unintended Use Cases',
  [DocumentComponentTypeEnum.UnittestModelConversion]:
    'Unittest Model Conversion',
  [DocumentComponentTypeEnum.ComingIntoForce]: 'Coming Into Force',
  [DocumentComponentTypeEnum.ContactOPolicy]: 'Contact of Policy',
  [DocumentComponentTypeEnum.DefinitionOfAi]: 'Definition of AI',
  [DocumentComponentTypeEnum.AlignmentWithOrganisation]:
    'Alignment with Organisation',
  [DocumentComponentTypeEnum.AiObjectives]: 'AI Objectives',
  [DocumentComponentTypeEnum.AiPrinciples]: 'AI Principles',
  [DocumentComponentTypeEnum.NonCompliance]: 'Non-Compliance Clause',
  [DocumentComponentTypeEnum.AiGovernanceSystem]: 'AI Governance System',
  [DocumentComponentTypeEnum.ObligationsRequirements]:
    'Obligations/ Requirements',
  [DocumentComponentTypeEnum.OtherPolicies]: 'Other Policies & Regulations',
  [DocumentComponentTypeEnum.RiskProfile]: 'Risk Profile',
  [DocumentComponentTypeEnum.AiSystems]: 'AI Systems',
};

export const CUSTOM_COMPONENT_TYPES = [
  DocumentComponentTypeEnum.CustomText,
  DocumentComponentTypeEnum.CustomArtifact,
] as readonly DocumentComponentTypeEnum[];
