import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import { Interval, User } from '../../__generated__/gql/graphql';
import ProjectGridItem from '../../project_view/ProjectGridItem';
import UserTag from '../../components/UserTag';
import EditableTypography from '../../project_view/EditableTypography';

export interface FormState {
  title: string;
  description: string;
  responsiblePerson: string;
  isRequired: boolean;
  interval: Interval;
}

interface DetailsSectionProps {
  formState: FormState;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  isEditable: boolean;
  allUsers: User[];
}

export function DetailsSection({
  formState,
  setFormState,
  isEditable,
  allUsers,
}: DetailsSectionProps) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProjectGridItem
          title="Description"
          content={
            <EditableTypography
              isEditable={isEditable}
              onChange={e =>
                setFormState(prev => ({ ...prev, description: e as string }))
              }
            >
              {formState.description}
            </EditableTypography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ProjectGridItem
          title="Responsible Person"
          content={
            <FormControl fullWidth variant="filled">
              <Select
                value={formState.responsiblePerson}
                onChange={e =>
                  setFormState(prev => ({
                    ...prev,
                    responsiblePerson: e.target.value,
                  }))
                }
                disabled={!isEditable}
              >
                {allUsers.map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    <UserTag user={user} variant="text" size="medium" />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <ProjectGridItem
          title="Required"
          content={
            <FormControl fullWidth variant="filled">
              <Select
                value={formState.isRequired}
                onChange={e =>
                  setFormState(prev => ({
                    ...prev,
                    isRequired: e.target.value === 'true',
                  }))
                }
                disabled={!isEditable}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <ProjectGridItem
          title="Interval"
          content={
            <FormControl fullWidth variant="filled">
              <Select
                value={formState.interval}
                onChange={e =>
                  setFormState(prev => ({
                    ...prev,
                    interval: e.target.value as Interval,
                  }))
                }
                disabled={!isEditable}
              >
                {Object.values(Interval).map(intervalValue => (
                  <MenuItem key={intervalValue} value={intervalValue}>
                    {intervalValue.charAt(0).toUpperCase() +
                      intervalValue.slice(1).toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        />
      </Grid>
    </Grid>
  );
}
