import { Box, Card, CardContent, Typography } from '@mui/material';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { colorThemes } from '../../theme';
import 'react-circular-progressbar/dist/styles.css';

interface ProgressDisplayProps {
  current: number;
  total: number;
  title: string;
  inverse?: boolean;
}

export default function ProgressDisplay({
  current,
  total,
  title,
  inverse = false,
}: ProgressDisplayProps) {
  const percentage = total ? Math.round((current / total) * 100) : 0;

  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: colorThemes.DARK_BLUE_600,
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        height: 300,
        transition: 'all .2s',
        border: '2px solid #334E68',
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">{title}</Typography>
        <Box sx={{ width: 120, height: 120 }}>
          <CircularProgressbarWithChildren
            value={percentage}
            counterClockwise={inverse}
            styles={buildStyles({
              pathColor: getColor(percentage, inverse),
              trailColor: colorThemes.GREY_400,
            })}
          >
            <Typography variant="h4">{current}</Typography>
          </CircularProgressbarWithChildren>
        </Box>
      </CardContent>
    </Card>
  );
}

function getColor(percentage: number, inverse: boolean) {
  if (inverse) {
    if (percentage >= 66.78) return '#cc1f1f';
    if (percentage >= 33.4) return '#cc921f';
    return '#27cc1f';
  }

  return '#27cc1f';
}
