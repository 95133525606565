import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, Fragment, useState } from 'react';
import ProjectGridItem from '../../project_view/ProjectGridItem';
import {
  LiteracyTraining,
  User,
  Interval,
} from '../../__generated__/gql/graphql';
import { generateIntervals } from '../../utils/generateIntervals';
import CompletionProgress from './CompletionProgress';
import CompletionTable from './CompletionTable';

interface CompletionLogsSectionProps {
  training: LiteracyTraining;
  allUsers: User[];
}

const CompletionLogsSection: FC<CompletionLogsSectionProps> = ({
  training,
  allUsers,
}) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleRowClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <ProjectGridItem
        title="Completion Logs"
        content={
          <TableContainer component={Paper}>
            <Table
              sx={{
                '& .MuiTableCell-root': {
                  borderBottom: '2px solid',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Interval</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Completions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {generateIntervals(
                  training.dueDate,
                  training.interval || Interval.Yearly
                ).map((intervalObj, idx) => (
                  <Fragment key={idx}>
                    <TableRow
                      onClick={() => handleRowClick(idx)}
                      sx={{
                        backgroundColor:
                          intervalObj.status === 'current'
                            ? 'action.hover'
                            : intervalObj.status === 'upcoming'
                            ? 'action.selected'
                            : 'inherit',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'action.hover',
                        },
                        '& td': {
                          borderBottom:
                            expandedRow === idx
                              ? '1px solid rgba(224, 224, 224, 0.4) !important'
                              : undefined,
                        },
                      }}
                    >
                      <TableCell>{intervalObj.label}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            color:
                              intervalObj.status === 'upcoming'
                                ? 'text.secondary'
                                : 'text.primary',
                          }}
                        >
                          {intervalObj.status.charAt(0).toUpperCase() +
                            intervalObj.status.slice(1)}
                        </Box>
                      </TableCell>
                      <CompletionProgress
                        interval={intervalObj}
                        completionLogs={training.completionLogs}
                        users={allUsers}
                        open={expandedRow === idx}
                      />
                    </TableRow>
                    {expandedRow === idx && (
                      <TableRow
                        sx={{
                          backgroundColor: 'inherit',
                          '& td': {
                            borderTop: 'none',
                          },
                        }}
                      >
                        <TableCell colSpan={3} sx={{ pt: 2, pb: 3 }}>
                          <Collapse in={true} timeout="auto">
                            <CompletionTable
                              intervalObj={intervalObj}
                              completionLogs={training.completionLogs}
                              users={allUsers}
                            />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      />
    </Box>
  );
};

export default CompletionLogsSection;
