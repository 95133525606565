import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  TableHead,
  TableBody,
  Skeleton,
} from '@mui/material';
import { GetExportedPdfsQuery } from '../__generated__/gql/graphql';
import { colorThemes } from '../theme';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { formatDateTime } from '../utils/dateTimeHelpers';

const GET_EXPORTED_PDFS = gql(`
    query GetExportedPdfs {
    allExportedPdfs {
        id
        fileName
        lastModified
        versionedFile{
            url
            }
        }
    }
`);

export default function ExportedDocumentList() {
  const { data, loading } = useQuery<GetExportedPdfsQuery>(GET_EXPORTED_PDFS);

  function handleDownload(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'document.pdf');
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box sx={{ width: 'full', paddingY: '4rem' }}>
      <Typography variant="h5">Exported Documents</Typography>
      {loading ? (
        <Skeleton sx={{ marginX: '4rem' }} height={150} />
      ) : data?.allExportedPdfs?.length === 0 ? (
        <Typography sx={{ marginTop: '1rem' }}>
          No exported documents yet. Generated documents will be listed here
          after you export them.
        </Typography>
      ) : (
        <Box sx={{ width: 'full', paddingX: '0rem', marginTop: '1rem' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Exported at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.allExportedPdfs?.map(pdf => (
                  <TableRow
                    key={pdf?.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      ':hover': {
                        backgroundColor: colorThemes.DARK_BLUE_400,
                        cursor: 'pointer',
                      },
                      transition: 'all 0.2s',
                    }}
                    onClick={() =>
                      handleDownload(pdf?.versionedFile?.url ?? '')
                    }
                  >
                    <TableCell
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <InsertDriveFileIcon />
                      {pdf?.fileName}
                    </TableCell>
                    <TableCell>{formatDateTime(pdf?.lastModified)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
