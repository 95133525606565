import { Box, Typography } from '@mui/material';
import {
  MaterializedRequirement,
  RequirementCompletionStatusEnum,
  User,
  RequirementAuditStatusEnum,
} from '../../__generated__/gql/graphql';
import UserList from '../../components/UserList';
import { colorThemes } from '../../theme';

const statusColors = {
  finished: '#2E7D32',
  unfinished: '#FF0000',
  audited: colorThemes.ACTION_BLUE,
  notAudited: colorThemes.GREY_600,
};

export function RequirementCard({
  onClick,
  specificationNumber,
  evidenceNumber,
  requirement: {
    title,
    description,
    userStatus: status,
    owners,
    approvers,
    approvalResponsibilities,
    ownerResponsibilities,
    requirementAuditStatus,
  },
  auditId,
}: {
  requirement: MaterializedRequirement;
  onClick: () => void;
  specificationNumber: number;
  evidenceNumber: number;
  auditId?: string;
}) {
  const truncatedDescription =
    description!.length > 300
      ? `${description?.substring(0, 300)}...`
      : description;

  return (
    <Box
      component="button"
      sx={{
        all: 'unset',
        bgcolor: colorThemes.GREY_100,
        color: colorThemes.DARK_BLUE_600,
        borderRadius: '8px',
        padding: '16px',
        display: 'grid',
        gridTemplateColumns: '5fr 1fr 1fr 1.5fr 1.5fr max-content',
        gap: 1,
        cursor: 'pointer',
        position: 'relative',
        ...(auditId && {
          borderLeft: '4px solid',
          borderLeftColor: requirementAuditStatus?.sections?.some(
            section =>
              section?.status !== RequirementAuditStatusEnum.NotReviewed
          )
            ? statusColors.audited
            : statusColors.notAudited,
        }),
        '&:hover': {
          bgcolor: colorThemes.GREY_300,
        },
      }}
      onClick={onClick}
    >
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography>{title}</Typography>
        <Typography>{truncatedDescription}</Typography>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Evidence</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{evidenceNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Specs</Typography>
        <Box display="flex" alignItems="center" gap="6px">
          <Box
            component="span"
            borderRadius="9999px"
            bgcolor={statusColors.finished}
            width="8px"
            height="8px"
          />
          <span>{specificationNumber}</span>
        </Box>
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Owner</Typography>
        <UserList
          users={owners as User[]}
          variant="text"
          remediationMessage={
            'Please assign one of the following responsibilites to a user: ' +
            ownerResponsibilities?.join(', ')
          }
        />
      </Box>
      <Box display="flex" gap="14px" flexDirection="column">
        <Typography fontWeight={600}>Approval</Typography>
        <UserList
          users={approvers as User[]}
          variant="text"
          remediationMessage={
            'Please assign one of the following responsibilites to a user: ' +
            approvalResponsibilities?.join(', ')
          }
        />
      </Box>
      <Box
        width={20}
        height={20}
        borderRadius="4px"
        bgcolor={
          status?.completionStatus === RequirementCompletionStatusEnum.Approved
            ? statusColors.finished
            : statusColors.unfinished
        }
      />
    </Box>
  );
}
