export enum ComponentCategory {
    Organization = 'Organization',
    Project = 'Project',
    Technical = 'Technical',
    General = 'General',
}

enum DocumentComponentType {
    Abstract = 'Abstract',
    AiGovernanceSystem = 'AiGovernanceSystem',
    AiObjectives = 'AiObjectives',
    AiPrinciples = 'AiPrinciples',
    AiSystems = 'AiSystems',
    AlignmentWithOrganisation = 'AlignmentWithOrganisation',
    Architecture = 'Architecture',
    ArchitectureDiagram = 'ArchitectureDiagram',
    BiasMitigationStrategy = 'BiasMitigationStrategy',
    ClassificationMetrics = 'ClassificationMetrics',
    ComingIntoForce = 'ComingIntoForce',
    ContactOPolicy = 'ContactOPolicy',
    CrossValidation = 'CrossValidation',
    CustomArtifact = 'CustomArtifact',
    CustomText = 'CustomText',
    DataIntegrity = 'DataIntegrity',
    DataProcessing = 'DataProcessing',
    DataSource = 'DataSource',
    DataSummary = 'DataSummary',
    DefinitionOfAi = 'DefinitionOfAi',
    EasyAlgorithmExplanation = 'EasyAlgorithmExplanation',
    HoldoutTestset = 'HoldoutTestset',
    InputDataType = 'InputDataType',
    IntendedUseCase = 'IntendedUseCase',
    LegalDisclaimer = 'LegalDisclaimer',
    MlFramework = 'MlFramework',
    ModelParameters = 'ModelParameters',
    NonCompliance = 'NonCompliance',
    ObligationsRequirements = 'ObligationsRequirements',
    OtherPolicies = 'OtherPolicies',
    OutputDataType = 'OutputDataType',
    PerformanceSummary = 'PerformanceSummary',
    PurposeAndGoal = 'PurposeAndGoal',
    QualitativePerformanceMetrics = 'QualitativePerformanceMetrics',
    QuantitativePerformanceMetrics = 'QuantitativePerformanceMetrics',
    ReferencePapers = 'ReferencePapers',
    RepresentativeData = 'RepresentativeData',
    RiskProfile = 'RiskProfile',
    RolesResponsibilities = 'RolesResponsibilities',
    ScopeOfPolicy = 'ScopeOfPolicy',
    StratifiedSampling = 'StratifiedSampling',
    TaskType = 'TaskType',
    TrainTestSplit = 'TrainTestSplit',
    UnintendedUseCases = 'UnintendedUseCases',
    UnittestModelConversion = 'UnittestModelConversion',
}

export const ComponentTypeCategories: Record<DocumentComponentType, ComponentCategory> = {
    [DocumentComponentType.Abstract]: ComponentCategory.Technical,
    [DocumentComponentType.AiGovernanceSystem]: ComponentCategory.Organization,
    [DocumentComponentType.AiObjectives]: ComponentCategory.Organization,
    [DocumentComponentType.AiPrinciples]: ComponentCategory.Organization,
    [DocumentComponentType.AiSystems]: ComponentCategory.Organization,
    [DocumentComponentType.AlignmentWithOrganisation]: ComponentCategory.Organization,
    [DocumentComponentType.Architecture]: ComponentCategory.Technical,
    [DocumentComponentType.ArchitectureDiagram]: ComponentCategory.Technical,
    [DocumentComponentType.BiasMitigationStrategy]: ComponentCategory.Technical,
    [DocumentComponentType.ClassificationMetrics]: ComponentCategory.Technical,
    [DocumentComponentType.ComingIntoForce]: ComponentCategory.Organization,
    [DocumentComponentType.ContactOPolicy]: ComponentCategory.Organization,
    [DocumentComponentType.CrossValidation]: ComponentCategory.Technical,
    [DocumentComponentType.CustomArtifact]: ComponentCategory.General,
    [DocumentComponentType.CustomText]: ComponentCategory.General,
    [DocumentComponentType.DataIntegrity]: ComponentCategory.Technical,
    [DocumentComponentType.DataProcessing]: ComponentCategory.Technical,
    [DocumentComponentType.DataSource]: ComponentCategory.Technical,
    [DocumentComponentType.DataSummary]: ComponentCategory.Technical,
    [DocumentComponentType.DefinitionOfAi]: ComponentCategory.Organization,
    [DocumentComponentType.EasyAlgorithmExplanation]: ComponentCategory.Technical,
    [DocumentComponentType.HoldoutTestset]: ComponentCategory.Technical,
    [DocumentComponentType.InputDataType]: ComponentCategory.Technical,
    [DocumentComponentType.IntendedUseCase]: ComponentCategory.Technical,
    [DocumentComponentType.LegalDisclaimer]: ComponentCategory.Organization,
    [DocumentComponentType.MlFramework]: ComponentCategory.Technical,
    [DocumentComponentType.ModelParameters]: ComponentCategory.Technical,
    [DocumentComponentType.NonCompliance]: ComponentCategory.Organization,
    [DocumentComponentType.ObligationsRequirements]: ComponentCategory.Organization,
    [DocumentComponentType.OtherPolicies]: ComponentCategory.Organization,
    [DocumentComponentType.OutputDataType]: ComponentCategory.Technical,
    [DocumentComponentType.PerformanceSummary]: ComponentCategory.Technical,
    [DocumentComponentType.PurposeAndGoal]: ComponentCategory.Organization,
    [DocumentComponentType.QualitativePerformanceMetrics]: ComponentCategory.Technical,
    [DocumentComponentType.QuantitativePerformanceMetrics]: ComponentCategory.Technical,
    [DocumentComponentType.ReferencePapers]: ComponentCategory.Technical,
    [DocumentComponentType.RepresentativeData]: ComponentCategory.Technical,
    [DocumentComponentType.RiskProfile]: ComponentCategory.Organization,
    [DocumentComponentType.RolesResponsibilities]: ComponentCategory.Organization,
    [DocumentComponentType.ScopeOfPolicy]: ComponentCategory.Organization,
    [DocumentComponentType.StratifiedSampling]: ComponentCategory.Technical,
    [DocumentComponentType.TaskType]: ComponentCategory.Technical,
    [DocumentComponentType.TrainTestSplit]: ComponentCategory.Technical,
    [DocumentComponentType.UnintendedUseCases]: ComponentCategory.Project,
    [DocumentComponentType.UnittestModelConversion]: ComponentCategory.Technical,
};

export default DocumentComponentType;
