import { useSuspenseQuery } from '@apollo/client';
import { useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { gql } from '../__generated__/gql';
import SearchIcon from '@mui/icons-material/Search';
import AuditDetailCard from './AuditDetailCard';
import NewAuditModal from './NewAuditModal';
import type { AuditViewItemsQuery } from '../__generated__/gql/graphql';
import AuditDetailDrawerSuspenseWrapper from './AuditDetailDrawerSuspenseWrapper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CardGrid from '../components/CardGrid';
import { useParams } from 'react-router-dom';

export const GET_AUDITS = gql(`
  query AuditViewItems {
    allGovernanceAudits {
      id
      name
      description
      status
      startDate
      endDate
      frameworkId
      auditors {
        id
        name
        email
        initials
      }
    }
  }
`);

export default function AuditView() {
  const { selectedAuditId } = useParams<{ selectedAuditId?: string }>();
  const { data } = useSuspenseQuery<AuditViewItemsQuery>(GET_AUDITS);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSidebar, setShowSidebar] = useState(!!selectedAuditId);
  const [selectedAudit, setSelectedAudit] = useState<NonNullable<
    NonNullable<AuditViewItemsQuery['allGovernanceAudits']>[number]
  > | null>(() => {
    if (selectedAuditId && data?.allGovernanceAudits) {
      return (
        data.allGovernanceAudits.find(a => a?.id === selectedAuditId) ?? null
      );
    }
    return null;
  });
  const [showCreationModal, setShowCreationModal] = useState(false);

  const audits =
    data?.allGovernanceAudits?.filter(
      (audit): audit is NonNullable<typeof audit> => audit !== null
    ) ?? [];
  const filteredAudits = audits.filter(audit =>
    audit?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box display={'flex'} gap={2} flexDirection={'column'} width={'100%'}>
      <ToastContainer position="top-right" />
      <Typography variant="h4" marginBottom={3}>
        Governance Audits
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          gap: '12px',
        }}
      >
        <TextField
          id="search"
          sx={{ width: 700 }}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search for an audit ..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <CardGrid
        items={filteredAudits}
        title={
          filteredAudits.length === 0
            ? 'No Audits'
            : filteredAudits.length === 1
            ? '1 Audit'
            : `${filteredAudits.length} Audits`
        }
        renderCard={audit => (
          <AuditDetailCard
            key={audit.id}
            audit={audit}
            onClick={() => {
              setSelectedAudit(audit);
              setShowSidebar(!showSidebar);
            }}
          />
        )}
        onAddNew={() => setShowCreationModal(true)}
      />

      {selectedAudit && (
        <AuditDetailDrawerSuspenseWrapper
          key={selectedAudit.id}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          selectedAudit={selectedAudit}
        />
      )}

      <NewAuditModal
        isOpen={showCreationModal}
        onClose={() => setShowCreationModal(false)}
      />
    </Box>
  );
}
