import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

interface PaywallModalProps {
  open: boolean;
  onClose: () => void;
  onAction?: () => void;
  title?: string;
  primaryText?: string;
  secondaryText?: string;
  actionButtonText?: string;
  cancelButtonText?: string;
  icon?: React.ReactNode;
}

export const PaywallModal: React.FC<PaywallModalProps> = ({
  open,
  onClose,
  onAction,
  title = 'Upgrade Required',
  primaryText = 'This feature requires a premium subscription',
  secondaryText = 'Unlock all premium features and enhance your experience with our premium plan. Get access to advanced tools and capabilities.',
  actionButtonText = 'Upgrade Now',
  cancelButtonText = 'Maybe Later',
  icon = <LockIcon color="primary" />,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 1,
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 24,
            },
          }}
        >
          {icon}
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ pt: 1 }}>
        <Typography variant="body1" fontWeight="medium" gutterBottom>
          {primaryText}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 2, whiteSpace: 'pre-line' }}
        >
          {secondaryText}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2.5, pt: 1 }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAction}
          sx={{
            boxShadow: 'none',
            textTransform: 'none',
            fontWeight: 600,
            px: 3,
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: 'primary.dark',
            },
          }}
        >
          {actionButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaywallModal;
