import { Box, Typography, MenuItem, Select, FormControl } from '@mui/material';
import {
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
  RiskClassSpecialCaseEnum,
} from '../__generated__/gql/graphql';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
  getRiskClassSpecialCaseText,
} from '../risk_classification/QuestionnaireResult';

export enum RiskClassType {
  ROLE = 'ROLE',
  CATEGORY = 'CATEGORY',
  SPECIAL_CASE = 'SPECIAL_CASE',
}

interface RiskClassTagProps {
  riskClassCategory?: RiskClassCategoryEnum;
  riskClassRole?: RiskClassRoleEnum;
  riskClassSpecialCase?: RiskClassSpecialCaseEnum;
  isEditable: boolean;
  setRiskClassRole?: (role: RiskClassRoleEnum) => void;
  setRiskClassCategory?: (category: RiskClassCategoryEnum) => void;
  setRiskClassSpecialCase?: (specialCase: RiskClassSpecialCaseEnum) => void;
  type: RiskClassType;
}

const RiskClassTag: React.FC<RiskClassTagProps> = ({
  riskClassCategory,
  riskClassRole,
  riskClassSpecialCase,
  isEditable,
  setRiskClassRole,
  setRiskClassCategory,
  setRiskClassSpecialCase,
  type,
}) => {
  const getMenuItemText = (value: string) => {
    switch (type) {
      case RiskClassType.ROLE:
        return getRiskClassRoleText(value as RiskClassRoleEnum);
      case RiskClassType.CATEGORY:
        return getRiskClassCategoryText(value as RiskClassCategoryEnum);
      case RiskClassType.SPECIAL_CASE:
        return getRiskClassSpecialCaseText(value as RiskClassSpecialCaseEnum);
    }
  };

  const getCurrentValue = () => {
    switch (type) {
      case RiskClassType.ROLE:
        return {
          value: riskClassRole,
          setter: setRiskClassRole,
          options: RiskClassRoleEnum,
        };
      case RiskClassType.CATEGORY:
        return {
          value: riskClassCategory,
          setter: setRiskClassCategory,
          options: RiskClassCategoryEnum,
        };
      case RiskClassType.SPECIAL_CASE:
        return {
          value: riskClassSpecialCase,
          setter: setRiskClassSpecialCase,
          options: RiskClassSpecialCaseEnum,
        };
    }
  };

  const currentConfig = getCurrentValue();

  const getBorderColor = (
    riskClassCategoryValue?: RiskClassCategoryEnum,
    riskClassRoleValue?: RiskClassRoleEnum
  ) => {
    if (riskClassRoleValue === RiskClassRoleEnum.OutOfScope) {
      return { border: 'white', background: 'transparent' };
    }
    switch (riskClassCategoryValue) {
      case RiskClassCategoryEnum.Minimal:
        return { border: 'green', background: 'rgba(0, 128, 0, 0.1)' };
      case RiskClassCategoryEnum.Limited:
        return { border: 'orange', background: 'rgba(255, 165, 0, 0.1)' };
      case RiskClassCategoryEnum.High:
      case RiskClassCategoryEnum.HighAndLimited:
        return { border: 'red', background: 'rgba(255, 0, 0, 0.1)' };
      case RiskClassCategoryEnum.HighExemptions:
        return { border: 'yellow', background: 'rgba(255, 255, 0, 0.1)' };
      case RiskClassCategoryEnum.Prohibited:
        return { border: 'black', background: 'rgba(0, 0, 0, 0.1)' };
      default:
        return { border: 'white', background: 'transparent' };
    }
  };

  const { border, background } = getBorderColor(
    riskClassCategory,
    riskClassRole
  );
  const getDisplayText = () => {
    if (riskClassRole === RiskClassRoleEnum.OutOfScope) {
      return 'Out of Scope';
    }
    if (!currentConfig.value) {
      return 'Not set yet';
    }
    return getMenuItemText(currentConfig.value);
  };

  const handleChange = (newValue: string) => {
    switch (type) {
      case RiskClassType.ROLE:
        setRiskClassRole?.(newValue as RiskClassRoleEnum);
        break;
      case RiskClassType.CATEGORY:
        setRiskClassCategory?.(newValue as RiskClassCategoryEnum);
        break;
      case RiskClassType.SPECIAL_CASE:
        setRiskClassSpecialCase?.(newValue as RiskClassSpecialCaseEnum);
        break;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="10px"
      height="100%"
      sx={{
        border: `2px solid ${border}`,
        backgroundColor: background,
        ...(isEditable ? {} : { py: '20px' }),
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      {isEditable ? (
        <FormControl variant="filled" fullWidth>
          <Select
            value={currentConfig.value || ''}
            onChange={event => handleChange(event.target.value)}
            sx={{
              pt: '0px',
              pb: '4px',
              px: '4px',
              '& .MuiSelect-select': {
                whiteSpace: 'normal',
                pt: '10px',
              },
            }}
          >
            {Object.values(currentConfig.options).map(value => (
              <MenuItem key={value} value={value}>
                {getMenuItemText(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Typography variant="h6">{getDisplayText()}</Typography>
      )}
    </Box>
  );
};

export default RiskClassTag;
