import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { IntervalData } from '../../utils/generateIntervals';
import {
  Maybe,
  TrainingCompletion,
  User,
} from '../../__generated__/gql/graphql';
import UserTag from '../../components/UserTag';

interface CompletionTableProps {
  intervalObj: IntervalData;
  completionLogs: Maybe<Maybe<TrainingCompletion>[]> | undefined;
  users: User[];
}
const CompletionTable: FC<CompletionTableProps> = ({
  intervalObj,
  completionLogs,
  users,
}) => (
  <Table
    size="small"
    sx={{
      '&.MuiTable-root .MuiTableCell-root': {
        borderBottom: '1px solid rgba(224, 224, 224, 0.4) !important', // Using !important to override inheritance
      },
    }}
  >
    <TableHead>
      <TableRow>
        <TableCell>User</TableCell>
        <TableCell>Completion Date</TableCell>
        <TableCell>Status</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {completionLogs
        ?.filter(log => {
          if (!log?.completionDate) return false;
          const completionDate = DateTime.fromISO(log.completionDate);
          return (
            completionDate >= intervalObj.start &&
            completionDate < intervalObj.end
          );
        })
        .map((log, idx) => {
          if (!log?.user || !log.completionDate) return null;
          return (
            <TableRow key={idx}>
              <TableCell>
                <UserTag user={log.user} variant="text" size="small" />
              </TableCell>
              <TableCell>
                {DateTime.fromISO(log.completionDate).toLocaleString(
                  DateTime.DATE_MED
                )}
              </TableCell>
              <TableCell sx={{ color: 'success.main' }}>Completed</TableCell>
            </TableRow>
          );
        })}
      {users
        .filter(user => !completionLogs?.some(log => log?.user?.id === user.id))
        .map(user => (
          <TableRow key={user.id}>
            <TableCell>
              <Box sx={{ opacity: 0.6 }}>
                <UserTag user={user} variant="text" size="small" />
              </Box>
            </TableCell>
            <TableCell>-</TableCell>
            <TableCell sx={{ color: 'warning.main' }}>Pending</TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
);
export default CompletionTable;
