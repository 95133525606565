import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useState, useEffect } from 'react';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentTemplateAtom,
  documentTitleAtom,
  selectTemplate,
  useInitializeTemplateFromUrl,
} from '../../DocumentationPageStates';
import { colorThemes } from '../../../../theme';
import {
  DocumentationTemplate,
  DocumentationTemplates,
} from './DocumentationTemplates';
import { DocumentationScopeEnum } from '../../../../__generated__/gql/graphql';
import { NoComponentsMessage } from '../components/ComponentsTab';
import { useSearchParams } from 'react-router-dom';
import {
  CUSTOM_COMPONENT_TYPES,
  DocumentComponentNameMap,
} from '../components/ComponentNameMapping';

export default function TemplateTab() {
  const currentComponents = useRecoilValue(documentComponentsAtom);
  const currentTemplate = useRecoilValue(documentTemplateAtom);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const initializeTemplate = useInitializeTemplateFromUrl();

  useEffect(() => {
    if (!currentTemplate) {
      initializeTemplate();
    }
  }, [currentTemplate, initializeTemplate]);

  return (
    <Box sx={{ width: 'full' }}>
      <Grid container spacing={10}>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Templates</Typography>
            <TextField
              id="search"
              sx={{ width: 200, height: 40 }}
              size="small"
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search Template"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Organizational Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Organization
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Project Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Project
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginTop: '0.5rem' }}>
                  Experiment Wide Templates
                </Typography>
                <Divider sx={{ marginTop: '0.5rem' }} />
              </Grid>
              {DocumentationTemplates.filter(template =>
                template.title.toLowerCase().includes(searchTerm.toLowerCase())
              )
                .filter(
                  template =>
                    template.documentationScope ===
                    DocumentationScopeEnum.Experiment
                )
                .map(template => (
                  <Grid item xs={6}>
                    <TemplateCard template={template} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Preview</Typography>
          </Box>
          <Box
            sx={{
              maxHeight: '650px',
              overflowY: 'auto',
            }}
          >
            <Typography sx={{ marginBottom: '1rem' }}>
              {currentTemplate
                ? currentTemplate.description
                : 'No Template Selected'}
            </Typography>
            {currentComponents.length !== 0 ? (
              currentComponents.map(component => (
                <PreviewComponentCard component={component} />
              ))
            ) : (
              <NoComponentsMessage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function TemplateCard({ template }: { template: DocumentationTemplate }) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const setDocumentTitle = useSetRecoilState(documentTitleAtom);
  const [selectedTemplate, setDocumentTemplate] =
    useRecoilState(documentTemplateAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTemplateSelection = () => {
    selectTemplate(template, {
      setDocumentComponents,
      setDocumentTemplate,
      setDocumentTitle,
    });

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('template', template.id.toString());
    setSearchParams(newSearchParams);
  };

  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 100,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        backgroundColor:
          selectedTemplate === template
            ? colorThemes.DARK_BLUE_600
            : colorThemes.DARK_BLUE_800,
        borderRadius: 1,
      }}
      onClick={handleTemplateSelection}
    >
      <Typography variant="subtitle1">{template.title}</Typography>
    </Box>
  );
}

function PreviewComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  const displayName = DocumentComponentNameMap[component.type];
  const isCustomComponent = CUSTOM_COMPONENT_TYPES.includes(component.type);

  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        marginBottom: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1">{displayName}</Typography>
      {isCustomComponent && (
        <Typography variant="subtitle2" sx={{ color: colorThemes.GREY_400 }}>
          - Custom
        </Typography>
      )}
    </Box>
  );
}
