import Markdown, { Components } from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Typography } from '@mui/material';
import { colorThemes } from '../theme';

interface Props {
  mdString?: string;
}

export function MarkdownRendering({ mdString }: Props): JSX.Element {
  const markdownComponents: Components = {
    p: ({ ...props }) => {
      return (
        <Typography color={colorThemes.DARK_BLUE_600}>
          {props.children}
        </Typography>
      );
    },
    h1: ({ ...props }) => (
      <Typography variant="h4" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    h2: ({ ...props }) => (
      <Typography variant="h5" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),

    // Lists
    ul: ({ ...props }) => (
      <Typography component="ul" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    ol: ({ ...props }) => (
      <Typography component="ol" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),
    li: ({ ...props }) => (
      <Typography component="li" color={colorThemes.DARK_BLUE_600}>
        {props.children}
      </Typography>
    ),

    sup: ({ ...props }) => (
      <Typography
        component="sup"
        sx={{
          fontSize: '0.7em',
          color: colorThemes.DARK_BLUE_600,
          textDecoration: 'none',
          marginLeft: '1px',
          marginRight: '1px',
        }}
      >
        {props.children}
      </Typography>
    ),

    code: ({ ...props }) => (
      <Typography
        component="code"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          // overflowX: 'auto',
        }}
      >
        {props.children}
      </Typography>
    ),
  };
  return (
    <Markdown components={markdownComponents} rehypePlugins={[rehypeRaw]}>
      {mdString}
    </Markdown>
  );
}
