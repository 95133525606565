import { useState } from 'react';
import { Box, Divider, Drawer } from '@mui/material';
import { ApolloCache, gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  GetLiteracyTrainingsQuery,
  Interval,
  LiteracyTraining,
  User,
} from '../__generated__/gql/graphql';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import CompletionLogsSection from './training_admin_drawer/CompletionLogsSection';
import { TrainingDrawerHeader } from './training_admin_drawer/TrainingDrawerHeader';
import {
  DetailsSection,
  FormState,
} from './training_admin_drawer/DetailsSection';
import { GET_LITERACY_TRAININGS } from './TrainingAdminDashboardPage';

const UPDATE_TRAINING = gql`
  mutation UpdateTraining(
    $trainingId: ID!
    $title: String
    $description: String
    $responsiblePerson: String
    $isRequired: Boolean
    $interval: IntervalEnum
  ) {
    updateTraining(
      trainingId: $trainingId
      title: $title
      description: $description
      responsiblePerson: $responsiblePerson
      isRequired: $isRequired
      interval: $interval
    ) {
      training {
        id
        title
        description
        responsiblePerson {
          id
          name
        }
        isRequired
        interval
      }
    }
  }
`;

const DELETE_TRAINING = gql`
  mutation DeleteTraining($trainingId: ID!) {
    deleteTraining(trainingId: $trainingId) {
      training {
        id
      }
    }
  }
`;

interface TrainingDrawerProps {
  training: LiteracyTraining;
  isOpen: boolean;
  onClose: () => void;
  allUsers: User[];
}

export default function TrainingDrawer({
  training,
  isOpen,
  onClose,
  allUsers,
}: TrainingDrawerProps) {
  const [isEditable, setIsEditable] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    title: training.title,
    description: training.description || '',
    responsiblePerson: training.responsiblePerson?.id || '',
    isRequired: training.isRequired || false,
    interval: training.interval || Interval.Yearly,
  });

  const resetForm = () => {
    setFormState({
      title: training.title,
      description: training.description || '',
      responsiblePerson: training.responsiblePerson?.id || '',
      isRequired: training.isRequired || false,
      interval: training.interval || Interval.Yearly,
    });
  };

  const updateCacheAfterDelete = (
    cache: ApolloCache<GetLiteracyTrainingsQuery>,
    deletedTrainingId: string
  ) => {
    const existingData = cache.readQuery<GetLiteracyTrainingsQuery>({
      query: GET_LITERACY_TRAININGS,
    });

    if (existingData?.allLiteracyTrainings) {
      cache.writeQuery<GetLiteracyTrainingsQuery>({
        query: GET_LITERACY_TRAININGS,
        variables: {
          targetQueryMutation: 'createTraining',
          action: 'mutation',
        },
        data: {
          allLiteracyTrainings: existingData.allLiteracyTrainings.filter(
            training => training?.id !== deletedTrainingId
          ),
        },
      });
    }
    cache.evict({
      id: cache.identify({
        __typename: 'LiteracyTraining',
        id: deletedTrainingId,
      }),
    });
    cache.gc();
  };

  const [updateTraining] = useMutation(UPDATE_TRAINING, {
    variables: {
      trainingId: training.id,
      ...formState,
    },
    onCompleted: () => {
      toast.success('Training updated successfully');
      setIsEditable(false);
    },
    onError: error => {
      toast.error('Failed to update training');
      console.error('Update training error:', error);
    },
  });

  const [deleteTraining] = useMutation(DELETE_TRAINING, {
    variables: { trainingId: training.id },
    onCompleted: () => {
      toast.success('Training deleted successfully');
      onClose();
    },
    onError: error => {
      toast.error('Failed to delete training');
      console.error('Delete training error:', error);
    },
    update(cache) {
      updateCacheAfterDelete(cache, training.id);
    },
  });

  if (!isOpen) return null;

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '80%',
            maxWidth: '1000px',
          },
        }}
      >
        <Box
          sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}
        >
          <TrainingDrawerHeader
            title={formState.title}
            isEditable={isEditable}
            onTitleChange={title => setFormState(prev => ({ ...prev, title }))}
            onEdit={() => {
              if (isEditable) resetForm();
              setIsEditable(!isEditable);
            }}
            onSave={updateTraining}
            onDelete={() => setDeleteDialogOpen(true)}
          />
          <Divider />
          <Box sx={{ p: 3 }}>
            <DetailsSection
              formState={formState}
              setFormState={setFormState}
              isEditable={isEditable}
              allUsers={allUsers}
            />
            <CompletionLogsSection training={training} allUsers={allUsers} />
          </Box>
        </Box>
      </Drawer>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          deleteTraining();
          setDeleteDialogOpen(false);
        }}
        itemToDelete={{
          name: training.title,
          type: 'Training',
        }}
      />
    </>
  );
}
