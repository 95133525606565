import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  gql,
  useMutation,
  useSuspenseQuery,
  ApolloCache,
  FetchResult,
} from '@apollo/client';
import {
  GetFrameworkTemplatesQuery,
  UpdateEnabledFrameworksMutation,
} from '../__generated__/gql/graphql';
import { GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS } from '../risk_management/NewRiskAssessmentModal';
import FrameworkList from './FrameworkList';
import { GET_USER_NOTIFICATIONS } from '../navigation_sidebar/NavigationSidebar';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const GET_FRAMEWORK_TEMPLATES = gql`
  query getFrameworkTemplates {
    organization {
      addonAccessControl {
        hasIsoAccess
      }
      riskFrameworks: availableFrameworkTemplates(frameworkType: ASSESSMENT) {
        frameworkName
        isEnabled
        templateId
        description
      }
      governanceFrameworks: availableFrameworkTemplates(
        frameworkType: GOVERNANCE
      ) {
        frameworkName
        isEnabled
        templateId
        description
      }
      projectGovernanceFrameworks: availableFrameworkTemplates(
        frameworkType: PROJECT_GOVERNANCE
      ) {
        frameworkName
        isEnabled
        templateId
        description
      }
      userCreatedFrameworks: availableFrameworkTemplates(
        frameworkType: GOVERNANCE
        userCreatedFrameworks: true
      ) {
        frameworkName
        isEnabled
        templateId
        description
      }
    }
  }
`;

const UPDATE_FRAMEWORKS_MUTATION = gql`
  mutation UpdateEnabledFrameworks(
    $enabledGovernanceFrameworks: [String!]!
    $enabledRiskFrameworks: [String!]!
    $enabledProjectGovernanceFrameworks: [String!]!
  ) {
    enableFrameworks(
      governanceFrameworkTemplates: $enabledGovernanceFrameworks
      riskAssessmentFrameworkTemplates: $enabledRiskFrameworks
      projectGovernanceFrameworkTemplates: $enabledProjectGovernanceFrameworks
    ) {
      projectGovernanceFrameworkTemplates {
        frameworkName
        templateId
        isEnabled
        description
      }
      governanceFrameworkTemplates {
        frameworkName
        templateId
        isEnabled
        description
      }
      riskAssessmentFrameworkTemplates {
        frameworkName
        templateId
        isEnabled
        description
      }
    }
  }
`;

const DELETE_FRAMEWORK_MUTATION = gql`
  mutation DeleteGovernanceFramework($templateId: ID!) {
    deleteGovernanceFramework(templateId: $templateId) {
      framework {
        id
        templateId
        name
        description
      }
    }
  }
`;

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

const FrameworkManagement: React.FC = () => {
  const { data } = useSuspenseQuery<GetFrameworkTemplatesQuery>(
    GET_FRAMEWORK_TEMPLATES
  );
  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');
  const [updateFrameworks, { loading, client }] =
    useMutation<UpdateEnabledFrameworksMutation>(UPDATE_FRAMEWORKS_MUTATION, {
      onCompleted: () => toast.success('Frameworks updated successfully!'),
      onError: error =>
        toast.error(`An unexpected error occurred: ${error.message}`),
      update: updateCacheAfterMutation,
      refetchQueries: [{ query: GET_ENABLED_RISK_ASSESSMENT_FRAMEWORKS }],
    });

  const [deleteFramework] = useMutation(DELETE_FRAMEWORK_MUTATION, {
    onCompleted: () => toast.success('Framework deleted successfully!'),
    onError: error =>
      toast.error(
        `An error occurred while deleting the framework: ${error.message}`
      ),
    refetchQueries: [
      {
        query: GET_FRAMEWORK_TEMPLATES,
      },
    ],
  });

  const [frameworkToDelete, setFrameworkToDelete] = useState<string | null>(
    null
  );

  const handleToggle = async (
    id: string,
    isEnabled: boolean
  ): Promise<void> => {
    const enabledGovernanceIds = getEnabledIds(
      [
        ...((data?.organization?.governanceFrameworks as Framework[]) || []),
        ...((data?.organization?.userCreatedFrameworks as Framework[]) || []),
      ],
      id,
      isEnabled
    );
    const enabledRiskIds = getEnabledIds(
      data?.organization?.riskFrameworks as Framework[],
      id,
      isEnabled
    );

    const enabledProjectGovernanceIds = getEnabledIds(
      data?.organization?.projectGovernanceFrameworks as Framework[],
      id,
      isEnabled
    );

    try {
      await updateFrameworks({
        variables: {
          enabledGovernanceFrameworks: enabledGovernanceIds,
          enabledRiskFrameworks: enabledRiskIds,
          enabledProjectGovernanceFrameworks: enabledProjectGovernanceIds,
        },
        refetchQueries: [GET_USER_NOTIFICATIONS],
      });
    } catch (error) {
      console.error('Error updating frameworks:', error);
      toast.error('Error updating frameworks');
      client.resetStore();
    }
  };

  const confirmDeleteFramework = async () => {
    if (frameworkToDelete) {
      try {
        await deleteFramework({ variables: { templateId: frameworkToDelete } });
        setFrameworkToDelete(null);
      } catch (error) {
        console.error('Error deleting framework:', error);
      }
    }
  };

  const [resetFramework] = useMutation(DELETE_FRAMEWORK_MUTATION, {
    onCompleted: data => {
      const deletedFramework = data.deleteGovernanceFramework.framework;
      toast.success(`Framework "${deletedFramework.name}" reset successfully!`);
    },
    onError: error =>
      toast.error(
        `An error occurred while resetting the framework: ${error.message}`
      ),
    refetchQueries: [
      {
        query: GET_FRAMEWORK_TEMPLATES,
      },
    ],
  });

  const [frameworkToReset, setFrameworkToReset] = useState<string | null>(null);

  const handleResetFramework = async (templateId: string) => {
    setFrameworkToReset(templateId);
  };

  const confirmResetFramework = async () => {
    if (frameworkToReset) {
      try {
        await resetFramework({
          variables: { templateId: frameworkToReset },
        });
        setFrameworkToReset(null);
      } catch (error) {
        console.error('Error resetting framework:', error);
      }
    }
  };

  const hasIsoAccess =
    data?.organization?.addonAccessControl?.hasIsoAccess ?? false;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Framework Management
        </Typography>
        <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
          {showBetaFeatures && (
            <Box mt={4}>
              <FrameworkList
                title="Custom Governance Frameworks"
                frameworks={
                  (data?.organization?.userCreatedFrameworks as Framework[]) ||
                  []
                }
                loading={loading}
                onToggle={handleToggle}
                onReset={handleResetFramework}
                showResetButton={true}
              />
            </Box>
          )}
          <Box mt={4}>
            <FrameworkList
              title="Standard Governance Frameworks"
              frameworks={
                (data?.organization?.governanceFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
              onReset={handleResetFramework}
              isoPerms={hasIsoAccess}
              showResetButton={true}
            />
          </Box>
          <Box mt={4}>
            <FrameworkList
              title="Risk Assessment Frameworks"
              frameworks={
                (data?.organization?.riskFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
              showResetButton={false}
            />
          </Box>
          <Box mt={4}>
            <FrameworkList
              title="Project Governance Frameworks"
              frameworks={
                (data?.organization
                  ?.projectGovernanceFrameworks as Framework[]) || []
              }
              loading={loading}
              onToggle={handleToggle}
              showResetButton={false}
            />
          </Box>
        </Box>
      </CardContent>
      <Dialog
        open={!!frameworkToDelete}
        onClose={() => setFrameworkToDelete(null)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this framework? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFrameworkToDelete(null)}>Cancel</Button>
          <Button onClick={confirmDeleteFramework} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!frameworkToReset}
        onClose={() => setFrameworkToReset(null)}
      >
        <DialogTitle>Confirm Framework Reset</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset this framework? This action will
            delete all instances and templates associated with this framework
            and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFrameworkToReset(null)}>Cancel</Button>
          <Button onClick={confirmResetFramework} color="error">
            Reset Framework
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const getEnabledIds = (
  frameworks: Framework[] | undefined | null,
  id: string,
  isEnabled: boolean
): string[] =>
  frameworks
    ?.map(fw => (fw.templateId === id ? { ...fw, isEnabled } : fw))
    .filter(fw => fw.isEnabled)
    .map(fw => fw.templateId) || [];

const updateCacheAfterMutation = (
  cache: ApolloCache<GetFrameworkTemplatesQuery>,
  result: FetchResult<UpdateEnabledFrameworksMutation>
): void => {
  const data = result.data;
  if (!data) return;

  const updatedGovernanceFrameworks =
    data?.enableFrameworks?.governanceFrameworkTemplates;
  const updatedRiskFrameworks =
    data?.enableFrameworks?.riskAssessmentFrameworkTemplates;
  const updatedProjectGovernanceFrameworks =
    data?.enableFrameworks?.projectGovernanceFrameworkTemplates;

  const currentData = cache.readQuery<GetFrameworkTemplatesQuery>({
    query: GET_FRAMEWORK_TEMPLATES,
  });

  cache.writeQuery({
    query: GET_FRAMEWORK_TEMPLATES,
    data: {
      organization: {
        __typename: 'Organization',
        addonAccessControl: currentData?.organization?.addonAccessControl ?? {
          hasIsoAccess: false,
          __typename: 'AddonAccessControl',
        },
        governanceFrameworks: updatedGovernanceFrameworks,
        riskFrameworks: updatedRiskFrameworks,
        projectGovernanceFrameworks: updatedProjectGovernanceFrameworks,
        userCreatedFrameworks:
          currentData?.organization?.userCreatedFrameworks ?? [],
      },
    },
  });
};

export default FrameworkManagement;
