import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { FC, useState } from 'react';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import BasicInfoStep from './steps/BasicInfoStep';
import MaterialUploadStep from './steps/MaterialUploadStep';
import ReviewStep from './steps/ReviewStep';
import { DateTime } from 'luxon';
import {
  CreateTrainingMutationVariables,
  IntervalEnum,
  TrailLiteracyTrainingPreview,
  User,
} from '../../__generated__/gql/graphql';

enum CreationStep {
  BASIC_INFO = 0,
  MATERIAL_UPLOAD = 1,
  REVIEW = 2,
}
const CREATE_TRAINING = gql`
  mutation CreateTraining(
    $title: String!
    $description: String!
    $responsiblePersonId: String!
    $interval: IntervalEnum!
    $isRequired: Boolean!
    $dueDate: DateTime
    $existingMaterialId: String
    $fileId: String
  ) {
    createTraining(
      title: $title
      description: $description
      responsiblePersonId: $responsiblePersonId
      interval: $interval
      isRequired: $isRequired
      dueDate: $dueDate
      existingMaterialId: $existingMaterialId
      fileId: $fileId
    ) {
      training {
        id
        title
        description
        responsiblePerson {
          id
          name
          email
        }
        isRequired
        interval
        trainingMaterial {
          id
          fileName
          url
        }
        dueDate
        completionLogs {
          id
          user {
            id
            name
            email
          }
          completionDate
        }
        lastModified
        created
      }
    }
  }
`;

interface TrainingCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  allUser: User[];
  availableTrainingMaterials: TrailLiteracyTrainingPreview[];
  hasLiteracyAccess: boolean;
}

export interface FormValues {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: IntervalEnum;
  isRequired: boolean;
  dueDate?: DateTime;
  existingMaterialId?: string;
  fileName?: string;
  fileContent?: string;
}

const TrainingCreationModal: FC<TrainingCreationModalProps> = ({
  isOpen,
  onClose,
  allUser,
  availableTrainingMaterials,
  hasLiteracyAccess,
}) => {
  const [selectedExistingMaterial, setSelectedExistingMaterial] = useState<
    string | null
  >(null);
  const [fileId, setFileId] = useState<string | null>(null);

  const [activeStep, setActiveStep] = useState<CreationStep>(
    CreationStep.BASIC_INFO
  );
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const formSchema: FormValues = {
    title: '',
    description: '',
    responsiblePersonId: '',
    interval: IntervalEnum.Yearly,
    isRequired: false,
  };

  const methods = useForm<FormValues>({
    defaultValues: formSchema,
  });

  const { control } = methods;
  const formValues = useWatch({ control }) as FormValues;

  const [createTraining] = useMutation(CREATE_TRAINING, {
    onCompleted: () => {
      onClose();
      toast.success('Training created successfully');
    },
    onError: error => {
      toast.error(`Error creating training: ${error.message}`);
    },
    update: (cache, { data }) => {
      if (!data?.createTraining?.training) return;

      cache.modify({
        fields: {
          allLiteracyTrainings(existingTrainings = []) {
            const newTrainingRef = cache.writeFragment({
              data: data.createTraining.training,
              fragment: gql`
                fragment NewTraining on LiteracyTraining {
                  id
                  title
                  description
                  responsiblePerson {
                    id
                    name
                    email
                  }
                  isRequired
                  interval
                  trainingMaterial {
                    id
                    fileName
                    url
                  }
                  dueDate
                  completionLogs {
                    id
                    user {
                      id
                      name
                      email
                    }
                    completionDate
                  }
                  lastModified
                  created
                  __typename
                }
              `,
            });
            return [...existingTrainings, newTrainingRef];
          },
        },
      });
    },
  });

  const handleNext = () => {
    setActiveStep(prevStep => (prevStep + 1) as CreationStep);
  };

  const handleBack = () => {
    setActiveStep(prevStep => (prevStep - 1) as CreationStep);
  };

  const handleCreate = async () => {
    try {
      if (
        !formValues.title ||
        !formValues.description ||
        !formValues.responsiblePersonId ||
        !formValues.dueDate
      ) {
        toast.error('Please fill in all required fields');
        return;
      }

      const variables: CreateTrainingMutationVariables = {
        title: formValues.title,
        description: formValues.description,
        responsiblePersonId: formValues.responsiblePersonId,
        interval: formValues.interval,
        isRequired: formValues.isRequired,
        dueDate: formValues.dueDate,
      };

      if (selectedExistingMaterial) {
        variables.existingMaterialId = selectedExistingMaterial;
      } else if (fileId) {
        variables.fileId = fileId;
      }

      await createTraining({
        variables,
      });
    } catch (error) {
      console.error('Error creating training:', error);
      toast.error('Failed to create training');
    }
  };

  const steps = [
    {
      label: 'Basic Information',
      component: <BasicInfoStep allUsers={allUser} />,
    },
    {
      label: 'Training Material',
      component: (
        <MaterialUploadStep
          onFileSelected={file => {
            setSelectedFile(file);
          }}
          onFileIdSelected={id => {
            setFileId(id);
          }}
          onExistingMaterialSelected={materialId => {
            setSelectedExistingMaterial(materialId);
          }}
          availableTrainingMaterials={availableTrainingMaterials || []}
          hasLiteracyAccess={hasLiteracyAccess}
        />
      ),
    },
    {
      label: 'Review',
      component: (
        <ReviewStep
          formValues={formValues}
          selectedFile={selectedFile}
          selectedExistingMaterial={availableTrainingMaterials?.find(
            m => m.id === selectedExistingMaterial
          )}
        />
      ),
    },
  ];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          width: '80%',
          maxWidth: '850px',
          p: 2,
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title="Create a new Training" />
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <FormProvider {...methods}>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {steps[activeStep].component}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
                marginTop: 'auto',
              }}
            >
              <Button
                onClick={handleBack}
                disabled={activeStep === CreationStep.BASIC_INFO}
              >
                Back
              </Button>
              {activeStep === CreationStep.REVIEW ? (
                <Button
                  variant="contained"
                  onClick={handleCreate}
                  disabled={
                    !isStepValid(
                      activeStep,
                      formValues,
                      selectedFile,
                      selectedExistingMaterial
                    )
                  }
                >
                  Create Training
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={
                    !isStepValid(
                      activeStep,
                      formValues,
                      selectedFile,
                      selectedExistingMaterial
                    )
                  }
                >
                  Next
                </Button>
              )}
            </Box>
          </FormProvider>
        </CardContent>
      </Card>
    </Modal>
  );
};

const isStepValid = (
  step: CreationStep,
  formValues: Partial<FormValues>,
  selectedFile: File | null,
  selectedExistingMaterial?: string | null
): boolean => {
  switch (step) {
    case CreationStep.BASIC_INFO:
      return !!(
        formValues.title &&
        formValues.description &&
        formValues.responsiblePersonId &&
        formValues.dueDate
      );
    case CreationStep.MATERIAL_UPLOAD:
      return !!(selectedFile || selectedExistingMaterial);
    default:
      return true;
  }
};

export default TrainingCreationModal;
