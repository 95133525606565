import {
  InsertDriveFile,
  Schedule,
  Code,
  Download,
  ArrowDropDown,
} from '@mui/icons-material';
import { Box, Typography, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { colorThemes } from '../../../../../theme';
import { formatDate } from '../../../../../utils/dateTimeHelpers';
import { DocumentationResultSource } from '../../../../../__generated__/gql/graphql';
import {
  createElement,
  Prism as SyntaxHighlighter,
} from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const SHARED_STYLES = {
  darkBlueHover: {
    '&:hover': {
      backgroundColor: colorThemes.DARK_BLUE_400,
    },
  },
  transition: {
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
} as const;

const getLanguage = (fileName: string): string => {
  const ext = fileName.split('.').pop()?.toLowerCase() || '';
  const languageMap: { [key: string]: string } = {
    py: 'python',
    js: 'javascript',
    jsx: 'jsx',
    ts: 'typescript',
    tsx: 'tsx',
    java: 'java',
    cpp: 'cpp',
    c: 'c',
    go: 'go',
    rs: 'rust',
    swift: 'swift',
    kt: 'kotlin',
    cs: 'csharp',
    php: 'php',
    rb: 'ruby',
    r: 'r',
  };
  return languageMap[ext] || 'text';
};

export const SourceRenderer = ({
  sourceFile,
  expanded,
}: {
  sourceFile: DocumentationResultSource;
  expanded: boolean;
}) => {
  const [isCodeExpanded, setIsCodeExpanded] = useState<boolean>(false);
  const [activeHighlight, setActiveHighlight] = useState<number | null>(null);
  const [selectedHighlight, setSelectedHighlight] = useState<number | null>(
    null
  );

  const highlights = sourceFile.highlights || [];

  return (
    <Box
      sx={{
        border: `1px solid ${colorThemes.DARK_BLUE_400}`,
        borderRadius: '8px',
        padding: '12px',
        opacity: expanded ? 1 : 0,
        backgroundColor: colorThemes.DARK_BLUE_700,
        transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <InsertDriveFile sx={{ color: colorThemes.DARK_BLUE_100 }} />
            <Tooltip title={sourceFile.sourceFile.filePath} arrow>
              <Typography
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {sourceFile.sourceFile.fileName}
              </Typography>
            </Tooltip>
          </Box>

          {sourceFile.sourceFile.url && (
            <Box
              component="a"
              href={sourceFile.sourceFile.url}
              download
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: colorThemes.DARK_BLUE_200,
                textDecoration: 'none',
                padding: '4px 8px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: colorThemes.DARK_BLUE_400,
                  color: colorThemes.DARK_BLUE_100,
                },
              }}
            >
              <Download sx={{ fontSize: '1.1rem' }} />
              <Typography sx={{ fontSize: '0.9rem', color: 'white' }}>
                Download
              </Typography>
            </Box>
          )}
        </Box>

        {sourceFile.sourceFile.creationTimestamp && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              paddingLeft: '28px',
            }}
          >
            <Schedule
              sx={{ fontSize: '1rem', color: colorThemes.DARK_BLUE_200 }}
            />
            <Typography
              sx={{ color: colorThemes.DARK_BLUE_200, fontSize: '0.9rem' }}
            >
              {formatDate(sourceFile.sourceFile.creationTimestamp)}
            </Typography>
          </Box>
        )}

        <Box sx={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
          {highlights.map((highlight, index) => (
            <Box
              key={index}
              onMouseEnter={() => setActiveHighlight(index)}
              onMouseLeave={() => setActiveHighlight(null)}
              onClick={() =>
                setSelectedHighlight(selectedHighlight === index ? null : index)
              }
              sx={{
                padding: '6px 12px',
                borderRadius: '6px',
                backgroundColor: colorThemes.DARK_BLUE_600,
                color: 'white',
                cursor: 'pointer',
                border: `1px solid ${colorThemes.DARK_BLUE_400}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  backgroundColor: colorThemes.DARK_BLUE_600,
                  borderColor: colorThemes.DARK_BLUE_200,
                },
                ...(selectedHighlight === index && {
                  backgroundColor: 'rgba(65, 105, 225, 0.1)',
                  borderColor: '#4169E1',
                }),
              }}
            >
              {highlight?.name}
            </Box>
          ))}
        </Box>

        {expanded && sourceFile.sourceFile.content && (
          <>
            <Box
              onClick={() => setIsCodeExpanded(!isCodeExpanded)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
                padding: '4px 8px',
                borderRadius: '4px',
                color: colorThemes.DARK_BLUE_200,
                '&:hover': {
                  backgroundColor: 'inherit',
                  color: 'inherit',
                },
              }}
            >
              <Code sx={{ fontSize: '1.1rem' }} />
              <Typography sx={{ fontSize: '0.9rem', color: 'white' }}>
                {isCodeExpanded ? 'Hide Code' : 'Show Code'}
              </Typography>
              <ArrowDropDown
                sx={{
                  transform: isCodeExpanded ? 'rotate(180deg)' : '',
                  ...SHARED_STYLES.transition,
                }}
              />
            </Box>

            <Box
              sx={{
                marginTop: '8px',
                borderRadius: '6px',
                maxHeight: isCodeExpanded ? '5000px' : 0,
                overflow: 'scroll',
                transition: 'max-height 800ms cubic-bezier(0.4, 0, 0.2, 1)',
              }}
            >
              <SyntaxHighlighter
                language={getLanguage(sourceFile.sourceFile.fileName)}
                style={vscDarkPlus}
                wrapLines={true}
                showLineNumbers={true}
                renderer={({ rows, stylesheet, useInlineStyles }) => {
                  return rows.map((row, i) => {
                    const lineNumber = i + 1;
                    const highlight =
                      activeHighlight !== null
                        ? highlights[activeHighlight]?.lines?.includes(
                            lineNumber
                          )
                        : selectedHighlight !== null
                        ? highlights[selectedHighlight]?.lines?.includes(
                            lineNumber
                          )
                        : false;

                    return (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          backgroundColor: highlight
                            ? 'rgba(65, 105, 225, 0.1)'
                            : 'transparent',
                          borderLeft: highlight
                            ? '3px solid #4169E1'
                            : '3px solid transparent',
                          transition: 'all 0.3s ease',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div key="code-content" style={{ width: '100%' }}>
                          {createElement({
                            node: row,
                            stylesheet,
                            useInlineStyles,
                            key: `code-line-${i}`,
                          })}
                        </div>
                      </div>
                    );
                  });
                }}
                customStyle={{
                  margin: 0,
                  borderRadius: '6px',
                  background: colorThemes.DARK_BLUE_800,
                }}
              >
                {sourceFile.sourceFile.content}
              </SyntaxHighlighter>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
