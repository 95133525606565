import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  Observable,
  Operation,
  NextLink,
  FetchResult,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { setContext } from '@apollo/client/link/context';
import {
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import App from './App';
import * as Sentry from '@sentry/react';

import { PostHogProvider } from 'posthog-js/react';

import { AuthProvider } from 'react-oidc-context';
import { getUser } from './utils/genericHelpers';
import { WebStorageStateStore, UserManager } from 'oidc-client-ts';
import { GovernanceRequirementStatus } from './__generated__/gql/graphql';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

Sentry.init({
  dsn: 'https://3c66ed64add9b6f6a42e98b85859fb9d@o4507090038423552.ingest.de.sentry.io/4508047733751888',
  environment: import.meta.env.VITE_ENVIRONMENT || 'development',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.feedbackIntegration({
      autoInject: false,
      colorScheme: 'dark',
      showBranding: false,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const onSigninCallback = (_user: any): void => {
  const firstLogin = _user.profile?.firstLogin;
  if (firstLogin === undefined || firstLogin === true) {
    window.location.href = '/welcome';
  }

  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: window._env_.AUTH_URL,
  client_id: 'trail-lux',
  redirect_uri: window.location.origin + '/projects',
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: window.location.origin,
  silent_redirect_uri:
    window.location.origin + '/authentication/silent_callback',
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  monitorSession: true,
  metadata: {
    issuer: window._env_.AUTH_URL,
    authorization_endpoint: `${window._env_.AUTH_URL}/protocol/openid-connect/auth`,
    token_endpoint: `${window._env_.AUTH_URL}/protocol/openid-connect/token`,
    end_session_endpoint: `${window._env_.AUTH_URL}/protocol/openid-connect/logout`,
    userinfo_endpoint: `${window._env_.AUTH_URL}/protocol/openid-connect/userinfo`,
    jwks_uri: `${window._env_.AUTH_URL}/protocol/openid-connect/certs`,
  },
  onSigninCallback,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

// This is neccessary for using telepresence on mac osx because the DNS resolution node invokes does not honor the /etc/resolver directory
const API_ENDPOINT = import.meta.env.VITE_API_URL
  ? `${import.meta.env.VITE_API_URL}/graphql`
  : '/graphql';

const httpLink = new HttpLink({
  uri: API_ENDPOINT,
});

const authLink = setContext(async (_, { headers }) => {
  const user = getUser();
  const token = user?.access_token ?? '';
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const removeTypenameLink = removeTypenameFromVariables();

const userManager = new UserManager(oidcConfig);

const handleAuthError = (
  operation: Operation,
  forward: NextLink
): Observable<FetchResult> => {
  return new Observable(observer => {
    userManager
      .signinSilent()
      .then(() => {
        const subscription = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
        return () => subscription.unsubscribe();
      })
      .catch(() => {
        userManager.signinRedirect();
      });
  });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err.extensions?.code === 'UNAUTHENTICATED' ||
          err.message.includes('Unauthorized')
        ) {
          const user = getUser();
          if (user) {
            return handleAuthError(operation, forward);
          } else {
            userManager.signinRedirect();
          }
        }
      }
    }

    if (
      networkError &&
      'statusCode' in networkError &&
      networkError.statusCode === 403
    ) {
      const user = getUser();
      if (user) {
        return handleAuthError(operation, forward);
      } else {
        userManager.signinRedirect();
      }
    }

    return forward(operation);
  }
);

const cache = new InMemoryCache({
  typePolicies: {
    GovernanceRequirementStatus: {
      fields: {
        evidence: {
          merge(_existing = [], incoming: GovernanceRequirementStatus[]) {
            return incoming;
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  link: from([errorLink, authLink, removeTypenameLink, httpLink]),
  cache,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <AuthProvider {...oidcConfig}>
        <ApolloProvider client={client}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <RouterProvider router={router} />
          </LocalizationProvider>
        </ApolloProvider>
      </AuthProvider>
    </PostHogProvider>
  </React.StrictMode>
);
