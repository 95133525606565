import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Chip, Stack } from '@mui/material';
import { colorThemes } from '../theme';
import { FC } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateTime } from 'luxon';

interface TrainingDetailCardProps {
  id: string;
  onClick?: () => void;
  title: string;
  isSelected: boolean;
  required: boolean;
  done: boolean;
  nextDue: DateTime | null;
  description: string;
}

const TrainingDetailCard: FC<TrainingDetailCardProps> = ({
  onClick,
  title,
  isSelected,
  required,
  done,
  nextDue,
  description,
}) => {
  const formatDate = (dateTime: DateTime | null) => {
    if (!dateTime) return 'No due date';
    return dateTime.toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const isOverdue = nextDue ? nextDue < DateTime.now() : false;

  return (
    <Card
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        opacity: done ? 0.7 : 1,
        transition: 'transform 0.2s ease-in-out',
        width: 400,
        height: 200,
        position: 'relative',
        backgroundColor: isSelected
          ? colorThemes.DARK_BLUE_400
          : 'background.paper',
        '&:hover': {
          cursor: onClick ? 'pointer' : 'default',
          backgroundColor: onClick ? colorThemes.DARK_BLUE_400 : '',
          transform: onClick ? 'scale(1.02)' : 'none',
          boxShadow: 4,
        },
        ...(isSelected && {
          border: `2px solid ${colorThemes.YELLOW_600}`,
          boxShadow: `0 0 10px ${colorThemes.YELLOW_600}40`,
        }),
        ...(done && {
          border: `2px solid ${colorThemes.GREEN_500}40`,
        }),
      }}
      onClick={onClick}
    >
      <CardContent
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '&:last-child': { pb: 2 },
        }}
      >
        <Stack spacing={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            maxWidth={'100%'}
          >
            <Typography
              sx={{
                fontWeight: 600,
                flexGrow: 1,
                paddingRight: 2,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                textOverflow: 'ellipsis',
              }}
              color="text.primary"
              variant="h6"
              title={title}
            >
              {title}
            </Typography>
            {!done && (
              <Chip
                label={required ? 'Required' : 'Optional'}
                size="small"
                sx={{
                  fontWeight: 600,
                  background: required
                    ? colorThemes.ACTION_BLUE
                    : colorThemes.DARK_BLUE_200,
                  px: 1,
                  flexShrink: 0,
                }}
              />
            )}
          </Box>

          {description && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {description}
            </Typography>
          )}
        </Stack>

        <Stack spacing={1}>
          <Box
            sx={{
              height: '1px',
              backgroundColor: 'divider',
              my: 1,
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Chip
              label={done ? 'Completed' : 'Not Started'}
              size="small"
              color={'default'}
              variant={done ? 'filled' : 'outlined'}
              sx={{
                ...(done && {
                  backgroundColor: colorThemes.GREEN_500,
                  color: 'white',
                }),
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              {nextDue && (
                <AccessTimeIcon
                  fontSize="small"
                  sx={{ color: isOverdue ? colorThemes.RED_500 : 'white' }}
                />
              )}
              <Typography
                variant="body2"
                color={isOverdue ? 'error' : 'text.secondary'}
              >
                {formatDate(nextDue)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TrainingDetailCard;
