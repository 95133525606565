import { DateTime, DurationLike } from 'luxon';
import { Interval } from '../__generated__/gql/graphql';

const INTERVAL_DURATIONS: Record<Interval, DurationLike> = {
  QUARTERLY: { months: 3 },
  YEARLY: { years: 1 },
  BIENNIAL: { years: 2 },
  ONCE: { days: 0 },
};

export const calculateDueDate = (
  initialDueDate: string,
  interval?: Interval | null,
  completionDate?: string | null
): DateTime => {
  const dueDateObj = DateTime.fromISO(initialDueDate);

  if (!completionDate) {
    return dueDateObj;
  }

  const lastCompletion = DateTime.fromISO(completionDate);

  if (!interval || interval === 'ONCE') {
    return lastCompletion;
  }

  return lastCompletion.plus(INTERVAL_DURATIONS[interval]);
};
