import { ArrowDropDown } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { colorThemes } from '../../../../../theme';
import { DocumentationResultSource } from '../../../../../__generated__/gql/graphql';
import { SourceRenderer } from './SourceRenderer';

const SHARED_STYLES = {
  transition: {
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  },
  borderRadius: {
    borderRadius: '10px',
  },
  darkBlueHover: {
    '&:hover': {
      backgroundColor: colorThemes.DARK_BLUE_400,
    },
  },
} as const;

export const SourcesAccordion = ({
  sources,
}: {
  sources: DocumentationResultSource[];
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box
      sx={{
        background: 'transparent',
        border: 0,
        boxShadow: 'none',
        width: '100%',
        cursor: 'default',
        minHeight: 'min-content',
      }}
    >
      <Box
        onClick={() => setExpanded(!expanded)}
        sx={{
          backgroundColor: colorThemes.DARK_BLUE_600,
          borderRadius: expanded ? '10px 10px 0 0' : '10px',
          display: 'flex',
          padding: '10px 16px',
          alignItems: 'center',
          gap: '8px',
          width: expanded ? '100%' : '120px',
          marginLeft: 'auto',
          cursor: 'pointer',
          ...SHARED_STYLES.transition,
        }}
      >
        <Typography sx={{ whiteSpace: 'nowrap' }}>
          Sources ({sources.length})
        </Typography>
        <ArrowDropDown
          sx={{
            transform: expanded ? 'rotate(180deg)' : '',
            ...SHARED_STYLES.transition,
          }}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: colorThemes.DARK_BLUE_600,
          borderRadius: '0 0 10px 10px',
          padding: expanded ? '16px' : 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: expanded ? '100%' : '120px',
          height: expanded ? 'auto' : 0,
          marginLeft: 'auto',
          overflow: expanded ? 'visible' : 'hidden',
          ...SHARED_STYLES.transition,
        }}
      >
        {sources.map((source, index) => (
          <SourceRenderer key={index} sourceFile={source} expanded={expanded} />
        ))}
      </Box>
    </Box>
  );
};
