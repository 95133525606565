import { FC, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  Clear,
  Upload as UploadIcon,
  Description,
  CheckCircle,
  PictureAsPdf,
  Visibility,
  Info,
} from '@mui/icons-material';
import { colorThemes } from '../../../theme';
import { TrailLiteracyTrainingPreview } from '../../../__generated__/gql/graphql';
import PdfViewer from '../../../components/PdfViewer';
import PaywallModal from '../../../components/PaywallModal';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';

interface MaterialUploadStepProps {
  onFileSelected: (file: File | null) => void;
  onFileIdSelected: (fileId: string | null) => void;
  onExistingMaterialSelected: (materialId: string | null) => void;
  availableTrainingMaterials: TrailLiteracyTrainingPreview[];
  hasLiteracyAccess: boolean;
}

interface TrainingMaterial {
  id: string;
  url: string;
}

const GET_PRESIGNED_URL = gql`
  mutation GetPresignedUploadUrl($fileName: String!, $contentType: String!) {
    getPresignedUploadUrl(fileName: $fileName, contentType: $contentType) {
      presignedUrl
      fileId
    }
  }
`;

const MaterialUploadStep: FC<MaterialUploadStepProps> = ({
  onFileSelected,
  onFileIdSelected,
  onExistingMaterialSelected,
  availableTrainingMaterials,
  hasLiteracyAccess,
}) => {
  const [getPresignedUrl] = useMutation(GET_PRESIGNED_URL);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadMethod, setUploadMethod] = useState<'new' | 'existing'>('new');
  const [selectedExistingMaterial, setSelectedExistingMaterial] =
    useState<string>('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [showPaywall, setShowPaywall] = useState(false);

  const handleUpgrade = () => {
    window.location.href = '/pricing';
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        try {
          const { data } = await getPresignedUrl({
            variables: {
              fileName: file.name,
              contentType: file.type,
            },
          });

          const requestInit: RequestInit = {
            method: 'PUT',
            body: file,
          };

          if (file.type) {
            requestInit.headers = {
              'Content-Type': file.type,
            };
          }

          const response = await fetch(
            data.getPresignedUploadUrl.presignedUrl,
            requestInit
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to upload file: ${errorText}`);
          }

          setSelectedFile(file);
          onFileSelected(file);
          onFileIdSelected(data.getPresignedUploadUrl.fileId);
          setSelectedExistingMaterial('');
          onExistingMaterialSelected(null);
        } catch (error) {
          console.error('Error uploading file:', error);
          toast.error('Failed to upload file');
          setSelectedFile(null);
          onFileSelected(null);
          onFileIdSelected(null);
        }
      }
    },
    [
      onFileSelected,
      onFileIdSelected,
      onExistingMaterialSelected,
      getPresignedUrl,
    ]
  );

  const handleRemoveFile = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      setSelectedFile(null);
      onFileSelected(null);
      onFileIdSelected(null);
    },
    [onFileSelected, onFileIdSelected]
  );

  const handleExistingMaterialChange = useCallback(
    (material: TrainingMaterial) => {
      if (!hasLiteracyAccess) {
        setShowPaywall(true);
        return;
      }
      setSelectedExistingMaterial(material.id);
      onExistingMaterialSelected(material.id);
      handleRemoveFile();
    },
    [hasLiteracyAccess, onExistingMaterialSelected, handleRemoveFile]
  );

  const handlePreviewOpen = useCallback((pdfData: string) => {
    setPdfData(pdfData);
    setIsPreviewOpen(true);
  }, []);

  const handlePreviewClose = useCallback(() => {
    setIsPreviewOpen(false);
    setPdfData(null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxSize: 10485760, // 10MB
    disabled: uploadMethod === 'existing',
  });

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h6" gutterBottom>
          Training Material
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={uploadMethod}
            onChange={(_, newValue) => setUploadMethod(newValue)}
            variant="fullWidth"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab
              icon={<UploadIcon />}
              label="Upload New"
              value="new"
              sx={{ textTransform: 'none' }}
            />
            <Tab
              icon={<Description />}
              label="Use Trail's Material"
              value="existing"
              sx={{ textTransform: 'none' }}
            />
          </Tabs>
          <Box sx={{ p: 3 }}>
            {uploadMethod === 'existing' ? (
              <Grid container spacing={2}>
                {availableTrainingMaterials.length > 0 ? (
                  availableTrainingMaterials
                    .filter(
                      (
                        material
                      ): material is TrailLiteracyTrainingPreview & {
                        trainingMaterial: NonNullable<
                          TrailLiteracyTrainingPreview['trainingMaterial']
                        >;
                      } =>
                        Boolean(
                          material?.trainingMaterial?.id &&
                            material?.trainingMaterial?.url
                        )
                    )
                    .map(material => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={material.trainingMaterial.id}
                      >
                        <Card
                          sx={{
                            height: '100%',
                            position: 'relative',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                              transform: 'translateY(-4px)',
                              boxShadow: 3,
                            },
                            border:
                              selectedExistingMaterial ===
                              material?.trainingMaterial?.id
                                ? `2px solid ${colorThemes.DARK_BLUE_100}`
                                : '2px solid transparent',
                          }}
                        >
                          <Box
                            onClick={() => {
                              const trainingMaterial =
                                material?.trainingMaterial;
                              if (
                                trainingMaterial?.id &&
                                trainingMaterial?.url
                              ) {
                                handleExistingMaterialChange({
                                  id: trainingMaterial.id,
                                  url: trainingMaterial.url,
                                });
                              }
                            }}
                            sx={{
                              height: '100%',
                              cursor: 'pointer',
                              '&:hover': {
                                bgcolor: 'action.hover',
                              },
                            }}
                          >
                            <CardContent>
                              <Box
                                sx={{
                                  mb: 2,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start',
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  fontWeight="bold"
                                  sx={{
                                    flexGrow: 1,
                                    mr: 1,
                                  }}
                                >
                                  {material.previewTitle}
                                </Typography>
                                {selectedExistingMaterial ===
                                  material?.trainingMaterial?.id && (
                                  <CheckCircle
                                    sx={{
                                      color: colorThemes.DARK_BLUE_100,
                                      flexShrink: 0,
                                    }}
                                  />
                                )}
                              </Box>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  display: '-webkit-box',
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: 'vertical',
                                  overflow: 'hidden',
                                  mb: 2,
                                  minHeight: '4.5em',
                                }}
                              >
                                {material.description}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Chip
                                  size="small"
                                  label="PDF"
                                  icon={<PictureAsPdf fontSize="small" />}
                                  sx={{
                                    bgcolor: 'rgba(25, 118, 210, 0.08)',
                                    '& .MuiChip-icon': {
                                      color: 'primary.main',
                                    },
                                  }}
                                />
                                <Tooltip title="Preview PDF">
                                  <IconButton
                                    size="small"
                                    onClick={e => {
                                      e.stopPropagation();
                                      handlePreviewOpen(
                                        material.trainingMaterial
                                          .contentBase64 ?? ''
                                      );
                                    }}
                                  >
                                    <Visibility />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </CardContent>
                          </Box>
                        </Card>
                      </Grid>
                    ))
                ) : (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 4,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        border: '1px dashed',
                        borderColor: 'divider',
                      }}
                    >
                      <Description
                        sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }}
                      />
                      <Typography
                        variant="h6"
                        color="text.secondary"
                        gutterBottom
                      >
                        No Trail Training Materials Available
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        There are currently no existing training materials to
                        choose from. Please check back later.
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            ) : (
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed',
                  borderColor: colorThemes.DARK_BLUE_100,
                  borderRadius: 1,
                  p: 3,
                  textAlign: 'center',
                  cursor: 'pointer',
                  bgcolor: () =>
                    isDragActive
                      ? 'action.hover'
                      : selectedFile
                      ? 'rgba(25, 118, 210, 0.04)'
                      : 'background.paper',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <input {...getInputProps()} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <UploadIcon fontSize="large" color="action" />
                  {selectedFile ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography>{selectedFile.name}</Typography>
                      <Tooltip title="Remove selected file">
                        <IconButton
                          onClick={e => {
                            e.stopPropagation();
                            handleRemoveFile();
                          }}
                          color="error"
                          size="small"
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    <Typography>
                      {isDragActive
                        ? 'Drop the file here...'
                        : 'Drag and drop training material, or click to select'}
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      Supported format: PDF (max 10MB)
                    </Typography>
                    <Tooltip title="PDF should be in horizontal orientation for optimal viewing">
                      <Info
                        fontSize="small"
                        color="action"
                        sx={{ fontSize: '16px', cursor: 'help' }}
                      />
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 0.5,
                      fontStyle: 'italic',
                    }}
                  >
                    Please ensure your PDF is in horizontal orientation
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* PDF Preview Dialog */}
        <Dialog
          open={isPreviewOpen}
          onClose={handlePreviewClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>PDF Preview</DialogTitle>
          <DialogContent>
            <Box sx={{ height: '70vh', width: '100%' }}>
              {pdfData && (
                <PdfViewer
                  pdfData={pdfData}
                  currentPage={1}
                  onPageLoad={(_totalPages: number) => {}}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
      <PaywallModal
        open={showPaywall}
        onClose={() => setShowPaywall(false)}
        onAction={handleUpgrade}
        title="Access Pre-Made Training Templates"
        primaryText="Start Training Faster with Ready-Made Materials"
        secondaryText={
          "Unlock Trail's library of professional training templates and get immediate access to:\n\n" +
          '• Ready-to-use training materials\n' +
          '• Professionally curated content\n'
        }
        actionButtonText="Add Addon Now"
      />
    </>
  );
};

export default MaterialUploadStep;
