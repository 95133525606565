import React, { useState } from 'react';
import {
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Tooltip,
} from '@mui/material';
import { Refresh as ResetIcon } from '@mui/icons-material';
import PaywallModal from '../components/PaywallModal';

interface Framework {
  frameworkName: string;
  isEnabled: boolean;
  templateId: string;
  description: string | null;
}

interface FrameworkListProps {
  title: string;
  frameworks: Framework[];
  loading: boolean;
  onToggle: (id: string, isEnabled: boolean) => void;
  onReset?: (templateId: string) => void;
  showResetButton: boolean;
  isoPerms?: boolean;
}

const FrameworkList: React.FC<FrameworkListProps> = ({
  title,
  frameworks,
  loading,
  onToggle,
  onReset,
  showResetButton,
  isoPerms = false,
}) => {
  const [showPaywall, setShowPaywall] = useState(false);

  const handleSwitchClick = (
    templateId: string,
    newState: boolean,
    frameworkName: string
  ) => {
    if (!isoPerms && frameworkName === 'ISO/IEC 42001:2023') {
      setShowPaywall(true);
      return;
    }
    onToggle(templateId, newState);
  };

  const handleUpgrade = () => {
    window.location.href = '/pricing';
  };
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <List>
        {frameworks.map(
          ({ frameworkName, isEnabled, templateId, description }) => (
            <ListItem
              key={templateId}
              alignItems="flex-start"
              sx={{ flexDirection: 'column', alignItems: 'stretch' }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItemText primary={frameworkName} secondary={description} />
                <Switch
                  edge="end"
                  onChange={() =>
                    handleSwitchClick(templateId, !isEnabled, frameworkName)
                  }
                  checked={isEnabled}
                  disabled={loading}
                />
              </Box>
              {showResetButton && onReset && (
                <Box display="flex" justifyContent="flex-end" mt={1}>
                  <Tooltip
                    title={!isEnabled ? 'Enable the framework to reset' : ''}
                  >
                    <span>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => onReset(templateId)}
                        disabled={loading || !isEnabled}
                        startIcon={<ResetIcon />}
                      >
                        Reset
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              )}
            </ListItem>
          )
        )}
      </List>

      <PaywallModal
        open={showPaywall}
        onClose={() => setShowPaywall(false)}
        onAction={handleUpgrade}
        title="Unlock ISO 42001 Framework"
        primaryText="Upgrade to access the ISO 42001 Framework"
        secondaryText="Enhance your AI management with our premium plan, featuring the ISO 42001 framework for comprehensive compliance and risk management. Join industry leaders in securing your AI processes and building trust with stakeholders."
        actionButtonText="Upgrade Now"
      />
    </>
  );
};

export default FrameworkList;
