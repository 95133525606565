import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { formatDateTime } from '../../../../utils/dateTimeHelpers';
import { DocumentationResultObject } from '../../../../__generated__/gql/graphql';

import DocumentationResultCard from './documentation_result/DocumentationResultCard';

export const DocumentationResultViewer = ({
  creationTimestamp,
  documentationResult,
}: {
  creationTimestamp: string;
  documentationResult:
    | DocumentationResultObject['generatedDocumentation']
    | null
    | undefined;
}) => {
  const formattedDateTime = formatDateTime(creationTimestamp);

  return (
    <>
      {creationTimestamp && (
        <Typography variant="subtitle1" color="textSecondary">
          Generated: {formattedDateTime}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          gap: '12px',
          maxHeight: 'calc(100vh - 230px)',
          overflow: 'auto',
          position: 'relative',
          width: '100%',
        }}
      >
        {documentationResult ? (
          documentationResult
            .filter(componentResult => componentResult !== null)
            .map(componentResult => (
              <DocumentationResultCard
                component={componentResult!}
                key={`componentResult-${componentResult?.name}-${uuidv4()}`}
              />
            ))
        ) : (
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontSize: '1.2rem',
              marginTop: '1rem',
            }}
          >
            No documentation generated yet. Click the Generate Documentation
            button to get started.
          </Typography>
        )}
      </Box>
    </>
  );
};
