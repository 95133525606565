import { Box, Button, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { role } from './ValidationQuestions';
import { colorThemes } from '../theme';
import { gql, useMutation } from '@apollo/client';
import { toast, ToastContainer } from 'react-toastify';
import { RiskClass, RiskClassInput } from '../__generated__/gql/graphql';

interface QuestionaireInterimResultProps {
  projectId?: string;
  setRiskClass?: (riskClass: RiskClass) => void;
  nextStepRiskClass?: () => void;
  onNextValidationStage?: () => void;
  language?: 'EN' | 'DE';
}

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateRiskClassInterimStep(
    $riskClass: RiskClassInput
    $projectId: String!
  ) {
    updateProject(projectId: $projectId, riskClass: $riskClass) {
      __typename
      project {
        __typename
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
        }
        id
      }
    }
  }
`;

const QuestionaireInterimResult: FC<QuestionaireInterimResultProps> = ({
  projectId,
  setRiskClass,
  nextStepRiskClass,
  onNextValidationStage,
  language = 'EN',
}) => {
  const riskClass: RiskClassInput = {
    riskClassRole: role,
  };

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      riskClass,
    },
  });

  const submitUpdateRiskClass = async () => {
    try {
      await updateProject();
      toast.success(
        language === 'DE'
          ? 'Risikoklasse erfolgreich gespeichert'
          : 'Successfully saved Risk Class'
      );
    } catch {
      toast.error(
        language === 'DE'
          ? 'Speichern der Risikoklasse fehlgeschlagen'
          : 'Saving Risk Class failed'
      );
    }
  };

  const submitCreateRiskClass = () => {
    if (setRiskClass) {
      setRiskClass(riskClass as RiskClass);
    }
    if (nextStepRiskClass) {
      nextStepRiskClass();
    }
  };

  return (
    <Box
      bgcolor={'rgba(0, 123, 255, 0.2)'}
      width={'80%'}
      p={4}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      borderRadius={'20px'}
      gap={2}
      border={'1px solid'}
      borderColor={colorThemes.ACTION_BLUE}
    >
      {role ? (
        setRiskClass ? (
          <>
            <Typography variant={'h5'}>
              {language === 'DE'
                ? 'Das betrachtete System ist wahrscheinlich kein KI-System gemäß dem EU-KI-Gesetz'
                : 'System under consideration is likely no AI system under the EU AI Act'}
            </Typography>
            <Button
              sx={{ mt: '40px' }}
              variant={'contained'}
              color={'primary'}
              onClick={submitCreateRiskClass}
            >
              {language === 'DE' ? 'Weiter' : 'Next'}
            </Button>
          </>
        ) : (
          <>
            <Typography variant={'h5'}>
              {language === 'DE'
                ? 'Das betrachtete System ist wahrscheinlich kein KI-System gemäß dem EU AI Act'
                : 'System under consideration is likely no AI system under the EU AI Act'}
            </Typography>
            <Button
              sx={{ mt: '40px' }}
              variant={'contained'}
              color={'primary'}
              onClick={submitUpdateRiskClass}
              component={Link}
              href="/projects"
            >
              {language === 'DE' ? 'Weiter' : 'Next'}
            </Button>
          </>
        )
      ) : (
        <>
          <Typography variant={'h5'}>
            {language === 'DE'
              ? 'Das System wird wahrscheinlich als KI-System gemäß dem EU AI Act angesehen.'
              : 'The system is likely to be considered as an AI system under the EU AI Act.'}
          </Typography>
          <Button
            sx={{ mt: '40px' }}
            variant={'contained'}
            color={'primary'}
            onClick={onNextValidationStage}
          >
            {language === 'DE'
              ? 'Risikoklassifizierung starten'
              : 'Start Risk Classification'}
          </Button>
        </>
      )}

      <ToastContainer />
    </Box>
  );
};

export default QuestionaireInterimResult;
