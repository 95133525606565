import { FC } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import PdfViewer from '../../components/PdfViewer';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfViewerScreenProps {
  isLoading: boolean;
  loadingPdf: boolean;
  pdfData?: string;
  currentPage: number;
  onPageLoad: (numPages: number) => void;
}

const PdfViewerScreen: FC<PdfViewerScreenProps> = ({
  isLoading,
  loadingPdf,
  pdfData,
  currentPage,
  onPageLoad,
}) => {
  if (isLoading || loadingPdf) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!pdfData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography color="error">Error loading PDF</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          borderRadius: '12px',
          bgcolor: '#fff',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PdfViewer
          pdfData={pdfData}
          currentPage={currentPage}
          onPageLoad={onPageLoad}
        />
      </Box>
    </Box>
  );
};

export default PdfViewerScreen;
