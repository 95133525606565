import { Badge, Box, Drawer, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

import AppsIcon from '@mui/icons-material/Apps';
import { useXarrow } from 'react-xarrows';
import { Link, Outlet, useLocation } from 'react-router-dom';
import {
  AssuredWorkloadRounded,
  GradingRounded,
  Label,
  AccountCircleRounded,
  Logout,
  Settings,
  Circle,
  ModelTraining,
} from '@mui/icons-material';
import { useProject } from '../contexts/project';
import {
  GetUserNotificationsQuery,
  Project,
} from '../__generated__/gql/graphql';
import { gql, useQuery, useSuspenseQuery } from '@apollo/client';
import { GET_PROJECTS } from '../project_view/ProjectView';
import { P, match } from 'ts-pattern';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import BugReportIcon from '@mui/icons-material/BugReport';
import * as Sentry from '@sentry/react';
import IMAGES from '../images/Images';
import { colorThemes } from '../theme';
import { useRecoilValue, useRecoilCallback, RecoilState } from 'recoil';
import { enabledFeaturesAtom } from '../EnabledFeatures';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const TRANSITION_DURATION_MS = 400;
const TRANSITION_DURATION_S = TRANSITION_DURATION_MS / 1000;

interface SidebarEntry {
  id: string;
  icon: ReactNode;
  name: string;
  link: string;
  subItems?: {
    id: string;
    name: string;
    link: string;
  }[];
}

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications {
    currentUser {
      __typename
      id
      notification {
        __typename
        id
        isRead
      }
    }
  }
`;

const entryStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  overflow: 'hidden',
  position: 'relative',
  padding: '10px',
  margin: '0 18px 0 10px',
  textDecoration: 'none',
  borderRadius: '4px',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colorThemes.DARK_BLUE_400,
  },
  transition: `background-color ${TRANSITION_DURATION_S / 2}s`,
};

const textEntryStyle = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  variant: 'subtitle1',
  fontSize: '14px',
  color: 'white',
};

const sidebarEntryGrouppingStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '6px',
  width: '100%',
};

const getShortProjectTitleString = (input: string) => {
  // Trim the input to remove leading and trailing spaces
  input = input.trim();

  // Split the string into words
  const words = input.split(/\s+/);

  // Check the number of words
  if (words.length === 1) {
    // If only one word, return the first two characters in uppercase
    // or the entire word if it's shorter than two characters
    return words[0].substring(0, 2).toUpperCase();
  } else {
    // If more than one word, return the first letter of the first two words in uppercase
    return (words[0][0] + words[1][0]).toUpperCase();
  }
};

function NavigationSidebar() {
  const hideArrows = () => {
    document
      .querySelectorAll('path[stroke="white"][stroke-width="4"]')
      .forEach(el => {
        if (el instanceof SVGPathElement) {
          el.style.visibility = 'hidden';
        }
      });
  };

  const showArrows = () => {
    document
      .querySelectorAll('path[stroke="white"][stroke-width="4"]')
      .forEach(el => {
        if (el instanceof SVGPathElement) {
          el.style.visibility = 'visible';
        }
      });
  };
  const query = useQuery(GET_PROJECTS);
  const { data: userNotifications } =
    useSuspenseQuery<GetUserNotificationsQuery>(GET_USER_NOTIFICATIONS);
  const notificationCount =
    userNotifications?.currentUser?.notification?.filter(
      notification => !notification?.isRead
    ).length ?? 0;

  const [isExpanded, setIsExpanded] = useState(false);
  const updateXarrow = useXarrow();

  const { pathname } = useLocation();

  const enabledFeatures = useRecoilValue(enabledFeaturesAtom);
  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');

  const showRolesPermissions = enabledFeatures.sidebarShowRolesAndPermissions;
  const showQuestionnaire = enabledFeatures.sidebarShowQuestionnaire;
  const showRiskManagement = enabledFeatures.sidebarShowRiskManagement;
  const showDevelop = enabledFeatures.sidebarShowDevelop;
  const showReports = enabledFeatures.sidebarShowReports;
  const showCompliance = enabledFeatures.sidebarShowCompliance;
  const showLiteracy = showBetaFeatures;
  const { removeUser, signoutRedirect, clearStaleState } = useAuth();

  const resetAllRecoilState = useRecoilCallback(({ reset, snapshot }) => () => {
    Array.from(snapshot.getNodes_UNSTABLE()).forEach(node => {
      const isResettable = (node: unknown): node is RecoilState<unknown> => {
        return typeof node === 'object' && node !== null && '__cTag' in node;
      };

      if (isResettable(node)) {
        reset(node);
      }
    });
  });

  const signOutHandler = () => {
    resetAllRecoilState();
    removeUser();
    signoutRedirect();
    clearStaleState();
    localStorage.clear();
    sessionStorage.clear();
  };

  const { project: selectedProject } = useProject();

  const width = isExpanded
    ? getComputedStyle(document.documentElement).getPropertyValue(
        '--expanded-sidebar-width'
      )
    : getComputedStyle(document.documentElement).getPropertyValue(
        '--collapsed-sidebar-width'
      );

  const organizationHomeEntries: SidebarEntry[] = [
    {
      id: '/projects',
      icon: <AppsIcon />,
      name: 'AI Registry',
      link: '/projects',
    },
  ];
  if (showRolesPermissions) {
    organizationHomeEntries.push({
      id: '/users',
      icon: <PeopleOutlineRoundedIcon />,
      name: 'Roles & Permissions',
      link: '/users',
    });
  }
  if (showQuestionnaire) {
    organizationHomeEntries.push({
      id: '/questionnaire',
      icon: <ArticleRoundedIcon />,
      name: 'Questionnaire',
      link: '/questionnaire',
    });
  }
  if (showLiteracy) {
    organizationHomeEntries.push({
      id: '/literacy',
      icon: <ModelTraining />,
      name: 'Literacy Training',
      link: '/literacy',
    });
  }

  const devLifecycleEntries: SidebarEntry[] = [];
  // devLifecycleEntries.push({
  //   id: '/planning',
  //   icon: <AppsIcon />,
  //   name: 'Planning',
  //   link: '/planning',
  // });

  if (showRiskManagement) {
    devLifecycleEntries.push({
      id: '/risk_management',
      icon: <VerifiedUserRoundedIcon />,
      name: 'Risk Management',
      link: `/risk_management/${selectedProject?.id ?? ''}`,
    });
  }

  if (showDevelop) {
    devLifecycleEntries.push({
      id: '/trail_graph',
      icon: <CodeRoundedIcon />,
      name: 'Develop',
      link: `/trail_graph/${selectedProject?.id ?? ''}`,
    });
  }
  const documentationEntries: SidebarEntry[] = [];
  if (showReports) {
    documentationEntries.push({
      id: '/documentations',
      icon: <GradingRounded />,
      name: 'Reports',
      link: '/documentations',
    });
  }
  if (showCompliance) {
    documentationEntries.push({
      id: '/governance_overview',
      icon: <AssuredWorkloadRounded />,
      name: 'Compliance',
      link: '/governance_overview',
      subItems:
        isExpanded && showBetaFeatures
          ? [
              {
                id: '/audits',
                name: 'Audits',
                link: '/audits',
              },
            ]
          : undefined,
    });
  }

  const bottomSidebarEntries: SidebarEntry[] = [
    {
      id: '/profile',
      icon: (
        <Badge
          badgeContent={notificationCount}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              fontSize: '9px',
              height: '16px',
              minWidth: '16px',
              padding: '0 4px',
              borderRadius: '60px',
              ...(notificationCount > 9 && {
                minWidth: '20px',
                minHeight: '20px',
              }),
            },
          }}
        >
          <AccountCircleRounded />
        </Badge>
      ),
      name: 'Profile',
      link: '/profile',
    },
    {
      id: '/organization_settings',
      icon: <Settings />,
      name: 'Organization Settings',
      link: '/organization_settings',
    },
    {
      id: 'report-bug',
      icon: <BugReportIcon />,
      name: 'Report a Bug',
      link: '#',
    },
  ];

  const handleBugButtonClick = () => {
    const feedback = Sentry.getFeedback();
    if (feedback) {
      feedback.createForm().then(form => {
        form.open();
        form.appendToDom();
      });
    }
  };

  const renderSidebarEntryGroup = (entries: SidebarEntry[]) =>
    entries.map(entry => (
      <>
        <Box
          key={entry.id}
          component={entry.id === 'report-bug' ? 'div' : Link}
          to={entry.link}
          onClick={
            entry.id === 'report-bug'
              ? async () => handleBugButtonClick()
              : undefined
          }
          sx={{
            ...entryStyle,
            width: 'calc(100% - 25px)',
            backgroundColor: pathname.startsWith(entry.id)
              ? colorThemes.DARK_BLUE_200
              : '',
          }}
        >
          {entry.icon}
          {isExpanded && (
            <Typography
              sx={{
                ...textEntryStyle,
                opacity: isExpanded ? 1 : 0,
                transition: `opacity ${TRANSITION_DURATION_S}s`,
              }}
            >
              {entry.name}
            </Typography>
          )}
        </Box>
        {entry.subItems?.map(subItem => (
          <Box
            key={subItem.id}
            component={Link}
            to={subItem.link}
            sx={{
              ...entryStyle,
              width: 'calc(100% - 25px)',
              paddingLeft: '60px',
              backgroundColor: pathname.startsWith(subItem.id)
                ? colorThemes.DARK_BLUE_200
                : '',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                position: 'absolute',
                left: '45px',
                pb: '5px',
              }}
            >
              <Circle sx={{ fontSize: '8px' }} />
            </Typography>
            <Typography
              sx={{
                ...textEntryStyle,
                opacity: isExpanded ? 1 : 0,
                transition: `opacity ${TRANSITION_DURATION_S}s`,
              }}
            >
              {subItem.name}
            </Typography>
          </Box>
        ))}
      </>
    ));

  const setExpansion = (expanded: boolean) => {
    const newWidth = expanded
      ? getComputedStyle(document.documentElement).getPropertyValue(
          '--expanded-sidebar-width'
        )
      : getComputedStyle(document.documentElement).getPropertyValue(
          '--collapsed-sidebar-width'
        );
    document.documentElement.style.setProperty('--sidebar-width', newWidth);
    setIsExpanded(expanded);
    if (expanded) {
      hideArrows();
    } else {
      setTimeout(updateXarrow, TRANSITION_DURATION_MS);
      setTimeout(showArrows, TRANSITION_DURATION_MS + 50);
    }
  };

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width,
          flexShrink: 0,
          transition: `width ${TRANSITION_DURATION_S}s`,
          '& .MuiDrawer-paper': {
            width,
            transition: `width ${TRANSITION_DURATION_S}s`,
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: colorThemes.DARK_BLUE_600,
            transition: `width ${TRANSITION_DURATION_S}s`,
            width,
            overflowX: 'hidden',
          },
        }}
        onMouseEnter={() => setExpansion(true)}
        onMouseLeave={() => setExpansion(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: '100%', height: '100%' }}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap="10%"
            style={{ height: '100%', marginTop: 10, marginBottom: 10 }}
          >
            <img
              src={IMAGES.trailLogo}
              alt="trail_logo"
              style={{ width: 25, marginLeft: 20, marginTop: 10 }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-between"
              style={{ width: '100%', height: '100%' }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '1.4rem' }}
              >
                {devLifecycleEntries.length > 0 && (
                  <Box sx={sidebarEntryGrouppingStyle}>
                    <Typography
                      sx={{
                        paddingX: '10px',
                        margin: '0 10px',
                        fontWeight: 'bold',
                        opacity: isExpanded ? 1 : 0,
                        ...textEntryStyle,
                        transition: `opacity ${TRANSITION_DURATION_S}s`,
                      }}
                    >
                      {isExpanded ? 'Dev Lifecycle' : 'Dev'}
                    </Typography>
                    {renderSidebarEntryGroup(devLifecycleEntries)}
                  </Box>
                )}
                <Box sx={sidebarEntryGrouppingStyle}>
                  <Typography
                    sx={{
                      paddingX: '10px',
                      margin: '0 10px',
                      fontWeight: 'bold',
                      opacity: isExpanded ? 1 : 0,
                      ...textEntryStyle,
                      transition: `opacity ${TRANSITION_DURATION_S}s`,
                    }}
                  >
                    {isExpanded ? 'Organization' : 'Org'}
                  </Typography>
                  {renderSidebarEntryGroup(organizationHomeEntries)}
                </Box>
                {documentationEntries.length > 0 && (
                  <Box sx={sidebarEntryGrouppingStyle}>
                    <Typography
                      sx={{
                        paddingX: '10px',
                        margin: '0 10px',
                        fontWeight: 'bold',
                        opacity: isExpanded ? 1 : 0,
                        width: 'calc(100% - 20px)',
                        ...textEntryStyle,
                        transition: `opacity ${TRANSITION_DURATION_S}s`,
                      }}
                    >
                      {isExpanded ? 'Documentations' : 'Docs'}
                    </Typography>
                    {renderSidebarEntryGroup(documentationEntries)}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  ...sidebarEntryGrouppingStyle,
                }}
              >
                <Typography
                  sx={{
                    padding: '10px',
                    margin: '0 10px',
                    fontWeight: 'bold',
                    ...textEntryStyle,
                    opacity: isExpanded ? 1 : 0,
                    transition: `opacity ${TRANSITION_DURATION_S}s`,
                  }}
                >
                  Projects
                </Typography>

                {match(query)
                  .with({ loading: true }, () => (
                    <Typography>Loading...</Typography>
                  ))
                  .with({ error: P.select('error') }, ({ error }) => (
                    <Typography>{error?.message}</Typography>
                  ))

                  .with(
                    {
                      data: P.select('data'),
                    },
                    data => {
                      return (
                        <>
                          {selectedProject && (
                            <ProjectItem
                              key={selectedProject.id}
                              project={selectedProject}
                              isSelected={true}
                              isExpanded={isExpanded}
                            />
                          )}

                          {data?.data?.allProjects
                            ?.filter(p => p?.id !== selectedProject?.id)
                            .slice(0, selectedProject ? 2 : 3)
                            .map(project => {
                              return project ? (
                                <ProjectItem
                                  key={project.id}
                                  project={project}
                                  isSelected={
                                    project.id === selectedProject?.id
                                  }
                                  isExpanded={isExpanded}
                                />
                              ) : null;
                            })}
                        </>
                      );
                    }
                  )
                  .exhaustive()}

                {renderSidebarEntryGroup(bottomSidebarEntries)}

                <Box sx={entryStyle} onClick={signOutHandler}>
                  <Logout />
                  {isExpanded && (
                    <Typography sx={textEntryStyle}>Sign Out</Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Outlet />
    </>
  );
}

const ProjectItem = ({
  project,
  isSelected,
  isExpanded,
}: {
  project: Project;
  isSelected?: boolean;
  isExpanded: boolean;
}) => {
  return (
    <Box
      key={project.id}
      component={Link}
      to={`/projects?projectDetailsFor=${project.id}`}
      sx={{
        ...entryStyle,
        pr: '7px !important',
        width: 'calc(100% - 18px)',
        backgroundColor: isSelected ? colorThemes.DARK_BLUE_200 : '',
      }}
    >
      {isExpanded ? (
        <>
          <Label />
          <Typography sx={textEntryStyle}>{project.title}</Typography>
        </>
      ) : (
        <Typography sx={textEntryStyle}>
          {getShortProjectTitleString(project.title)}
        </Typography>
      )}
    </Box>
  );
};

export default withAuthenticationRequired(NavigationSidebar, {
  OnRedirecting: () => <div></div>,
});
