import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Button, Typography } from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetLiteracyTrainingsforUserQuery,
  LiteracyTraining,
} from '../__generated__/gql/graphql';
import CardGrid from '../components/CardGrid';
import TrainingDetailCard from './TrainingDetailCard';
import { calculateDueDate } from '../utils/dateUtils';
import TrainingModal from './TrainingModal';
import { Link } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { colorThemes } from '../theme';
import { DateTime } from 'luxon';

const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainingsforUser(
    $targetQueryMutation: String!
    $action: String!
  ) {
    checkPermission(
      targetQueryMutation: $targetQueryMutation
      action: $action
    ) {
      hasPermission
    }
    allLiteracyTrainings {
      id
      title
      responsiblePerson {
        id
        name
        email
      }
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      dueDate
      lastModified
      created
      currentUserCompletion {
        id
        completionDate
      }
    }
  }
`;

const TWO_WEEKS = { weeks: 2 };

interface TrainingWithDueDate extends LiteracyTraining {
  nextDueDate: string | null;
}

const TrainingOverviewPage: FC = () => {
  const { data: allTrainingData } =
    useSuspenseQuery<GetLiteracyTrainingsforUserQuery>(GET_LITERACY_TRAININGS, {
      variables: {
        targetQueryMutation: 'createTraining',
        action: 'mutation',
      },
    });
  const filteredTraining =
    (allTrainingData?.allLiteracyTrainings as LiteracyTraining[]) || [];
  const [selectedTraining, setSelectedTraining] =
    useState<LiteracyTraining | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTraining(training);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTraining(null);
  };

  const organizeTrainings = (trainings: LiteracyTraining[]) => {
    return trainings.reduce(
      (acc, training) => {
        const nextDueDate = calculateDueDate(
          training.dueDate,
          training.interval,
          training.currentUserCompletion?.completionDate
        );

        const isDone = Boolean(
          training.currentUserCompletion &&
            (!nextDueDate ||
              DateTime.fromISO(nextDueDate.toString()) >
                DateTime.now().plus(TWO_WEEKS))
        );

        const trainingWithDueDate: TrainingWithDueDate = {
          ...training,
          nextDueDate: nextDueDate ? nextDueDate.toString() : null,
        };

        if (isDone) {
          acc.doneTrainings.push(trainingWithDueDate);
        } else {
          acc.pendingTrainings.push(trainingWithDueDate);
        }
        return acc;
      },
      {
        pendingTrainings: [] as TrainingWithDueDate[],
        doneTrainings: [] as TrainingWithDueDate[],
      }
    );
  };

  const sortByNextDueDate = (trainings: TrainingWithDueDate[]) => {
    return [...trainings].sort((a, b) => {
      if (!a.nextDueDate) return 1;
      if (!b.nextDueDate) return -1;
      return (
        DateTime.fromISO(a.nextDueDate).toMillis() -
        DateTime.fromISO(b.nextDueDate).toMillis()
      );
    });
  };

  const { pendingTrainings, doneTrainings } =
    organizeTrainings(filteredTraining);
  const sortedPendingTrainings = sortByNextDueDate(pendingTrainings);
  const sortedDoneTrainings = sortByNextDueDate(doneTrainings);

  const hasAdminPermission = allTrainingData?.checkPermission?.hasPermission;

  const renderTrainingCard = (
    training: TrainingWithDueDate,
    isDone: boolean
  ) => (
    <TrainingDetailCard
      key={training.id}
      id={training.id}
      onClick={isDone ? undefined : () => handleTrainingSelect(training)}
      title={training.title}
      isSelected={selectedTraining?.id === training.id}
      required={training?.isRequired ?? false}
      done={isDone}
      nextDue={
        training.nextDueDate ? DateTime.fromISO(training.nextDueDate) : null
      }
      description={training.description ?? ''}
    />
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width={'100%'}
        alignItems="center"
      >
        <Typography variant="h4" marginBottom={3}>
          Training Overview
        </Typography>
        {hasAdminPermission && (
          <Button
            component={Link}
            to="/literacy-admin"
            variant="contained"
            color="primary"
            sx={{
              background: colorThemes.YELLOW_400,
              padding: '8px 16px',
              borderRadius: '8px',
              textTransform: 'none',
              '&:hover': {
                background: colorThemes.YELLOW_200,
              },
            }}
            startIcon={<AdminPanelSettingsIcon sx={{ color: 'black' }} />}
          >
            Admin View
          </Button>
        )}
      </Box>

      {sortedPendingTrainings.length === 0 &&
      sortedDoneTrainings.length === 0 ? (
        <Typography variant="h5">No Trainings available</Typography>
      ) : (
        <>
          {sortedPendingTrainings.length > 0 && (
            <CardGrid
              items={sortedPendingTrainings}
              title={`Pending Trainings (${sortedPendingTrainings.length})`}
              renderCard={training => renderTrainingCard(training, false)}
            />
          )}

          {sortedDoneTrainings.length > 0 && (
            <Box sx={{ marginTop: 4 }}>
              <CardGrid
                items={sortedDoneTrainings}
                title={`Completed Trainings (${sortedDoneTrainings.length})`}
                renderCard={training => renderTrainingCard(training, true)}
              />
            </Box>
          )}
        </>
      )}

      <TrainingModal
        open={isModalOpen}
        onClose={handleModalClose}
        training={selectedTraining}
      />
      <ToastContainer />
    </>
  );
};

export default TrainingOverviewPage;
