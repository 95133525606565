import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  FormHelperText,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { Interval, User } from '../../../__generated__/gql/graphql';
import UserTag from '../../../components/UserTag';

interface BasicInfoFormData {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: Interval;
  isRequired: boolean;
  dueDate?: DateTime;
}

interface BasicInfoStepProps {
  allUsers: User[];
}

const BasicInfoStep: FC<BasicInfoStepProps> = ({ allUsers }) => {
  const { control } = useFormContext<BasicInfoFormData>();

  return (
    <Paper elevation={0} sx={{ p: 3, backgroundColor: 'transparent' }}>
      <Grid container spacing={3}>
        {/* Title Field */}
        <Grid item xs={12}>
          <Controller
            name="title"
            control={control}
            rules={{
              required: 'Title is required',
              minLength: {
                value: 3,
                message: 'Title must be at least 3 characters long',
              },
              maxLength: {
                value: 100,
                message: 'Title must be less than 100 characters',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Title"
                fullWidth
                required
                error={!!error}
                helperText={error?.message}
                placeholder="Enter training title"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Description Field */}
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            rules={{
              required: 'Description is required',
              minLength: {
                value: 10,
                message: 'Description must be at least 10 characters long',
              },
              maxLength: {
                value: 1000,
                message: 'Description must be less than 1000 characters',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                multiline
                rows={4}
                required
                error={!!error}
                helperText={error?.message}
                placeholder="Enter training description"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                  },
                }}
              />
            )}
          />
        </Grid>

        {/* Responsible Person Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="responsiblePersonId"
            control={control}
            rules={{ required: 'Responsible person is required' }}
            render={({ field, fieldState: { error } }) => (
              <FormControl
                fullWidth
                error={!!error}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                  },
                }}
              >
                <InputLabel>Responsible Person</InputLabel>
                <Select
                  {...field}
                  label="Responsible Person"
                  placeholder="Select responsible person"
                >
                  {allUsers.map((user, userIndex) => (
                    <MenuItem
                      key={userIndex}
                      value={user.id}
                      sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
                    >
                      <UserTag
                        key={user.id}
                        user={user}
                        variant={'text'}
                        size={'medium'}
                      />
                    </MenuItem>
                  ))}
                </Select>
                {error && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
                <FormHelperText>
                  Select the person responsible for managing this training
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Due Date Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="dueDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePicker
                label="Due Date"
                value={value}
                onChange={newValue => {
                  onChange(newValue);
                }}
                slotProps={{
                  textField: {
                    error: !!error,
                    helperText:
                      error?.message ||
                      'When should this training be completed by?',
                    fullWidth: true,
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'background.paper',
                      },
                    },
                  },
                }}
                minDate={DateTime.now()}
              />
            )}
          />
        </Grid>

        {/* Interval Field */}
        <Grid item xs={12} md={6}>
          <Controller
            name="interval"
            control={control}
            defaultValue={Interval.Yearly}
            render={({ field }) => (
              <FormControl
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: 'background.paper',
                  },
                }}
              >
                <InputLabel>Interval</InputLabel>
                <Select {...field} label="Interval">
                  {Object.values(Interval).map(intervalValue => (
                    <MenuItem
                      key={intervalValue}
                      value={intervalValue}
                      sx={{ '&:hover': { backgroundColor: 'action.hover' } }}
                    >
                      {intervalValue.charAt(0).toUpperCase() +
                        intervalValue.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  How often should this training be repeated?
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>

        {/* Required Training Switch */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={0}
            sx={{
              p: 2,
              backgroundColor: 'background.paper',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Controller
              name="isRequired"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange, ...field } }) => (
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={value}
                        onChange={e => onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body1" component="span">
                        Required Training
                      </Typography>
                    }
                  />
                  <FormHelperText>
                    Toggle if this training is mandatory for all users
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BasicInfoStep;
