import { DateTime, DurationLike } from 'luxon';
import { Interval } from '../__generated__/gql/graphql';

export interface IntervalData {
  start: DateTime;
  end: DateTime;
  label: string;
  status: 'upcoming' | 'current' | 'past';
}

const INTERVAL_DURATIONS: Record<Interval, DurationLike> = {
  QUARTERLY: { months: 3 },
  YEARLY: { years: 1 },
  BIENNIAL: { years: 2 },
  ONCE: { days: 0 },
};

const FUTURE_INTERVALS = 2;

function getIntervalLabel(start: DateTime, interval: Interval): string {
  if (interval === 'ONCE') {
    return `Once (${start.toFormat('yyyy-MM-dd')})`;
  }

  const year = start.year;
  const month = start.monthLong;
  const endDate = start.plus(INTERVAL_DURATIONS[interval]).minus({ days: 1 });

  const formatYearRange = () =>
    endDate.year === year
      ? `${month} - ${endDate.monthLong} ${year}`
      : `${month} ${year} - ${endDate.monthLong} ${endDate.year}`;

  switch (interval) {
    case 'QUARTERLY':
      return `${month} - ${endDate.monthLong} ${year}`;
    case 'YEARLY':
      return formatYearRange();
    case 'BIENNIAL':
      return `${month} ${year} - ${endDate.monthLong} ${endDate.year}`;
    default:
      return `${year}`;
  }
}

function createIntervalData(
  start: DateTime,
  interval: Interval,
  status: IntervalData['status']
): IntervalData {
  const end = start
    .plus(INTERVAL_DURATIONS[interval])
    .minus({ days: 1 })
    .endOf('day');

  return {
    start,
    end,
    label: getIntervalLabel(start, interval),
    status,
  };
}

/**
 * Generates a list of intervals based on the initial due date and interval type
 */
export function generateIntervals(
  initialDueDate: string,
  interval: Interval,
  includeUpcoming: boolean = true
): IntervalData[] {
  const dueDate = DateTime.fromISO(initialDueDate).startOf('day');
  const now = DateTime.now().startOf('day');

  if (interval === 'ONCE') {
    const end = dueDate;
    const status: IntervalData['status'] =
      now > end ? 'past' : now < end ? 'upcoming' : 'current';

    return [
      {
        start: dueDate,
        end,
        label: `Once (due by ${end.toFormat('yyyy-MM-dd')})`,
        status,
      },
    ];
  }

  const intervals: IntervalData[] = [];
  let currentStart = findFirstIntervalStart(dueDate, now, interval);

  // Add past and current intervals
  while (currentStart <= now) {
    const end = currentStart
      .plus(INTERVAL_DURATIONS[interval])
      .minus({ days: 1 })
      .endOf('day');

    intervals.push(
      createIntervalData(currentStart, interval, end < now ? 'past' : 'current')
    );
    currentStart = end.plus({ days: 1 }).startOf('day');
  }

  // Add upcoming intervals
  if (includeUpcoming) {
    for (let i = 0; i < FUTURE_INTERVALS; i++) {
      intervals.push(createIntervalData(currentStart, interval, 'upcoming'));
      currentStart = currentStart
        .plus(INTERVAL_DURATIONS[interval])
        .startOf('day');
    }
  }

  return intervals;
}

function findFirstIntervalStart(
  dueDate: DateTime,
  now: DateTime,
  interval: Interval
): DateTime {
  let currentStart = dueDate;
  while (currentStart > now) {
    currentStart = currentStart.minus(INTERVAL_DURATIONS[interval]);
  }
  return currentStart;
}
