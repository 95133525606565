import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Chip, Stack } from '@mui/material';
import { colorThemes } from '../theme';
import { FC, ReactNode } from 'react';
import { Interval, User } from '../__generated__/gql/graphql';
import UserTag from '../components/UserTag';

type TrainingAdminDetailCardProps = {
  id: string;
  title: string;
  onClick: () => void;
  isSelected?: boolean;
  user: User;
  interval: Interval;
  updatedAt: Date | string;
  required: boolean;
};

const InfoItem = ({
  title,
  content,
}: {
  title: string;
  content: ReactNode;
}) => (
  <Box mt={2}>
    <Typography variant="subtitle2" color="text.secondary" noWrap>
      {title}:
    </Typography>
    {content}
  </Box>
);

const TrainingAdminDetailCard: FC<TrainingAdminDetailCardProps> = ({
  title,
  onClick,
  isSelected,
  user,
  interval,
  updatedAt,
  required,
}) => {
  const formatDate = (date: Date | string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };
  return (
    <Card
      sx={{
        width: 400,
        height: 200,
        transition: 'all 0.2s ease-in-out',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
          transform: 'translateY(-2px)',
          boxShadow: 3,
        },
        ...(isSelected && {
          border: `2px solid ${colorThemes.YELLOW_600}`,
          boxShadow: `0 0 0 1px ${colorThemes.YELLOW_600}`,
        }),
      }}
      onClick={event => {
        event.stopPropagation();
        onClick();
      }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          '&:last-child': { pb: 3 },
        }}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={0.5}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontWeight: 500 }}
            >
              Updated: {formatDate(updatedAt)}
            </Typography>
            <Chip
              label={required ? 'Required' : 'Optional'}
              size="small"
              sx={{
                fontWeight: 600,
                background: required
                  ? colorThemes.ACTION_BLUE
                  : colorThemes.DARK_BLUE_200,
                px: 1,
              }}
            />
          </Stack>
          <Typography
            noWrap
            textOverflow="ellipsis"
            color="text.primary"
            variant="h6"
            sx={{ fontWeight: 600 }}
          >
            {title}
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <InfoItem
            title="Interval"
            content={
              <Chip
                variant="outlined"
                size="small"
                label={interval ?? 'Not Defined'}
              />
            }
          />
          <InfoItem
            title="Responsible Person"
            content={
              <UserTag
                key={user.id}
                user={user}
                variant={'text'}
                size={'medium'}
                noWrap={true}
              />
            }
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrainingAdminDetailCard;
