import { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, CircularProgress } from '@mui/material';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

interface PdfViewerProps {
  pdfData: string;
  currentPage: number;
  onPageLoad: (totalPages: number) => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({
  pdfData,
  currentPage,
  onPageLoad,
}) => {
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const link = (event.target as HTMLElement).closest(
      '.react-pdf__Page__annotations a'
    ) as HTMLAnchorElement;

    if (link) {
      event.preventDefault();
      window.open(link.href, '_blank', 'noopener,noreferrer');
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    onPageLoad(numPages);
  };

  const handlePageLoadSuccess = ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => {
    if (containerRef.current && scale === null) {
      const container = containerRef.current;
      const containerWidth = container.clientWidth - 40;
      const containerHeight = container.clientHeight - 40;

      const widthScale = containerWidth / width;
      const heightScale = containerHeight / height;

      const newScale = Math.min(widthScale, heightScale);
      setScale(newScale);
    }
    setLoading(false);
  };

  return (
    <Box
      ref={containerRef}
      onClick={handleContainerClick}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'auto',
        bgcolor: '#f5f5f5',
        '& .react-pdf__Page': {
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Document
        file={`data:application/pdf;base64,${pdfData}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => console.error('Error loading PDF:', error)}
        loading={<CircularProgress />}
      >
        <Page
          pageNumber={currentPage}
          renderTextLayer={true}
          renderAnnotationLayer={true}
          loading={<CircularProgress />}
          onLoadSuccess={handlePageLoadSuccess}
          scale={scale ?? 1}
        />
      </Document>
    </Box>
  );
};

export default PdfViewer;
