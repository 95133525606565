import React, { useState, useEffect } from 'react';
import { useMutation, gql, useApolloClient, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  Tooltip,
  Link,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Clear,
  Description,
  Download,
  Link as LinkIcon,
  Search,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import {
  AvailableGovernanceEvidencesQuery,
  AvailableGovernanceEvidencesQueryVariables,
  GovernanceEvidence,
} from '../../../__generated__/gql/graphql';

const CREATE_EVIDENCE = gql`
  mutation CreateEvidence(
    $title: String!
    $description: String
    $fileName: String
    $fileContent: String
    $externalUrl: String
  ) {
    createEvidence(
      title: $title
      description: $description
      fileName: $fileName
      fileContent: $fileContent
      externalUrl: $externalUrl
    ) {
      evidence {
        id
        title
        description
        externalUrl
        file {
          url
        }
      }
    }
  }
`;

const AVAILABLE_GOVERNANCE_EVIDENCES = gql`
  query AvailableGovernanceEvidences {
    availableGovernanceEvidences {
      id
      title
      description
      file {
        url
      }
    }
  }
`;

const ADD_EVIDENCE_TO_REQUIREMENT = gql`
  mutation AddEvidenceToRequirement($requirementId: ID!, $evidenceId: ID!) {
    addEvidenceToRequirement(
      requirementId: $requirementId
      evidenceId: $evidenceId
    ) {
      requirement {
        id
        evidence {
          id
          title
          description
        }
      }
    }
  }
`;

const UPDATE_EVIDENCE = gql`
  mutation UpdateEvidence(
    $id: ID!
    $title: String
    $description: String
    $externalUrl: String
    $fileName: String
    $fileContent: String
    $deleteFile: Boolean
  ) {
    updateEvidence(
      id: $id
      title: $title
      description: $description
      externalUrl: $externalUrl
      fileName: $fileName
      fileContent: $fileContent
      deleteFile: $deleteFile
    ) {
      evidence {
        id
        title
        description
        externalUrl
        file {
          url
        }
      }
    }
  }
`;

type EvidenceModalProps = {
  open: boolean;
  onClose: () => void;
  requirementStatusId: string;
  editEvidence?: GovernanceEvidence | null;
};

function EvidenceModal({
  open,
  onClose,
  requirementStatusId,
  editEvidence,
}: EvidenceModalProps) {
  const client = useApolloClient();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEvidence, setSelectedEvidence] = useState<string | null>(null);
  const [externalUrl, setExternalUrl] = useState('');

  const [activeTab, setActiveTab] = useState(editEvidence ? 0 : 0);

  const [createEvidence] = useMutation(CREATE_EVIDENCE, {
    update(cache, { data: { createEvidence } }) {
      const newEvidence = createEvidence.evidence;

      cache.modify({
        id: cache.identify({
          __typename: 'GovernanceRequirementStatus',
          id: requirementStatusId,
        }),
        fields: {
          userStatus(existingUserStatus = {}) {
            const updatedEvidence = existingUserStatus.evidence
              ? [...existingUserStatus.evidence, newEvidence]
              : [newEvidence];

            return {
              ...existingUserStatus,
              evidence: updatedEvidence,
            };
          },
        },
      });

      cache.updateQuery<AvailableGovernanceEvidencesQuery>(
        {
          query: AVAILABLE_GOVERNANCE_EVIDENCES,
          variables: {},
        },
        data => ({
          ...data,
          availableGovernanceEvidences: [
            ...(data?.availableGovernanceEvidences || []),
            newEvidence,
          ],
        })
      );
    },
  });

  const [addEvidenceToRequirement] = useMutation(ADD_EVIDENCE_TO_REQUIREMENT);

  const [updateEvidence] = useMutation(UPDATE_EVIDENCE);

  const [isLoading, setIsLoading] = React.useState(false);

  const [deleteFile, setDeleteFile] = useState(false);

  useEffect(() => {
    if (editEvidence) {
      setTitle(editEvidence.title || '');
      setDescription(editEvidence.description || '');
      setExternalUrl(editEvidence.externalUrl || '');
      setFile(null);
      setDeleteFile(false);
    } else {
      setTitle('');
      setDescription('');
      setExternalUrl('');
      setFile(null);
    }
  }, [editEvidence, open]);

  const handleEvidenceSubmit = async () => {
    setIsLoading(true);
    try {
      if (editEvidence) {
        let fileContent = null;
        let fileName = null;

        if (file) {
          const reader = new FileReader();
          fileContent = await new Promise(resolve => {
            reader.onload = event =>
              resolve(btoa(event.target?.result as string));
            reader.readAsBinaryString(file);
          });
          fileName = file.name;
        }

        const result = await updateEvidence({
          variables: {
            id: editEvidence.id,
            title,
            description,
            externalUrl,
            fileName,
            fileContent,
            deleteFile: deleteFile && !file,
          },
        });

        client.cache.modify({
          id: client.cache.identify({
            __typename: 'GovernanceRequirementStatus',
            id: requirementStatusId,
          }),
          fields: {
            evidence(existingEvidence = []) {
              return existingEvidence.map((ev: GovernanceEvidence) =>
                ev.id === editEvidence.id
                  ? result.data.updateEvidence.evidence
                  : ev
              );
            },
          },
        });
      } else {
        let fileContent = null;
        let fileName = null;

        if (file) {
          const reader = new FileReader();
          fileContent = await new Promise(resolve => {
            reader.onload = event =>
              resolve(btoa(event.target?.result as string));
            reader.readAsBinaryString(file);
          });
          fileName = file.name;
        }

        const result = await createEvidence({
          variables: {
            title,
            description,
            fileName,
            fileContent,
            externalUrl,
          },
        });

        const evidenceId = result.data.createEvidence.evidence.id;

        await addEvidenceToRequirement({
          variables: {
            requirementId: requirementStatusId,
            evidenceId,
          },
        });
      }

      setTitle('');
      setDescription('');
      setFile(null);
      setExternalUrl('');
      onClose();
      toast.success(
        editEvidence
          ? 'Evidence updated successfully'
          : 'Evidence added successfully'
      );
    } catch (error) {
      toast.error(
        editEvidence ? 'Failed to update evidence' : 'Failed to add evidence'
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLinkEvidence = async () => {
    if (selectedEvidence) {
      setIsLoading(true);
      try {
        const result = await addEvidenceToRequirement({
          variables: {
            requirementId: requirementStatusId,
            evidenceId: selectedEvidence,
          },
        });

        client.cache.modify({
          id: client.cache.identify({
            __typename: 'GovernanceRequirementStatus',
            id: requirementStatusId,
          }),
          fields: {
            userStatus: (existingUserStatus = {}) => ({
              ...existingUserStatus,
              evidence: [
                ...(existingUserStatus.evidence || []),
                result.data.addEvidenceToRequirement.requirement.evidence[0],
              ],
            }),
          },
        });

        toast.success('Evidence linked successfully');
        onClose();
      } catch (error) {
        toast.error('Failed to link evidence');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    setIsLoading(true);
    try {
      let result: unknown;
      if (activeTab === 0) {
        result = await handleEvidenceSubmit();
      } else if (activeTab === 1 && selectedEvidence) {
        result = await handleLinkEvidence();
      }

      if (result) {
        onClose();
        toast.success('Evidence added successfully');
      }
    } catch (_error) {
      toast.error('Failed to add evidence');
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { data: availableEvidences, loading: loadingEvidences } = useQuery<
    AvailableGovernanceEvidencesQuery,
    AvailableGovernanceEvidencesQueryVariables
  >(AVAILABLE_GOVERNANCE_EVIDENCES);

  const filteredEvidences =
    availableEvidences?.availableGovernanceEvidences?.filter(
      evidence =>
        evidence?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        evidence?.description?.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: 700,
          width: '100%',
          maxWidth: 800,
        },
      }}
    >
      <DialogTitle>
        {editEvidence ? 'Edit Evidence' : 'Add Evidence'}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {editEvidence
            ? 'Update the evidence details below.'
            : 'Add new evidence or link existing evidence to this requirement.'}
        </Typography>
        <form onSubmit={handleSubmit}>
          {!editEvidence && (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="evidence tabs"
              sx={{ mb: 2 }}
            >
              <Tab icon={<Description />} label="New Evidence" />
              <Tab icon={<LinkIcon />} label="Link Evidence" />
            </Tabs>
          )}
          {(editEvidence || activeTab === 0) && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={4}
              />
              <TextField
                fullWidth
                label="External URL"
                value={externalUrl}
                onChange={e => setExternalUrl(e.target.value)}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {editEvidence?.file && !deleteFile && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      bgcolor: 'background.paper',
                      p: 1,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider',
                    }}
                  >
                    <Typography variant="body2">Current file:</Typography>
                    <Link
                      href={editEvidence.file.url ?? ''}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Download sx={{ mr: 0.5 }} fontSize="small" />
                      Download
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Delete file">
                      <IconButton
                        size="small"
                        onClick={() => setDeleteFile(true)}
                        sx={{ color: 'error.main' }}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                <Box
                  {...getRootProps()}
                  sx={{
                    border: '2px dashed #cccccc',
                    borderRadius: '4px',
                    p: 4,
                    textAlign: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                    ...(file && {
                      borderColor: 'primary.main',
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    }),
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <Typography>Drop the file here ...</Typography>
                  ) : (
                    <Typography>
                      {file
                        ? `New file selected: ${file.name}`
                        : editEvidence?.file && !deleteFile
                        ? 'Drag and drop a new file to replace the current one'
                        : `Drag and drop a file here, or click to select a file ${
                            editEvidence ? '' : '(optional)'
                          }`}
                    </Typography>
                  )}
                </Box>

                {(deleteFile || file) && editEvidence?.file && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {deleteFile && !file && (
                      <Button
                        size="small"
                        onClick={() => setDeleteFile(false)}
                        startIcon={<Clear />}
                        color="primary"
                      >
                        Keep current file
                      </Button>
                    )}
                    {file && (
                      <Button
                        size="small"
                        onClick={() => setFile(null)}
                        startIcon={<Clear />}
                        color="primary"
                      >
                        Cancel new file
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {!editEvidence && activeTab === 1 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                label="Search Existing Evidence"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: <Search />,
                }}
              />
              <List sx={{ maxHeight: 300, overflow: 'auto' }}>
                {loadingEvidences ? (
                  <Typography>Loading evidences...</Typography>
                ) : (
                  filteredEvidences.map(evidence => (
                    <ListItem
                      key={evidence?.id}
                      disablePadding
                      secondaryAction={
                        <IconButton edge="end" aria-label="preview">
                          {/* Add preview functionality here */}
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        selected={selectedEvidence === evidence?.id}
                        onClick={() => setSelectedEvidence(evidence?.id ?? '')}
                      >
                        <ListItemText
                          primary={evidence?.title}
                          secondary={evidence?.description}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))
                )}
              </List>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={editEvidence ? handleEvidenceSubmit : handleSubmit}
          loading={isLoading}
          variant="contained"
        >
          {editEvidence
            ? 'Update Evidence'
            : activeTab === 0
            ? 'Add Evidence'
            : 'Link Evidence'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EvidenceModal;
