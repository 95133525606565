import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { useState } from 'react';
import { Reorder } from 'framer-motion';
import { RemoveCircle } from '@mui/icons-material';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import {
  DocumentationEntry,
  documentComponentsAtom,
  documentTemplateAtom,
} from '../../DocumentationPageStates';
import { colorThemes } from '../../../../theme';
import {
  CUSTOM_COMPONENT_TYPES,
  DocumentComponentNameMap,
} from './ComponentNameMapping';
import DocumentComponentType, {
  ComponentCategory,
  ComponentTypeCategories,
} from '../../../../__generated__/document_component_type';
import {
  DocumentationScopeEnum,
  ComponentCategoryEnum,
  DocumentComponentTypeEnum,
} from '../../../../__generated__/gql/graphql';

export default function ComponentsTab() {
  const currentTemplate = useRecoilValue(documentTemplateAtom);
  const [currentComponents, setDocumentComponents] = useRecoilState(
    documentComponentsAtom
  );

  const filterComponentsByScope = (components: DocumentationEntry[]) => {
    const allowedCategories = [ComponentCategoryEnum.General];

    switch (currentTemplate?.documentationScope) {
      case DocumentationScopeEnum.Organization:
        allowedCategories.push(ComponentCategoryEnum.Organization);
        break;
      case DocumentationScopeEnum.Project:
        allowedCategories.push(ComponentCategoryEnum.Project);
        break;
      case DocumentationScopeEnum.Experiment:
        allowedCategories.push(ComponentCategoryEnum.Technical);
        break;
    }

    return components.filter(
      component =>
        component.category && allowedCategories.includes(component.category)
    );
  };

  const predefinedComponents: DocumentationEntry[] = Object.values(
    DocumentComponentTypeEnum
  )
    .filter(enumValue => !currentComponents.find(c => c.type === enumValue))
    .map(enumValue => ({
      id: uuidv4(),
      name: enumValue,
      query: '',
      type: enumValue,
      dataSource: undefined,
      category: mapComponentCategory(
        ComponentTypeCategories[enumValue as unknown as DocumentComponentType]
      ),
    }));

  function mapComponentCategory(
    category: ComponentCategory
  ): ComponentCategoryEnum {
    switch (category) {
      case ComponentCategory.Technical:
        return ComponentCategoryEnum.Technical;
      case ComponentCategory.Organization:
        return ComponentCategoryEnum.Organization;
      case ComponentCategory.Project:
        return ComponentCategoryEnum.Project;
      case ComponentCategory.General:
      default:
        return ComponentCategoryEnum.General;
    }
  }
  const filteredComponents = filterComponentsByScope(
    predefinedComponents || []
  );

  const [searchTerm, setSearchTerm] = useState<string>('');
  return (
    <Box sx={{ width: 'full' }}>
      <Grid container spacing={10}>
        <Grid item xs={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Components</Typography>
            <TextField
              id="search"
              sx={{ width: 200, height: 40 }}
              size="small"
              onChange={e => setSearchTerm(e.target.value)}
              placeholder="Search component"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            {filteredComponents
              .filter(component =>
                component?.name
                  ?.toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map(component => (
                <ComponentCard key={component.id} component={component} />
              ))}
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <Typography variant="h6">Preview</Typography>
          </Box>
          <Box
            sx={{
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            {currentComponents.length !== 0 ? (
              <Reorder.Group
                axis="y"
                values={currentComponents}
                onReorder={setDocumentComponents}
                as="div"
              >
                {currentComponents.map(component => (
                  <Reorder.Item key={component.name} value={component} as="div">
                    <TemplateComponentCard component={component} />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            ) : (
              <NoComponentsMessage />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export const NoComponentsMessage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Typography variant="h5" sx={{ color: colorThemes.GREY_500 }}>
        No components selected
      </Typography>
    </Box>
  );
};

function ComponentCard({ component }: { component: DocumentationEntry }) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const addComponent = () => {
    setDocumentComponents(components => [...components, component]);
  };
  const displayName = DocumentComponentNameMap[component.type];
  return (
    <Box
      sx={{
        padding: 1,
        width: 'full',
        height: 50,
        transition: 'all .2s',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_600,
        },
        marginBottom: 1,
        borderRadius: 1,
      }}
      onClick={addComponent}
    >
      <Typography variant="subtitle1">{displayName}</Typography>
    </Box>
  );
}

function TemplateComponentCard({
  component,
}: {
  component: DocumentationEntry;
}) {
  const setDocumentComponents = useSetRecoilState(documentComponentsAtom);
  const removeComponent = () => {
    setDocumentComponents(components =>
      components.filter(c =>
        c.type === DocumentComponentTypeEnum.CustomText
          ? c.id !== component.id
          : c.name !== component.name
      )
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const displayName =
    component.type === DocumentComponentTypeEnum.CustomText
      ? component.name
      : DocumentComponentNameMap[component.type];

  const handleClose = () => {
    setDocumentComponents(components =>
      components.map(c =>
        c.id === component.id ? { ...c, name: localName, query: localQuery } : c
      )
    );
    setOpen(false);
  };
  const handleComponentNameChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalName(e.target.value);
  };

  const handleComponentQueryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalQuery(e.target.value);
  };

  const [localName, setLocalName] = useState(component.name || '');
  const [localQuery, setLocalQuery] = useState(component.query || '');
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: 'absolute',
            right: '50%',
            minWidth: '30vw',
            maxWidth: '50vw',
            top: '50%',
            transform: 'translate(50%, -50%)',
          }}
        >
          <CardContent>
            <input
              type="text"
              value={localName}
              onChange={handleComponentNameChange}
              placeholder={'Custom Text'}
              style={{
                border: 'none',
                outline: 'none',
                resize: 'none',
                color: 'white',
                width: '20vw',
                fontWeight: 'semibold',
                backgroundColor: colorThemes.DARK_BLUE_600,
                fontSize: '1.2rem',
              }}
            />
            <Typography
              variant="subtitle1"
              sx={{ color: colorThemes.GREY_200, marginTop: '1rem' }}
            >
              Query
            </Typography>
            <input
              type="text"
              value={localQuery}
              onChange={handleComponentQueryChange}
              placeholder={'Custom Query'}
              style={{
                border: 'none',
                borderRadius: '0.2rem',
                outline: 'none',
                resize: 'none',
                color: 'white',
                width: '25vw',
                fontWeight: 'medium',
                backgroundColor: colorThemes.DARK_BLUE_600,
                fontSize: '1rem',
              }}
            />
          </CardContent>
        </Card>
      </Modal>
      <Box
        sx={{
          padding: 1,
          width: 'full',
          height: 50,
          transition: 'all .2s',
          border: '2px solid #334E68',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: colorThemes.DARK_BLUE_600,
          },
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 1,
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <Typography variant="subtitle1">{displayName}</Typography>
          {component.type === DocumentComponentTypeEnum.CustomText && (
            <Typography
              variant="subtitle2"
              sx={{ color: colorThemes.GREY_400 }}
            >
              - Custom
            </Typography>
          )}
        </Box>
        <Box>
          {CUSTOM_COMPONENT_TYPES.includes(component.type) && (
            <IconButton
              sx={{
                padding: 0,
                marginRight: '0.2rem',
              }}
              onClick={handleOpen}
            >
              <PendingRoundedIcon sx={{ color: colorThemes.GREY_200 }} />
            </IconButton>
          )}
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={removeComponent}
          >
            <RemoveCircle sx={{ color: colorThemes.GREY_200 }} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
