import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import theme from '../theme';

interface ProjectGridItemProps {
  title: string;
  loading?: boolean;
  content: React.ReactNode;
  icon?: React.ReactNode;
  overflow?: 'auto' | 'hidden';
}

export default function GridItem({
  title,
  loading = false,
  content,
  icon,
  overflow = 'auto',
}: ProjectGridItemProps) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        marginTop: '20px',
        width: '100%',
        height: '95%',
        bgcolor: '#001529',
        padding: 2.5,
        borderRadius: theme.spacing(1),
        position: 'relative',
      }}
    >
      {icon && (
        <Box
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          {icon}
        </Box>
      )}
      <Typography variant="h5">{title}</Typography>
      <Box
        sx={{
          mt: 2,
          height: 'calc(100% - 60px)',
          overflowY: overflow,
          wordBreak: 'break-word',
        }}
      >
        {loading ? <CircularProgress color="inherit" /> : content}
      </Box>
    </Grid>
  );
}
