import React, { FC } from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Chip,
  Divider,
} from '@mui/material';
import {
  Schedule,
  CheckCircle,
  Upload,
  Description,
  Title,
  Event,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import {
  IntervalEnum,
  TrailLiteracyTrainingPreview,
} from '../../../__generated__/gql/graphql';
import { FormValues } from '../TrainingCreationModal';

interface ReviewStepProps {
  formValues: FormValues;
  selectedFile: File | null;
  selectedExistingMaterial?: TrailLiteracyTrainingPreview;
}

const ReviewStep: FC<ReviewStepProps> = ({
  formValues,
  selectedFile,
  selectedExistingMaterial,
}) => {
  const formatInterval = (interval: IntervalEnum) => {
    return interval.charAt(0) + interval.slice(1).toLowerCase();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxWidth: 800,
        mx: 'auto',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          backgroundColor: 'background.paper',
        }}
      >
        <List sx={{ p: 0 }}>
          <ReviewItem icon={<Title />} label="Title" value={formValues.title} />
          <Divider component="li" />
          <ReviewItem
            icon={<Description />}
            label="Description"
            value={formValues.description}
          />
          <Divider component="li" />
          <ReviewItem
            icon={<Schedule />}
            label="Training Schedule"
            value={formatInterval(formValues.interval)}
          />
          <Divider component="li" />
          {formValues.dueDate && (
            <>
              <ReviewItem
                icon={<Event />}
                label="Due Date"
                value={formValues.dueDate.toLocaleString(DateTime.DATE_FULL)}
              />
              <Divider component="li" />
            </>
          )}
          <ListItem sx={{ py: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                width: '100%',
              }}
            >
              <CheckCircle sx={{ color: 'text.secondary' }} />
              <Box sx={{ flex: 1 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Training Status
                </Typography>
                <Chip
                  size="small"
                  label={formValues.isRequired ? 'Required' : 'Optional'}
                  color={formValues.isRequired ? 'primary' : 'default'}
                  sx={{ mt: 1 }}
                />
              </Box>
            </Box>
          </ListItem>
          <Divider component="li" />
          {selectedFile && (
            <>
              <Divider component="li" />
              <ListItem sx={{ py: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  <Upload sx={{ color: 'text.secondary' }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1">
                      <strong>Training Material:</strong>{' '}
                      {selectedExistingMaterial
                        ? `Using existing material: ${selectedExistingMaterial.previewTitle}`
                        : selectedFile
                        ? selectedFile.name
                        : 'No material selected'}
                    </Typography>
                    <Chip
                      size="small"
                      label={`${(selectedFile.size / 1024 / 1024).toFixed(
                        2
                      )} MB`}
                      variant="outlined"
                    />
                  </Box>
                </Box>
              </ListItem>
            </>
          )}
        </List>
      </Paper>
    </Box>
  );
};

const ReviewItem: FC<{
  icon: React.ReactNode;
  label: string;
  value: string;
}> = ({ icon, label, value }) => (
  <ListItem sx={{ py: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
      {React.cloneElement(icon as React.ReactElement, {
        sx: { color: 'text.secondary' },
      })}
      <Box sx={{ flex: 1 }}>
        <Typography variant="subtitle2" color="text.secondary">
          {label}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {value}
        </Typography>
      </Box>
    </Box>
  </ListItem>
);

export default ReviewStep;
