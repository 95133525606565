import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import {
  OrganizationDepartmentsQuery,
  RiskClassCategoryEnum,
  Stage,
  UsersBasicInfoQuery,
} from '../__generated__/gql/graphql';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useState } from 'react';
import { DefaultDepartments } from './project_creation_modal/ModelSpesificationStage';
import { StageDisplayMap } from './project_creation_modal/UserFacingModalStage';

export interface ProjectFilterProps {
  userId: string;
  departments: string[];
  riskClasses: RiskClassCategoryEnum[];
  stages: Stage[];
  isPurchased: boolean | null;
}

export const ProjectBaseFilter: ProjectFilterProps = {
  userId: '',
  departments: [],
  riskClasses: [],
  stages: [],
  isPurchased: null,
};

export default function ProjectFilter({
  projectFilters,
  setProjectFilters,
  userData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  departmentsData,
}: {
  projectFilters: ProjectFilterProps;
  setProjectFilters: (projectFilters: ProjectFilterProps) => void;
  userData: UsersBasicInfoQuery;
  departmentsData: OrganizationDepartmentsQuery;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        sx={{
          color: 'white',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Button sx={{ color: 'white' }} onClick={() => setOpen(true)}>
          <FilterAltRoundedIcon />
          <Box component="span" sx={{ display: 'inline' }}>
            Filter
          </Box>
        </Button>
        <Button
          sx={{
            position: 'absolute',
            width: '130px',
            left: 'calc(100% + 8px)',
            display: projectFilters === ProjectBaseFilter ? 'none' : 'block',
          }}
          onClick={() => setProjectFilters(ProjectBaseFilter)}
        >
          Remove Filters
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card
          sx={{
            maxHeight: '90%',
            minWidth: '20%',
            maxWidth: '30%',
            minHeight: '40%',
            overflowY: 'scroll',
          }}
        >
          <CardHeader title="Filters" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <Typography sx={{ fontSize: '1rem', width: '200px' }}>
                  Responsible Person:
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="simple-select-objective"
                    required
                    sx={{ width: '100%' }}
                    value={projectFilters.userId || ''}
                    onChange={e => {
                      setProjectFilters({
                        ...projectFilters,
                        userId: e.target.value,
                      });
                    }}
                  >
                    {Object.values(userData.allUsers || []).map(user => {
                      if (!user) return <></>;
                      return (
                        <MenuItem key={user.id} value={user.id}>
                          {user.name ? user.name : user.email}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value={''}>No User</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <Typography sx={{ fontSize: '1rem', width: '200px' }}>
                  Departments:
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="simple-select-objective"
                    sx={{ width: '100%' }}
                    required
                    size="small"
                    multiple
                    value={projectFilters.departments || []}
                    onChange={e => {
                      if (e.target.value.includes('no value')) {
                        setProjectFilters({
                          ...projectFilters,
                          departments: [],
                        });
                        return;
                      }
                      setProjectFilters({
                        ...projectFilters,
                        departments: e.target.value as string[],
                      });
                    }}
                    renderValue={selected => (selected as string[]).join(', ')}
                  >
                    {DefaultDepartments.map(department => (
                      <MenuItem key={department} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                    <MenuItem value={'no value'}>No Department</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '1rem', width: '200px' }}>
                  Stage:
                </Typography>

                {Object.values(Stage).map(stage => (
                  <Box
                    key={stage}
                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                  >
                    <Checkbox
                      checked={projectFilters.stages.includes(stage)}
                      onChange={() => {
                        const newStages = projectFilters.stages.includes(stage)
                          ? projectFilters.stages.filter(s => s !== stage)
                          : [...projectFilters.stages, stage];
                        setProjectFilters({
                          ...projectFilters,
                          stages: newStages,
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>{StageDisplayMap[stage]}</Typography>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: '1rem', width: '200px' }}>
                  Risk Class:
                </Typography>

                {Object.values(RiskClassCategoryEnum).map(riskClass => (
                  <Box
                    key={riskClass}
                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                  >
                    <Checkbox
                      checked={projectFilters.riskClasses.includes(riskClass)}
                      onChange={() => {
                        const newRiskClasses =
                          projectFilters.riskClasses.includes(riskClass)
                            ? projectFilters.riskClasses.filter(
                                s => s !== riskClass
                              )
                            : [...projectFilters.riskClasses, riskClass];
                        setProjectFilters({
                          ...projectFilters,
                          riskClasses: newRiskClasses,
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography>{RiskClassDisplayMap[riskClass]}</Typography>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <Typography sx={{ fontSize: '1rem', width: '200px' }}>
                  Purchase Type:
                </Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="simple-select-purchase-type"
                    value={
                      projectFilters.isPurchased === null
                        ? 'all'
                        : projectFilters.isPurchased.toString()
                    }
                    onChange={e => {
                      setProjectFilters({
                        ...projectFilters,
                        isPurchased:
                          e.target.value === 'all'
                            ? null
                            : e.target.value === 'true',
                      });
                    }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">Procured</MenuItem>
                    <MenuItem value="false">Internally Built</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'end' }}
              >
                <Button onClick={() => setProjectFilters(ProjectBaseFilter)}>
                  Remove Filters
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

const RiskClassDisplayMap = {
  [RiskClassCategoryEnum.Minimal]: 'Minimal',
  [RiskClassCategoryEnum.Limited]: 'Limited',
  [RiskClassCategoryEnum.High]: 'High',
  [RiskClassCategoryEnum.HighExemptions]: 'High (Exemptions)',
  [RiskClassCategoryEnum.HighAndLimited]: 'High and Limited',
  [RiskClassCategoryEnum.Prohibited]: 'Prohibited',
};

export const filterProjects = ({
  responsible,
  stage,
  department,
  riskClass,
  isPurchased,
  filters,
}: {
  responsible: string | undefined;
  stage: Stage | null | undefined;
  department: string | null | undefined;
  riskClass: RiskClassCategoryEnum | null | undefined;
  isPurchased: boolean | null | undefined;
  filters: ProjectFilterProps;
}) => {
  if (filters.userId && (!responsible || responsible !== filters.userId)) {
    return false;
  }
  if (
    filters.departments.length &&
    (!department || !filters.departments.includes(department))
  ) {
    return false;
  }
  if (filters.stages.length && (!stage || !filters.stages.includes(stage))) {
    return false;
  }
  if (
    filters.riskClasses.length &&
    (!riskClass || !filters.riskClasses.includes(riskClass))
  ) {
    return false;
  }
  if (filters.isPurchased !== null && isPurchased !== filters.isPurchased) {
    return false;
  }
  return true;
};
