import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

interface Props {
  onClose: () => void;
  showConfirmationDialog: boolean;
  title: string;
  content: string | React.ReactNode;
  actions: React.ReactNode;
}

export const ConfirmationDialog = ({
  onClose,
  showConfirmationDialog,
  title,
  content,
  actions,
}: Props) => {
  return (
    <Dialog
      open={showConfirmationDialog}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            padding: '16px',
          }}
        >
          {actions}
        </Box>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};
