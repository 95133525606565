import React, { Fragment, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  ButtonGroup,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import FlagIcon from '@mui/icons-material/Flag';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import SettingsIcon from '@mui/icons-material/Settings';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import TrashIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import { v4 as uuidv4 } from 'uuid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  useMutation,
  gql,
  useSuspenseQuery,
  useApolloClient,
} from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';
import theme, { colorThemes } from '../theme';
import generateLink from '../utils/genericHelpers';
import IosShareIcon from '@mui/icons-material/IosShare';
import MenuButton from '../components/MenuButton';
import { GET_PROJECTS } from './ProjectView';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Risk } from './RisksTable';
import {
  DataTypeEnum,
  GetAllUsersForSelectQuery,
  GetCurrentUserForConcernReportQuery,
  GetItAssetsQuery,
  ItAsset,
  Project,
  ProjectDetailDrawerQueryQuery,
  ProjectGovernanceFrameworkTemplate,
  RemoteGitSync,
  RiskClassCategoryEnum,
  RiskClassRoleEnum,
  RiskClassSpecialCaseEnum,
  Stage,
} from '../__generated__/gql/graphql';
import RiskClassTag, { RiskClassType } from './RiskClassTag';
import ProjectGridItem from './ProjectGridItem';
import StackEditor from './StackEditor';
import EditableTypography from './EditableTypography';
import ProjectSettingsModal from './ProjectSettingsModal';
import ReportConcernDialog from './ReportConcernDialog';
import { TabContext, TabPanel } from '@mui/lab';
import { Link, Link as RouterLink } from 'react-router-dom';
import NewGovernanceFrameworkModal from './NewGovernanceFrameworkModal';
import AddNewItemCard from '../components/AddNewItemCard';
import RemoteGitSyncForm from './RemoteGitSyncModal';
import { copyToClipboard } from '../components/clipoadUtils';
import UserTag from '../components/UserTag';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import AddItAssetDialog from './AddItAssetDialog';

const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId) {
      id
      success
    }
  }
`;

const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProject(
    $description: String
    $intendedUseCases: [String]
    $limitations: [String]
    $projectId: String!
    $title: String
    $unintendedUseCases: [String]
    $department: String
    $dataSource: String
    $stage: String
    $risks: [RisksInput]
    $usagePolicy: String
    $responsiblePerson: String
    $riskClass: RiskClassInput
    $targetQueryMutation: String!
    $action: String!
    $dataType: DataTypeEnum
    $users: String
    $itAsset: String
    $operationEntryDate: DateTime
  ) {
    updateProject(
      description: $description
      intendedUseCases: $intendedUseCases
      limitations: $limitations
      projectId: $projectId
      title: $title
      unintendedUseCases: $unintendedUseCases
      department: $department
      dataSource: $dataSource
      stage: $stage
      risks: $risks
      usagePolicy: $usagePolicy
      responsiblePerson: $responsiblePerson
      riskClass: $riskClass
      dataType: $dataType
      users: $users
      itAsset: $itAsset
      operationEntryDate: $operationEntryDate
    ) {
      __typename
      project {
        __typename
        stage
        id
        isPurchased
        projectType
        vendor
        riskClass {
          __typename
          riskClassRole
          riskClassCategory
          riskClassSpecialCase
        }
        allowedDataTypes
        allowedTaskTypes
        title
        limitations
        description
        intendedUseCases
        remoteGitSync {
          branch
          publicDeploymentKey
          id
          lastSyncedAt
          repoName
          repoUrl
          syncIntervalMinutes
        }
        responsible {
          __typename
          id
          name
          email
          initials
        }
        numberOfDocumentations
        numberOfExperiments
        risks {
          __typename
          impact
          likelihood
          mitigation
          riskDescription
        }
        projectGovernanceFrameworks {
          __typename
          id
          name
        }
        recommendedGovernanceFrameworks {
          __typename
          id
          name
        }
        mostRecentExperiment {
          __typename
          instanceRuns {
            __typename
            metrics
          }
        }
        department
        dataSource
        dataType
        users
        itAsset
        operationEntryDate
        usagePolicy
        checkPermission(
          targetQueryMutation: $targetQueryMutation
          action: $action
        ) {
          hasPermission
          userId
        }
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query ProjectDetailDrawerQuery(
    $projectId: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    project(id: $projectId) {
      __typename
      title
      limitations
      description
      intendedUseCases
      isPurchased
      projectType
      vendor
      riskClass {
        __typename
        riskClassCategory
        riskClassRole
        riskClassSpecialCase
      }
      allowedDataTypes
      allowedTaskTypes
      remoteGitSync {
        __typename
        branch
        publicDeploymentKey
        id
        lastSyncedAt
        repoName
        repoUrl
        syncIntervalMinutes
      }
      responsible {
        __typename
        id
        name
        email
        initials
      }
      numberOfDocumentations
      numberOfExperiments
      risks {
        __typename
        impact
        likelihood
        mitigation
        riskDescription
      }
      projectGovernanceFrameworks {
        id
        name
      }
      recommendedGovernanceFrameworks {
        id
        name
      }
      mostRecentExperiment {
        instanceRuns {
          metrics
        }
      }
      dataType
      users
      itAsset
      operationEntryDate
      stage
      department
      dataSource
      usagePolicy
      checkPermission(
        targetQueryMutation: $targetQueryMutation
        action: $action
      ) {
        hasPermission
        userId
      }
    }
  }
`;

const GET_CURRENT_USER = gql`
  query GetCurrentUserForConcernReport {
    currentUser {
      email
      name
      initials
    }
  }
`;

const GET_USERS = gql`
  query GetAllUsersForSelect {
    allUsers {
      id
      email
      initials
      name
    }
  }
`;

const DELETE_PROJECT_GOVERNANCE_FRAMEWORK = gql`
  mutation DeleteProjectGovernanceFramework(
    $projectId: String!
    $materializedFrameworkId: String!
  ) {
    deleteProjectGovernanceFramework(
      projectId: $projectId
      materializedFrameworkId: $materializedFrameworkId
    ) {
      project {
        id
        projectGovernanceFrameworks {
          __typename
          id
          name
        }
        recommendedGovernanceFrameworks {
          __typename
          id
          name
        }
      }
    }
  }
`;

export const GET_IT_ASSETS = gql`
  query GetItAssets {
    allItAssets {
      id
      name
      created
      lastModified
    }
  }
`;

export interface ProjectDetailDrawerProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  selectedProject: Project;
  setProject: (project: Project | undefined) => void;
}

const TAB_VALUES = {
  USER: 'user',
  DEV: 'dev',
  GOV: 'gov',
} as const;

export const stageLabels: Record<Stage, string> = {
  [Stage.Plan]: 'Planning Phase',
  [Stage.Develop]: 'Development Phase',
  [Stage.Deploy]: 'Deployment Phase',
  [Stage.Operate]: 'Operation Phase',
  [Stage.Retire]: 'Retirement Phase',
};

type TabValue = (typeof TAB_VALUES)[keyof typeof TAB_VALUES];

export default function ProjectDetailDrawer({
  setShowSidebar,
  selectedProject,
  setProject,
}: ProjectDetailDrawerProps) {
  const { id: projectId } = selectedProject;
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    variables: { projectId },
    refetchQueries: [{ query: GET_PROJECTS }],
  });
  const client = useApolloClient();

  const { data: drawerDetail } =
    useSuspenseQuery<ProjectDetailDrawerQueryQuery>(GET_PROJECT, {
      variables: {
        projectId,
        targetQueryMutation: 'deleteProjectGovernanceFramework',
        action: 'mutation',
      },
    });

  const [deleteProjectGovernanceFramework] = useMutation(
    DELETE_PROJECT_GOVERNANCE_FRAMEWORK
  );

  const handleDeleteProject = async () => {
    try {
      await deleteProject();
      setShowSidebar(false);
      const storedProject = localStorage.getItem('selectedProject');
      const parsedStoredProject = storedProject
        ? JSON.parse(storedProject)
        : undefined;
      if (parsedStoredProject.id === projectId) {
        setProject(undefined);
        localStorage.removeItem('selectedProject');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const confirmDelete = async (
    materializedFrameworkId: string,
    projectId: string
  ) => {
    if (!materializedFrameworkId || !projectId) return;

    try {
      const { data } = await deleteProjectGovernanceFramework({
        variables: { projectId, materializedFrameworkId },
      });
      client.cache.updateQuery(
        {
          query: GET_PROJECT,
          variables: {
            projectId,
            targetQueryMutation: 'deleteProjectGovernanceFramework',
            action: 'mutation',
          },
        },
        dataCache => {
          const updatedProjectGovFrameworks =
            data?.deleteProjectGovernanceFramework?.project
              ?.projectGovernanceFrameworks;
          return {
            ...dataCache,
            project: {
              ...dataCache.project,
              projectGovernanceFrameworks: updatedProjectGovFrameworks,
            },
          };
        }
      );
      toast.success('Successfully deleted project governance framework');
    } catch (error) {
      console.error('Error deleting project governance framework:', error);
      toast.error('Failed to delete project governance framework');
    } finally {
      setDeleteConfirmOpen(false);
    }
  };

  const handleExport = async (format: string) => {
    try {
      const res = await generateLink(`export_project_${format}/${projectId}`);
      const data = await res.blob();
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `project_${projectId}.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      toast.error('An error occurred while exporting the project.');
      console.error(error);
    }
  };
  const { data: allUsersUnsorted } =
    useSuspenseQuery<GetAllUsersForSelectQuery>(GET_USERS);

  const [responsiblePerson, setResponsiblePerson] = useState<string>(
    drawerDetail!.project?.responsible?.id ?? ''
  );
  const [stage, setStage] = useState<Stage>(
    drawerDetail?.project?.stage ?? Stage.Develop
  );
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [riskClassCategory, setRiskClassCategory] = useState<
    RiskClassCategoryEnum | undefined
  >(drawerDetail?.project?.riskClass?.riskClassCategory ?? undefined);
  const [riskClassSpecialCase, setRiskClassSpecialCase] = useState<
    RiskClassSpecialCaseEnum | undefined
  >(drawerDetail?.project?.riskClass?.riskClassSpecialCase ?? undefined);
  const [riskClassRole, setRiskClassRole] = useState<
    RiskClassRoleEnum | undefined
  >(drawerDetail?.project?.riskClass?.riskClassRole ?? undefined);
  const [department, setDepartment] = useState<string>(
    drawerDetail?.project?.department ?? ''
  );
  const [limitations, setLimitations] = useState(
    (drawerDetail?.project?.limitations as string[]) ?? []
  );
  const [title, setTitle] = useState(drawerDetail?.project?.title);
  const [intendedUseCases, setIntendedUseCases] = useState(
    (drawerDetail?.project?.intendedUseCases as string[]) ?? []
  );

  const [dataSource, setDataSource] = useState<string>(
    drawerDetail?.project?.dataSource ?? ''
  );

  const [description, setDescription] = useState(
    drawerDetail?.project?.description
  );

  const [usagePolicy, setUsagePolicy] = useState<string>(
    drawerDetail?.project?.usagePolicy ?? ''
  );

  const [dataType, setDataType] = useState<DataTypeEnum | undefined>(
    drawerDetail?.project?.dataType ?? undefined
  );
  const [users, setUsers] = useState<string>(
    drawerDetail?.project?.users ?? ''
  );
  const [isAddItAssetOpen, setIsAddItAssetOpen] = useState(false);

  const [operationEntryDate, setOperationEntryDate] = useState<Date | null>(
    drawerDetail?.project?.operationEntryDate
      ? new Date(drawerDetail.project.operationEntryDate)
      : null
  );
  const { data: itAssetsData } =
    useSuspenseQuery<GetItAssetsQuery>(GET_IT_ASSETS);

  const [itAsset, setItAsset] = useState<string>(
    drawerDetail?.project?.itAsset ?? ''
  );

  const handleItAssetChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'add') {
      setIsAddItAssetOpen(true);
    } else {
      setItAsset(event.target.value);
    }
  };

  const handleAddItAsset = async (newAsset: ItAsset) => {
    setItAsset(newAsset.id ?? '');

    try {
      await updateProject({
        variables: {
          projectId,
          itAsset: newAsset.id,
          limitations,
          dataSource,
          stage,
          responsiblePerson,
          intendedUseCases,
          description,
          department,
          risks,
          usagePolicy,
          title,
          riskClass: {
            riskClassCategory,
            riskClassRole,
            riskClassSpecialCase,
          },
          dataType,
          users,
          operationEntryDate,
          targetQueryMutation: 'deleteProjectGovernanceFramework',
          action: 'mutation',
        },
      });
      toast.success('Successfully updated project with new IT asset');
    } catch (error) {
      console.error('Error updating project with new IT asset:', error);
      toast.error('Failed to update project with new IT asset');
    }
  };

  const [tab, setTab] = useState<TabValue>(TAB_VALUES.USER);
  const metrics =
    drawerDetail.project?.mostRecentExperiment?.instanceRuns?.[0]?.metrics;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  let mostRecentExperimentMetricName = 'n/a';
  let mostRecentExperimentMetricValue = 'n/a';
  if (metrics && Object.keys(metrics).length > 0) {
    mostRecentExperimentMetricName = Object.keys(metrics)[0];
    mostRecentExperimentMetricValue = metrics[mostRecentExperimentMetricName]
      .toString()
      .substring(0, 4);
  }

  const risksWithoutTypename = (drawerDetail?.project?.risks ?? [])
    .filter(
      (
        row
      ): row is {
        __typename: 'Risks';
        impact?: string | null;
        likelihood?: string | null;
        mitigation?: string | null;
        riskDescription?: string | null;
      } => row !== null
    )
    .map(risk => ({
      ...risk,
      id: uuidv4(),
    })) as Risk[];

  const recommendedGovernanceFrameworks =
    drawerDetail?.project?.recommendedGovernanceFrameworks?.filter(
      (framework): framework is ProjectGovernanceFrameworkTemplate =>
        framework !== null
    );

  const [risks, setRisks] = useState<Risk[]>(risksWithoutTypename);

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    variables: {
      projectId,
      limitations,
      dataSource,
      stage,
      responsiblePerson,
      intendedUseCases,
      description,
      department,
      risks,
      usagePolicy,
      title,
      riskClass: {
        riskClassCategory,
        riskClassRole,
        riskClassSpecialCase,
      },
      dataType,
      users,
      itAsset,
      operationEntryDate,
      targetQueryMutation: 'deleteProjectGovernanceFramework',
      action: 'mutation',
    },
    update(cache, { data }) {
      if (!data?.updateProject?.project) return;
      const updatedProject = {
        ...data.updateProject.project,
        __typename: 'Project',
        intendedUseCases: data.updateProject.project.intendedUseCases || [],
        limitations: data.updateProject.project.limitations || [],
        risks: data.updateProject.project.risks || [],
      };

      cache.writeQuery({
        query: GET_PROJECT,
        variables: { projectId },
        data: {
          project: updatedProject,
          __typename: 'Query',
        },
      });

      const existingData = cache.readQuery<{ allProjects: Project[] }>({
        query: GET_PROJECTS,
      });

      if (existingData?.allProjects) {
        cache.writeQuery({
          query: GET_PROJECTS,
          data: {
            allProjects: existingData.allProjects.map((project: Project) =>
              project?.id === projectId ? updatedProject : project
            ),
          },
        });
      }
    },
    onError: error => {
      toast.error('Failed to update project');
      console.error('Update project error:', error);
    },
  });

  const submitUpdateProject = async () => {
    try {
      await updateProject();
      toast.success("Successfully updated project's details");
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error('Project update was not successful');
    }
  };

  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] =
    useState<HTMLElement | null>(null);
  const open = Boolean(moreOptionsAnchorEl);

  const handleMoreOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreOptionsAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setMoreOptionsAnchorEl(null);
  };
  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: TabValue
  ) => {
    setTab(newValue);
  };
  const [isSettingsModalOpen, setIsSettingsModalOpen] =
    useState<boolean>(false);
  const [isGovernanceFrameworkModelOpen, setIsGovernanceFrameworkModelOpen] =
    useState<boolean>(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const showConcernDialog = () => {
    setOpenReportDialog(true);
  };
  const { data } =
    useSuspenseQuery<GetCurrentUserForConcernReportQuery>(GET_CURRENT_USER);
  const currentUser = data?.currentUser;
  const pgfDeletePerms =
    drawerDetail.project?.checkPermission?.hasPermission ?? false;

  const resetState = () => {
    setTitle(drawerDetail?.project?.title ?? '');
    setResponsiblePerson(drawerDetail!.project?.responsible?.id ?? '');
    setStage(drawerDetail?.project?.stage ?? Stage.Develop);
    setDepartment(drawerDetail?.project?.department ?? '');
    setLimitations((drawerDetail?.project?.limitations as string[]) ?? []);
    setIntendedUseCases(
      (drawerDetail?.project?.intendedUseCases as string[]) ?? []
    );
    setDataSource(drawerDetail?.project?.dataSource ?? '');
    setDescription(drawerDetail?.project?.description ?? '');
    setUsagePolicy(drawerDetail?.project?.usagePolicy ?? '');
    setRisks(risksWithoutTypename);
    setRiskClassCategory(
      drawerDetail?.project?.riskClass?.riskClassCategory ?? undefined
    );
    setRiskClassRole(
      drawerDetail?.project?.riskClass?.riskClassRole ?? undefined
    );
    setRiskClassSpecialCase(
      drawerDetail?.project?.riskClass?.riskClassSpecialCase ?? undefined
    );
    setDataType(drawerDetail?.project?.dataType ?? undefined);
    setUsers(drawerDetail?.project?.users ?? '');
    setItAsset(drawerDetail?.project?.itAsset ?? '');
    setOperationEntryDate(
      drawerDetail?.project?.operationEntryDate
        ? new Date(drawerDetail.project.operationEntryDate)
        : null
    );
  };

  const isPurchased = drawerDetail?.project?.isPurchased ?? false;

  const projectType = drawerDetail?.project?.projectType || 'Unknown';
  const vendor = drawerDetail?.project?.vendor || 'Unknown';
  return (
    <>
      <Grid
        sx={{
          width: '100%',
          py: '30px',
          display: 'flex',
          justifyContent: 'start',
        }}
      >
        <Box sx={{ px: '30px', width: '100%' }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'space-between', pb: '10px' }}
          >
            <Box sx={{ flexGrow: 1, minWidth: 0, mr: 2 }}>
              <EditableTypography
                variant="h4"
                multiline={false}
                isEditable={isEditable}
                onChange={e => setTitle(e as string)}
              >
                {title}
              </EditableTypography>
            </Box>
            <Box display="flex" marginLeft="auto" gap={2}>
              <Button
                onClick={() => {
                  setProject(selectedProject);
                  setShowSidebar(false);
                }}
                variant="contained"
                startIcon={
                  <CheckIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                }
              >
                Select as current project
              </Button>
              <MenuButton
                variant="contained"
                startIcon={
                  <EditIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                }
                label={isEditable ? 'Cancel' : 'Edit'}
                onClick={() => {
                  if (isEditable) {
                    resetState();
                  }
                  setIsEditable(!isEditable);
                }}
                items={[]}
              />
              {isEditable && (
                <MenuButton
                  variant="contained"
                  startIcon={
                    <SaveIcon style={{ color: colorThemes.DARK_BLUE_800 }} />
                  }
                  label={'Save'}
                  onClick={() => {
                    submitUpdateProject();
                    setIsEditable(false);
                  }}
                  items={[]}
                />
              )}

              <Button
                aria-controls={open ? 'simple-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                startIcon={<MoreVertIcon sx={{ color: '#000' }} />}
                onClick={handleMoreOptionsClick}
              >
                more options
              </Button>
            </Box>

            <Box
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <Menu
                id="project-menu"
                anchorEl={moreOptionsAnchorEl}
                open={open}
                onClose={handleMoreOptionsClose}
                MenuListProps={{
                  'aria-labelledby': 'more-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleDeleteProject();
                    handleMoreOptionsClose();
                  }}
                >
                  <TrashIcon />
                  Delete Project
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    showConcernDialog();
                  }}
                >
                  <FlagIcon />
                  report a concern
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleExport('json');
                    handleMoreOptionsClose();
                  }}
                >
                  <IosShareIcon />
                  Export as .json
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsSettingsModalOpen(true);
                    handleMoreOptionsClose();
                  }}
                >
                  <SettingsIcon />
                  Settings
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Typography
            align="left"
            variant="body2"
            color="text.secondary"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              copyToClipboard(projectId, 'Project ID copied to clipboard!')
            }
          >
            Project ID: {projectId}
          </Typography>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography align="left" variant="body2" color="text.secondary">
              Entry Date
            </Typography>
            <DatePicker
              value={
                operationEntryDate
                  ? DateTime.fromJSDate(operationEntryDate)
                  : null
              }
              onChange={(newValue: DateTime | null) => {
                setOperationEntryDate(newValue?.toJSDate() ?? null);
              }}
              disabled={!isEditable}
              slotProps={{
                textField: {
                  variant: 'standard',
                  sx: {
                    my: 0,
                    '& .MuiInput-underline.Mui-disabled:before': {
                      borderBottomStyle: 'solid',
                      borderBottomColor: 'rgba(0, 0, 0, 0.12)',
                    },
                    '& .Mui-disabled .MuiSvgIcon-root': {
                      display: 'none',
                    },
                  },
                },
              }}
              format="yyyy-MM-dd"
            />
          </Box>
        </Box>
      </Grid>
      <Divider sx={{ borderBottomWidth: 1, bgcolor: 'white' }} />
      <TabContext value={tab}>
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
            }}
          >
            <Tab
              label="User Facing"
              id="user-facing-tab"
              value={TAB_VALUES.USER}
              sx={{ flexGrow: 1 }}
            />
            {!isPurchased && (
              <Tab
                label="Development"
                id="development-tab"
                value={TAB_VALUES.DEV}
                sx={{ flexGrow: 1 }}
              />
            )}
            <Tab
              label="Project Governance"
              id="project-gov-tab"
              value={TAB_VALUES.GOV}
              sx={{ flexGrow: 1 }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={TAB_VALUES.USER}
          sx={{ height: '100%', overflow: 'auto', pb: '20px' }}
        >
          <Box
            sx={{
              marginLeft: '20px',
              marginRight: '20px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            <Grid
              container
              columnSpacing={2.5}
              rowSpacing={1}
              sx={{ display: 'flex', alignItems: 'stretch' }}
            >
              {/* Left Column: Project Information */}
              <Grid item xs={6}>
                <ProjectGridItem
                  title="Project Information"
                  overflow="hidden"
                  content={
                    <TableContainer sx={{ height: 'auto', mb: '20px' }}>
                      <Table
                        size="small"
                        sx={{
                          '& tr': { height: '80px' },
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" width={'30%'}>
                              Stage
                            </TableCell>
                            <TableCell>
                              <FormControl
                                variant="filled"
                                fullWidth
                                sx={{
                                  flex: 1,
                                }}
                              >
                                <Select
                                  value={stage}
                                  onChange={event =>
                                    setStage(event.target.value as Stage)
                                  }
                                  disabled={!isEditable}
                                  sx={{
                                    '& .MuiSelect-select': {
                                      py: 1,
                                      px: 1,
                                      whiteSpace: 'normal',
                                    },
                                    '&.Mui-disabled': {
                                      '& .MuiSelect-select': {
                                        WebkitTextFillColor: 'rgb(255,255,255)',
                                        whiteSpace: 'nowrap',
                                        pl: 1,
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(Stage).map(
                                    (stageName, stageIndex) => (
                                      <MenuItem
                                        key={stageIndex}
                                        value={stageName}
                                      >
                                        {stageLabels[stageName]}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              width="30%"
                              sx={{ py: 1 }}
                            >
                              {'Service & Vendor'}
                            </TableCell>
                            <TableCell sx={{ py: 1, px: '24px' }}>
                              <Typography variant="body1">
                                {projectType}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Vendor: {vendor}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <Tooltip
                              title={
                                'This links to a software resource in the enterprise inventory for accurate tracking and management.'
                              }
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ py: 1 }}
                              >
                                IT Asset
                              </TableCell>
                            </Tooltip>
                            <TableCell sx={{ py: 1 }}>
                              <FormControl
                                variant="filled"
                                fullWidth
                                size="small"
                              >
                                <Select
                                  value={itAsset}
                                  onChange={handleItAssetChange}
                                  disabled={!isEditable}
                                  sx={{
                                    '& .MuiSelect-select': {
                                      py: 1,
                                      px: 1,
                                      whiteSpace: 'normal',
                                    },
                                    '&.Mui-disabled': {
                                      '& .MuiSelect-select': {
                                        WebkitTextFillColor: 'rgb(255,255,255)',
                                        whiteSpace: 'nowrap',
                                        pl: 1,
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                >
                                  {(itAssetsData?.allItAssets ?? []).map(
                                    asset => (
                                      <MenuItem
                                        key={asset?.id ?? 'default-key'}
                                        value={asset?.id ?? ''}
                                      >
                                        {asset?.name}
                                      </MenuItem>
                                    )
                                  )}
                                  <MenuItem value="add">
                                    + Add New Asset
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ py: 1 }}
                            >
                              Data Type
                            </TableCell>
                            <TableCell sx={{ py: 1 }}>
                              <FormControl
                                variant="filled"
                                fullWidth
                                size="small"
                              >
                                <Select
                                  value={dataType || ''}
                                  onChange={e =>
                                    setDataType(e.target.value as DataTypeEnum)
                                  }
                                  disabled={!isEditable}
                                  sx={{
                                    '& .MuiSelect-select': {
                                      py: 1,
                                      px: 1,
                                      whiteSpace: 'normal',
                                    },
                                    '&.Mui-disabled': {
                                      '& .MuiSelect-select': {
                                        WebkitTextFillColor: 'rgb(255,255,255)',
                                        whiteSpace: 'nowrap',
                                        pl: 1,
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                >
                                  {Object.values(DataTypeEnum).map(type => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                />
              </Grid>

              {/* Right Column: Stage and Responsible */}
              <Grid item xs={6}>
                <ProjectGridItem
                  title="Team & Department"
                  content={
                    <TableContainer>
                      <Table
                        size="small"
                        sx={{
                          '& tr': { height: '80px' },
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell width="30%">Department</TableCell>
                            <TableCell sx={{ px: '24px' }}>
                              <TextField
                                fullWidth
                                variant="standard"
                                value={department}
                                onChange={e => setDepartment(e.target.value)}
                                InputProps={{
                                  readOnly: !isEditable,
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell width="30%">
                              Responsible Person
                            </TableCell>
                            <TableCell>
                              <FormControl variant="filled" fullWidth>
                                <Select
                                  fullWidth
                                  value={responsiblePerson}
                                  onChange={event =>
                                    setResponsiblePerson(
                                      event.target.value as string
                                    )
                                  }
                                  disabled={!isEditable}
                                  sx={{
                                    '& .MuiSelect-select': {
                                      py: 1,
                                      px: 1,
                                      whiteSpace: 'normal',
                                    },
                                    '&.Mui-disabled': {
                                      '& .MuiSelect-select': {
                                        WebkitTextFillColor: 'rgb(255,255,255)',
                                        whiteSpace: 'nowrap',
                                        pl: 1,
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        display: 'none',
                                      },
                                    },
                                  }}
                                >
                                  {(allUsersUnsorted?.allUsers ?? []).map(
                                    user => (
                                      <MenuItem key={user?.id} value={user?.id}>
                                        <UserTag
                                          user={user ?? undefined}
                                          variant="text"
                                          size="medium"
                                        />
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" width="30%">
                              Users
                            </TableCell>
                            <TableCell sx={{ px: '24px' }}>
                              <TextField
                                fullWidth
                                variant="standard"
                                value={users}
                                onChange={e => setUsers(e.target.value)}
                                disabled={!isEditable}
                                multiline
                                size="small"
                                rows={2}
                                sx={{ pt: '20px' }}
                                InputProps={{
                                  readOnly: !isEditable,
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                />
              </Grid>

              {/* Next row: Role / Risk Class / GPAI Case */}
              <Grid
                item
                display={'flex'}
                xs={12}
                sm={12}
                flexDirection={'column'}
                gap={4}
                height={'260px'}
              >
                <Box display={'flex'} gap={2.5} height={'100%'}>
                  <ProjectGridItem
                    title="Role"
                    content={
                      <Box>
                        <RiskClassTag
                          riskClassRole={riskClassRole}
                          isEditable={isEditable}
                          setRiskClassRole={setRiskClassRole}
                          type={RiskClassType.ROLE}
                        />
                      </Box>
                    }
                  />
                  <ProjectGridItem
                    title="Risk Class"
                    content={
                      <Box>
                        <RiskClassTag
                          riskClassRole={riskClassRole}
                          riskClassCategory={riskClassCategory}
                          type={RiskClassType.CATEGORY}
                          isEditable={isEditable}
                          setRiskClassCategory={setRiskClassCategory}
                        />
                      </Box>
                    }
                  />
                  <ProjectGridItem
                    title="GPAI Case"
                    content={
                      <Box>
                        <RiskClassTag
                          riskClassRole={riskClassRole}
                          type={RiskClassType.SPECIAL_CASE}
                          isEditable={isEditable}
                          riskClassSpecialCase={riskClassSpecialCase}
                          setRiskClassSpecialCase={setRiskClassSpecialCase}
                        />
                      </Box>
                    }
                  />
                </Box>
                {isEditable ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '80px',
                      p: 1,
                      bgcolor: '#001529',
                      borderRadius: theme.spacing(1),
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      component={Link}
                      to={`/risk_classification/${projectId}`}
                    >
                      {riskClassRole
                        ? 'Redo Classification Questionnaire'
                        : 'Do Classification Questionnaire'}
                    </Button>
                  </Box>
                ) : null}
              </Grid>

              <Grid item xs={12} sm={12} height={'250px'}>
                <ProjectGridItem
                  title="Description"
                  content={
                    <EditableTypography
                      isEditable={isEditable}
                      onChange={e => setDescription(e as string)}
                    >
                      {description}
                    </EditableTypography>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <ProjectGridItem
                  title="Intended use cases"
                  content={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                      }}
                    >
                      <StackEditor
                        stacks={intendedUseCases}
                        isEditable={isEditable}
                        setStacks={setIntendedUseCases}
                      />
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} height={'250px'}>
                <ProjectGridItem
                  title="Usage Policy"
                  content={
                    <EditableTypography
                      isEditable={isEditable}
                      onChange={e => setUsagePolicy(e as string)}
                    >
                      {usagePolicy}
                    </EditableTypography>
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  height: '250px',
                  marginBottom: '40px',
                }}
              >
                <ProjectGridItem
                  title="Limitations"
                  content={
                    <StackEditor
                      stacks={limitations}
                      isEditable={isEditable}
                      setStacks={setLimitations}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        {!isPurchased && (
          <TabPanel value={TAB_VALUES.DEV}>
            <Grid
              container
              spacing={2}
              sx={{ display: 'flex', alignItems: 'stretch' }}
            >
              <Grid item xs={3} sm={3} height={'200px'}>
                <ProjectGridItem
                  title="Stage"
                  content={
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CheckCircleIcon />
                      <FormControl fullWidth variant="filled">
                        <Select
                          value={stage}
                          label="Current Stage"
                          onChange={event =>
                            setStage(event.target.value as Stage)
                          }
                          disabled={!isEditable}
                          inputProps={{ 'aria-label': 'Without label' }}
                          sx={{
                            width: '100%',
                            pt: '0px',
                            pb: '4px',
                            '& .MuiSelect-select': {
                              whiteSpace: 'normal',
                            },
                            '&.Mui-disabled': {
                              '& .MuiSelect-select': {
                                WebkitTextFillColor: 'rgb(255,255,255)',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                                pl: '16%',
                                pt: '10px',
                                pb: '6px',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                              '& .MuiSvgIcon-root': {
                                display: 'none',
                              },
                            },
                          }}
                        >
                          {Object.values(Stage).map((stageName, stageIndex) => (
                            <MenuItem key={stageIndex} value={stageName}>
                              {stageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ProjectGridItem
                  title="Last Experiment"
                  content={
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="h4">
                        {mostRecentExperimentMetricValue}
                      </Typography>
                      <Typography>{mostRecentExperimentMetricName}</Typography>
                    </Box>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ProjectGridItem
                  title="Documentation"
                  content={
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="h4">
                        {drawerDetail?.project?.numberOfDocumentations}
                      </Typography>
                      <Typography>Documentations</Typography>
                    </Box>
                  }
                  icon={
                    <RouterLink to={`/documentation/${projectId}`}>
                      <ArrowOutwardIcon />
                    </RouterLink>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <ProjectGridItem
                  title="Audit Trail"
                  content={
                    <Box sx={{ width: '100%' }}>
                      <Typography variant="h4">
                        {drawerDetail?.project?.numberOfExperiments}
                      </Typography>
                      <Typography>Experiments</Typography>
                    </Box>
                  }
                  icon={
                    <RouterLink to={`/trail_graph/${projectId}/`}>
                      <ArrowOutwardIcon />
                    </RouterLink>
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <ProjectGridItem
                  key={drawerDetail?.project?.remoteGitSync?.id}
                  title="Git Integration"
                  content={
                    <RemoteGitSyncForm
                      projectId={projectId}
                      initialData={
                        drawerDetail?.project?.remoteGitSync as RemoteGitSync
                      }
                    />
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  minHeight: '200px',
                  maxHeight: '400px',
                  height: 'auto',
                }}
              >
                <ProjectGridItem
                  title="Data Source"
                  content={
                    <EditableTypography
                      isEditable={isEditable}
                      onChange={e => setDataSource(e as string)}
                    >
                      {dataSource}
                    </EditableTypography>
                  }
                />
              </Grid>
            </Grid>
          </TabPanel>
        )}
        <TabPanel value={TAB_VALUES.GOV}>
          <Grid item xs={1} sm={1}>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Typography variant="h5">
                Project Governance Frameworks
              </Typography>
              {drawerDetail?.project?.projectGovernanceFrameworks?.map(
                frameworkData => {
                  return (
                    <Fragment key={frameworkData?.id}>
                      <Dialog
                        open={deleteConfirmOpen}
                        onClose={() => setDeleteConfirmOpen(false)}
                      >
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to delete this project
                            governance framework? This action cannot be undone.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setDeleteConfirmOpen(false)}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              confirmDelete(frameworkData?.id ?? '', projectId);
                              setDeleteConfirmOpen(false);
                            }}
                            color="error"
                          >
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                      <ProjectGridItem
                        title=""
                        content={
                          <Box
                            sx={{
                              position: 'relative',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <RouterLink
                              to={`/project_governance/${projectId}/${frameworkData?.id}`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                                display: 'block',
                                height: '100%',
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h4"
                                  style={{ marginBottom: '4px' }}
                                >
                                  {frameworkData?.name}
                                </Typography>
                                <Typography>{frameworkData?.id}</Typography>
                              </Box>
                            </RouterLink>
                          </Box>
                        }
                        icon={
                          <ButtonGroup>
                            <Tooltip
                              title={
                                !pgfDeletePerms
                                  ? 'Must be an admin or manager to perform this action.'
                                  : ''
                              }
                            >
                              <span>
                                {pgfDeletePerms && (
                                  <TrashIcon
                                    onClick={() => setDeleteConfirmOpen(true)}
                                    sx={{
                                      color: theme.palette.error.dark,
                                      '&:hover': {
                                        color: theme.palette.error.main,
                                        cursor: 'pointer',
                                      },
                                      transition: 'color 0.3s',
                                      marginRight: '5px',
                                      marginTop: '5px',
                                    }}
                                  />
                                )}
                              </span>
                            </Tooltip>
                          </ButtonGroup>
                        }
                      />
                    </Fragment>
                  );
                }
              )}

              <AddNewItemCard
                onClick={() => {
                  setIsGovernanceFrameworkModelOpen(true);
                  handleMoreOptionsClose();
                }}
                sx={{
                  width: '100%',
                  height: '100%',
                  marginTop: '15px',
                }}
              />
              <NewGovernanceFrameworkModal
                open={isGovernanceFrameworkModelOpen}
                onClose={() => {
                  setIsGovernanceFrameworkModelOpen(false);
                }}
                projectId={projectId}
                recommendedGovernanceFrameworks={
                  recommendedGovernanceFrameworks ?? []
                }
              />
            </Box>
          </Grid>
        </TabPanel>
      </TabContext>
      <ToastContainer />
      <ProjectSettingsModal
        open={isSettingsModalOpen}
        onClose={() => setIsSettingsModalOpen(false)}
        projectId={projectId}
      />
      <ReportConcernDialog
        open={openReportDialog}
        getModelName={title}
        getPerson={currentUser?.email || ''}
        onClose={() => setOpenReportDialog(false)}
      />
      <AddItAssetDialog
        open={isAddItAssetOpen}
        onClose={() => setIsAddItAssetOpen(false)}
        onAdd={handleAddItAsset}
      />
    </>
  );
}
