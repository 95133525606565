import { FC, useState } from 'react';
import {
  Box,
  CardHeader,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { colorThemes } from '../../theme';
import { Add, ExpandLess } from '@mui/icons-material';
import TreatmentBox from './TreatmentBox';
import {
  RiskAssessment,
  RiskTreatment,
  TreatmentComment,
  User,
} from '../../__generated__/gql/graphql';
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form';
import CreateNewTreatment from './CreateNewTreatment';
import { RISK_FORM_PREFIX } from '../risk_assessment/RiskAssessment';

interface TreatmentOptionsSectionProps {
  availableRiskTreatments: RiskTreatment[];
  allUsers: User[];
  activeRiskSectionId: number;
  control: Control<RiskAssessment>;
}

const TreatmentOptionsSection: FC<TreatmentOptionsSectionProps> = ({
  availableRiskTreatments,
  allUsers,
  activeRiskSectionId,
  control,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${RISK_FORM_PREFIX}.${activeRiskSectionId}.treatments`,
  });

  const acceptRiskWithoutTreatment = useWatch({
    control,
    name: `${RISK_FORM_PREFIX}.${activeRiskSectionId}.acceptRiskWithoutTreatment`,
  });

  const handleAdd = (id: string) => {
    const treatment = availableRiskTreatments.find(
      treatment => treatment.id === id
    );
    if (treatment && !fields.some(field => field.id === id)) {
      append(treatment);
      setExpanded(false);
    }
  };

  const handleDelete = (id: string) => {
    const index = fields.findIndex(field => field.id === id);
    if (index !== -1) {
      remove(index);
    }
  };

  return (
    <Box
      bgcolor={colorThemes.GREY_100}
      color="black"
      borderRadius="8px"
      minHeight="500px"
      overflow="auto"
    >
      <CardHeader title="Treatment Options" />
      <CardContent
        sx={{
          pl: '32px',
          pt: '0px',
          display: 'flex',
          gap: '16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {!acceptRiskWithoutTreatment && (
          <>
            {fields.map((sectionTreatment: RiskTreatment, index: number) => (
              <TreatmentBox
                allUsers={allUsers}
                key={`selected-treatments-${sectionTreatment.id}-${index}`}
                checked={true}
                description={sectionTreatment.description}
                personId={sectionTreatment?.responsiblePerson?.id}
                title={sectionTreatment.treatment}
                deletable={true}
                onDelete={() => handleDelete(sectionTreatment.id)}
              />
            ))}
            <Accordion
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
              sx={{
                boxShadow: 'none',
                bgcolor: 'white',
                border: 2,
                borderColor: colorThemes.GREY_200,
                borderRadius: '8px !important',
                width: expanded ? '90%' : '270px',
                transition: 'width 0.3s ease',
                overflow: 'hidden',
              }}
            >
              <AccordionSummary
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  fontWeight: 'bold',
                  gap: '16px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                {expanded ? (
                  <ExpandLess sx={{ color: 'black', marginRight: '10px' }} />
                ) : (
                  <Add sx={{ color: 'black', marginRight: '10px' }} />
                )}
                Add Treatment Option
              </AccordionSummary>

              <AccordionDetails
                sx={{ display: 'flex', gap: '16px', flexDirection: 'column' }}
              >
                {availableRiskTreatments.map(
                  (riskTreatment: RiskTreatment, index: number) => (
                    <TreatmentBox
                      allUsers={allUsers}
                      key={`available-treatments-${riskTreatment.id}-${index}`}
                      checked={true}
                      description={riskTreatment.description}
                      personId={riskTreatment?.responsiblePerson?.id}
                      title={riskTreatment.treatment}
                      addible={true}
                      onAdd={() => handleAdd(riskTreatment.id)}
                    />
                  )
                )}
                <CreateNewTreatment
                  allUsers={allUsers}
                  setExpanded={setExpanded}
                  append={append}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <Box display="flex" flexDirection="column" gap="16px" width="80%">
          <Controller
            name={`${RISK_FORM_PREFIX}.${activeRiskSectionId}.acceptRiskWithoutTreatment`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="Accept Risk without treatment"
                control={
                  <Tooltip title="Enter a comment to accept risk without treatment.">
                    <span>
                      <Checkbox
                        {...field}
                        checked={field.value as boolean}
                        onChange={e => field.onChange(e.target.checked)}
                        disabled={
                          `${RISK_FORM_PREFIX}.${activeRiskSectionId}.comment` ===
                          ''
                        }
                        id={`acceptRiskWithoutTreatment-${activeRiskSectionId}`}
                        name={`acceptRiskWithoutTreatment-${activeRiskSectionId}`}
                        icon={
                          <CheckBoxOutlineBlankIcon sx={{ color: 'black' }} />
                        }
                        checkedIcon={<CheckBoxIcon sx={{ color: 'black' }} />}
                      />
                    </span>
                  </Tooltip>
                }
              />
            )}
          />
          {!acceptRiskWithoutTreatment && (
            <Controller
              name={`${RISK_FORM_PREFIX}.${activeRiskSectionId}.comment`}
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    value={field.value?.comment || ''}
                    onChange={e => {
                      const updatedComment: TreatmentComment = {
                        comment: e.target.value,
                      };
                      field.onChange(updatedComment);
                    }}
                    label="Justification for accepting without treatment"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{
                      height: '80px',
                      mb: '16px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'black',
                      },
                      '& .MuiOutlinedInput-root': {
                        color: 'black',
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'black',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: 'black',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: 'black',
                      },
                      width: '100%',
                    }}
                  />
                </>
              )}
            />
          )}
        </Box>
      </CardContent>
    </Box>
  );
};

export default TreatmentOptionsSection;
