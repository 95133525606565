import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ItAsset } from '../__generated__/gql/graphql';
import { GET_IT_ASSETS } from './ProjectDetailDrawer';

export const CREATE_IT_ASSET = gql`
  mutation CreateItAsset($name: String!) {
    createItAsset(name: $name) {
      itAsset {
        id
        name
        created
        lastModified
      }
    }
  }
`;

interface AddItAssetDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (asset: ItAsset) => void;
}

const AddItAssetDialog: React.FC<AddItAssetDialogProps> = ({
  open,
  onClose,
  onAdd,
}) => {
  const [name, setName] = useState('');

  const [createItAsset] = useMutation(CREATE_IT_ASSET, {
    update(cache, { data }) {
      if (!data?.createItAsset?.itAsset) return;

      const existingData = cache.readQuery<{ allItAssets: ItAsset[] }>({
        query: GET_IT_ASSETS,
      });

      cache.writeQuery({
        query: GET_IT_ASSETS,
        data: {
          allItAssets: [
            ...(existingData?.allItAssets ?? []),
            data.createItAsset.itAsset,
          ],
        },
      });
    },
    onCompleted: data => {
      if (data?.createItAsset?.itAsset) {
        onAdd(data.createItAsset.itAsset);
        setName('');
        onClose();
        toast.success('IT Asset created successfully');
      }
    },
    onError: error => {
      if (error.message.includes('duplicate key error')) {
        toast.error('An IT Asset with this name already exists');
      } else {
        toast.error('Failed to create IT Asset');
      }
    },
  });

  const handleSubmit = async () => {
    try {
      await createItAsset({
        variables: { name: name.trim() },
      });
    } catch (error) {
      console.error('Error creating IT Asset:', error);
    }
  };

  const handleClose = () => {
    setName('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New IT Asset</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Asset Name"
          fullWidth
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!name.trim()}
          variant="contained"
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItAssetDialog;
