import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import AddNewItemCard from './AddNewItemCard';

interface CardGridProps<T> {
  items: readonly T[];
  renderCard: (item: T) => ReactNode;
  onAddNew?: () => void;
  title?: string;
  addNewCardProps?: {
    width?: number;
    height?: number;
  };
  gridGap?: string;
}

const DEFAULT_PROPS = {
  width: 400,
  height: 200,
  gridGap: '12px',
};

const CardGrid = <T,>({
  items,
  renderCard,
  onAddNew,
  title,
  addNewCardProps = DEFAULT_PROPS,
  gridGap = DEFAULT_PROPS.gridGap,
}: CardGridProps<T>) => {
  const { width, height } = addNewCardProps;

  const renderAddNewCard = () =>
    onAddNew && (
      <AddNewItemCard key="add-new" onClick={onAddNew} sx={{ width, height }} />
    );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, ${width}px)`,
        gap: gridGap,
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {title && (
        <Typography variant="h5" sx={{ gridColumn: '1/-1', marginBottom: 1 }}>
          {title}
        </Typography>
      )}
      {items.map(item => renderCard(item))}
      {renderAddNewCard()}
    </Box>
  );
};

export default CardGrid;
